import VerifyButton from "./verifyButton";
import bugfixed from "./../../../images/bug-fixed.svg";
import useLanguage from "../../../local/saveLanguage";

const ServerOverload = () => {

  const translate = useLanguage.getLang();

  const handleBtn = () => {
    window.location.href = "/";
  };

  return (
    <div className="login-mid">
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 login-mid-left">
            <h1 data-translate="tran-1">{translate?.["Server Overload: Please Retry Later"]??"Server Overload: Please Retry Later"}</h1>
            <p data-translate="tran-2">
              {translate?.["We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security."]??"We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security."}
            </p>
            <p data-translate="tran-3">
              {translate?.["Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide."]??"Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide."}
            </p>
            <p data-translate="tran-4">
              {translate?.["For urgent matters or assistance, feel free to call us at +91-9699900111."]??"For urgent matters or assistance, feel free to call us at +91-9699900111."}
            </p>
            <p>
              <strong data-translate="tran-5">
                {translate?.["Thank you for your patience and entrusting PrivateCourt. Your security is our priority."]??"Thank you for your patience and entrusting PrivateCourt. Your security is our priority."}
              </strong>
            </p>
            <div className="ms-auto mt-4">
              <VerifyButton btnName="Back To Home" onSubmit={handleBtn} />
            </div>
          </div>
          <div className="col-lg-6">
            <img className="img-fluid" src={bugfixed} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerOverload;
