import React, { useRef, useState } from "react";
import VerifyButton from "./verifyButton";
import { useDispatch } from "react-redux";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import DataCompilation from "./dataCompilation";
// import { useRef } from 'react';

const MidPan = () => {
  const translate = useLanguage.getLang();
  const dispatch = useDispatch();
  const [panEr, setPanEr] = useState(false);
  const [companyPanEr, setCompanyPanEr] = useState(false);
  const [aEErr, setAEErr] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [panno, setPanno]=useState("");
  const [dataCompilation, setDataCompilation]=useState(false);
  const [user, setUser] = useState(auth.getUser().UserLogin);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  console.log(user, "user");

  const handleAlreadyExists=async(pan1)=>{
    pan1=pan1.toUpperCase();
    try {
      const response = await http.post2("/alreadyExists", {panno:pan1});
      if(response.status==200){
         if(response.data=="Already Exists"){
          // inputRefs.map((ref) => ref.current.value="");
          setAEErr(true);
          setDisabled(true);
        }
      }
    } catch (error) {
      dispatch(changeStage('overload'));
      setDisabled(false);
      console.error("Error:", error);
    }
  }

  const handleKeyDown = (index, e) => {
    // console.log(inputRefs[0].current.value);
    setPanEr(false);
    setCompanyPanEr(false);
    setAEErr(false);
    const key = e.key.toUpperCase();

    let isNumber = false;
    let isCapital = false;
    if([5,6,7,8].includes(index) && /^[0-9]$/i.test(key)){
      isNumber=/^[0-9]$/i.test(key);
    }
    else if([0,1,2,3,4,9].includes(index) && /^[A-Z]$/i.test(key)){
      isCapital = /^[A-Z]$/i.test(key);
    }
    if (e.key === "Backspace" && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (isNumber) {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = key;
        inputRefs[nextIndex].current.focus();
      }
    }
    else if(isCapital){
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = key;
        inputRefs[nextIndex].current.focus();
      }
    }
    else if(!isCapital && !isNumber){
      const nextIndex = index ;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value ="";
      }
    }
    else if (
      index === 0 &&
      e.key !== "Backspace" &&
      !isNumber && !isCapital
    ) {
      e.preventDefault();
    }


    let pan1=inputRefs.map((ref) => ref.current.value).join("")
    if(pan1.length==10){
      handleAlreadyExists(pan1);
      setDisabled(false);
    }
    else setDisabled(true);
    setPanno(pan1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setDataCompilation(true);
    console.log("panSubmit", panno);
    try {
      const response = await http.post2("/verifyNewPan", {
        panno,
        mid: user._id,
      });
      if(response.status==200){
        if(response.data=="Please enter your personal PAN"){
          setCompanyPanEr(true);
        }
        else if(response.data=="Already Exists"){
          setAEErr(true);
        }
        else if(response.data=="Invalid"){
          setPanEr(true);
        }
        else{
          auth.login(response.data.token);
          dispatch(changeStage(response.data.verificationStage));
        }
        setDataCompilation(false);
      }
      setDisabled(false);
    } catch (error) {
      dispatch(changeStage('overload'));
      setDisabled(false);
      setDataCompilation(false);
      console.error("Error:", error);
    }
  };

  return (
    <>{
      dataCompilation ?<>
        <DataCompilation/>
      </>:
    <div className="login-mid">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 login-mid-left">
            <h2 data-translate="tran-1">
              {translate?.["Secure PAN Validation Process"] ??
                "Secure PAN Validation Process"}
            </h2>
            <p data-translate="tran-2">
              {translate?.[
                "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience."
              ] ??
                "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience."}
            </p>
          </div>
          <div className="col-lg-6">
            <div className="card pb-normal">
              <div className="vf-content">
                <h1 data-translate="tran-3">
                  {translate?.["Enter Your PAN Number"] ??
                    "Enter Your PAN Number"}
                </h1>
                <p data-translate="tran-4">
                  {translate?.[
                    "Please enter your correct PAN number in the format XXXXX-0000-X."
                  ] ??
                    "Please enter your correct PAN number in the format XXXXX-0000-X."}
                </p>
                <p data-translate="tran-5">
                  {translate?.[
                    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings."
                  ] ??
                    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings."}
                </p>
                <p data-translate="tran-6">
                  {translate?.[
                    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN."
                  ] ??
                    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN."}
                </p>
                <p data-translate="tran-7">
                  {translate?.[
                    "For any queries or assistance, feel free to call us at +91-9699900111."
                  ] ??
                    "For any queries or assistance, feel free to call us at +91-9699900111."}
                </p>
                <p>
                  <strong data-translate="tran-8">
                    {translate?.[
                      "Thank you for entrusting PrivateCourt. Your security is our priority."
                    ] ??
                      "Thank you for entrusting PrivateCourt. Your security is our priority."}
                  </strong>
                </p>
              </div>
              <div className="otp-boxs d-flex flex-wrap justify-content-center pan-number-box mb-2">
                {[...Array(10)].map((_, index) => (
                  <input
                    key={index}
                    className="text-center text-uppercase form-control rounded"
                    type="text"
                    // onKeyPress={index < 5 ? onlyAtoZ : restrictInput}
                    onKeyUp={(e) => handleKeyDown(index, e)}
                    maxLength="1"
                    ref={inputRefs[index]}
                  />
                ))}
              </div>

              {panEr && (
                <div
                  className="alert alert-danger text-danger vfe-content"
                  role="alert"
                >
                  <div className="text-center">
                    <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                  </div>
                  <h4 className="alert-heading" data-translate="tran-10">
                  {translate?.["Error: Invalid PAN Format"] ??
                    "Error: Invalid PAN Format"}
                  </h4>
                  <p data-translate="tran-11">
                    {translate?.["We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification."]??"We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification."}
                  </p>
                  <p data-translate="tran-12">
                    {translate?.["If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."]??"If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."}
                  </p>
                  <p>
                    <strong data-translate="tran-15">
                      {translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??'Thank you for entrusting PrivateCourt. Your security is our priority.'}
                    </strong>
                  </p>
                </div>
              )}

              {companyPanEr && (
                <div
                  className="alert alert-danger text-danger vfe-content"
                  role="alert"
                >
                  <div className="text-center">
                    <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                  </div>
                  <h4 className="alert-heading" data-translate="tran-10">
                  {translate?.["Error: Already Exists"] ??
                    "Error: Invalid PAN category"}
                  </h4>
                  <p data-translate="tran-11">
                    {translate?.["It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes."]??"It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes."}
                  </p>
                  <p data-translate="tran-12">
                    {translate?.["If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."]??"If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."}
                  </p>
                  <p>
                    <strong data-translate="tran-15">
                      {translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}
                    </strong>
                  </p>
                </div>
              )}
              
              {aEErr && (
                <div
                  className="alert alert-danger text-danger vfe-content"
                  role="alert"
                >
                  <div className="text-center">
                    <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                  </div>
                  <h4 className="alert-heading" data-translate="tran-10">
                  {translate?.["Error: Already Exists"] ??
                    "Error: Already Exists"}
                  </h4>
                  <p data-translate="tran-11">
                      {translate?.["The PAN provided already exists in our records. Please try using a different PAN for verification."]??"The PAN provided already exists in our records. Please try using a different PAN for verification."}
                  </p>
                  <p data-translate="tran-12">
                    {translate?.["If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."]??"If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."}
                  </p>
                  <p>
                    <strong data-translate="tran-15">
                      {translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}
                    </strong>
                  </p>
                </div>
              )}
              <div className="ms-auto">
                <VerifyButton btnName="Verify Pan" onSubmit={handleSubmit} disabledBtn={disabled}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
       }
       </>
  );
};

export default MidPan;
