import React, { useRef, useState } from "react";
import VerifyButton from "./verifyButton";
import { useDispatch } from "react-redux";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import DataCompilation from "./dataCompilation";
// import { useRef } from 'react';

const MidBusiness = () => {
  const translate = useLanguage.getLang();
  const dispatch = useDispatch();
  const [businesses, setBusiness]=useState("");
  const [dataCompilation, setDataCompilation] = useState(false);
  const [user, setUser] = useState(auth.getUser().UserLogin);

const handleSubmit=async()=>{
    console.log(businesses)
    setDataCompilation(true);
    try {
      const response = await http.post2("/numberOfBusiness", {mid:user._id, businesses:businesses});
      if(response.status==200){
        auth.login(response.data.token);
        dispatch(changeStage(response.data.verificationStage));
        setDataCompilation(false);
      }
    } catch (error) {
      dispatch(changeStage('overload'));
      setDataCompilation(false);
      console.error("Error:", error);
    }
}

  return (
    <>
      {dataCompilation ? (
        <>
          <DataCompilation />
        </>
      ) : (
        <div className="login-mid">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 login-mid-left">
                <h2 data-translate="tran-1">
                  {translate?.["Business Verification Process"] ??
                    "Business Verification Process"}
                </h2>
                <p data-translate="tran-2">
                  {translate?.[
                    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience."
                  ] ??
                    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience."}
                </p>
              </div>
              <div className="col-lg-6">
                <div className="card pb-normal">
                  <div className="vf-content">
                    <h1 data-translate="tran-3">
                      {translate?.["Please provide details for each of your businesses for accurate validation."] ?? "Please provide details for each of your businesses for accurate validation."}
                    </h1>
                    <p data-translate="tran-4">
                      {translate?.[
                        "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings."
                      ] ??
                        "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings."}
                    </p>
                    <p data-translate="tran-5">
                      {translate?.[
                        "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN."
                      ] ??
                        "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN."}
                    </p>
                    <p data-translate="tran-6">
                      {translate?.[
                        "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN."
                      ] ??
                        "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN."}
                    </p>
                    <p data-translate="tran-7">
                      {translate?.[
                        "For any queries or assistance, feel free to call us at +91-9699900111."
                      ] ??
                        "For any queries or assistance, feel free to call us at +91-9699900111."}
                    </p>
                    <p>
                      <strong data-translate="tran-8">
                        {translate?.[
                          "Thank you for entrusting PrivateCourt. Your security is our priority."
                        ] ??
                          "Thank you for entrusting PrivateCourt. Your security is our priority."}
                      </strong>
                    </p>
                  </div>
                  <div className="otp-boxs d-flex flex-wrap justify-content-center pan-number-box mb-2">
                    {/* options */}
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={businesses}
                        onChange={(e)=>setBusiness(e.target.value)}
                        >
                        <option value="" disabled={true}>{translate?.["Select Businesses"]??"Select Businesses"}</option>
                        <option value="0">I am individual</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                  </div>
                  <div className="ms-auto">
                    <VerifyButton
                      btnName="Submit"
                      onSubmit={handleSubmit}
                      disabledBtn={!businesses}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MidBusiness;
