// import React from "react";
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLang } from "../../../features/language/langSlice";

const LoginLang = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const lang = useSelector((state) => state.lang.lang);
  const verificationStage = useSelector(
    (state) => state.verificationStage.verificationStage
  );
  const dispatch = useDispatch();

  const handleChangeLang = (language) => {
    dispatch(changeLang(language));
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
    };
    audioElement.addEventListener("ended", handleEnded);
    return () => {
      audioElement.removeEventListener("ended", handleEnded);
    };
  }, []);

  return (
    <div className="language-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ul className="list-inline">
              <li
                className="list-inline-item"
                onClick={() => handleChangeLang("English(UK)")}
              >
                English (UK)
              </li>
              <li
                className="list-inline-item"
                id="button1"
                onClick={() => handleChangeLang("Hindi")}
              >
                हिन्दी
              </li>
              <li
                className="list-inline-item"
                id="button2"
                onClick={() => handleChangeLang("Bengali")}
              >
                বাংলা
              </li>
              <li
                className="list-inline-item"
                id="button3"
                onClick={() => handleChangeLang("Telugu")}
              >
                తెలుగు
              </li>
              <li
                className="list-inline-item"
                id="button4"
                onClick={() => handleChangeLang("Marathi")}
              >
                मराठी
              </li>
              <li
                className="list-inline-item"
                id="button5"
                onClick={() => handleChangeLang("Tamil")}
              >
                தமிழ்
              </li>
              <li
                className="list-inline-item"
                id="button6"
                onClick={() => handleChangeLang("Gujarati")}
              >
                ગુજરાતી
              </li>
              <li
                className="list-inline-item"
                id="button7"
                onClick={() => handleChangeLang("Kannada")}
              >
                ಕನ್ನಡ
              </li>
              <li
                className="list-inline-item"
                id="button8"
                onClick={() => handleChangeLang("Malayalam")}
              >
                മലയാളം
              </li>
              <li
                className="list-inline-item"
                id="button9"
                onClick={() => handleChangeLang("Odia")}
              >
                ওড়িয়া
              </li>
            </ul>
          </div>
          <div className="col-lg-1">
            <audio
              id="audioPlayer"
              ref={audioRef}
              src={`https://privatecourtdocs.s3.ap-south-1.amazonaws.com/LoginAudio/${verificationStage}_${lang}.wav`}
              preload="auto"
            ></audio>
            <button
              id="toggleButton"
              onClick={lang !== "Odia" ? togglePlayPause : () => {}}
            >
              <i
                id="playPauseIcon"
                className={`bi ${
                  isPlaying ? "bi-pause-circle" : "bi-play-circle"
                }`}
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLang;
