const MarathiTranslation={
    'Email Security Check':'ईमेल सुरक्षा तपासणी',
    'Verify Your Email for Account Access': 'खाते प्रवेशासाठी तुमचा ईमेल सत्यापित करा',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": "तुमच्या खात्याची सुरक्षितता सुनिश्चित करण्यासाठी, कृपया तुमचा वैध ईमेल आयडी निर्दिष्ट फॉरमॅटमध्ये प्रविष्ट करा- username@domain.com. तुमचा ईमेल पत्ता आमच्या सिस्टममध्ये अस्तित्वात नसल्यास,",
    'NEW USER': 'नवीन वापरकर्ता',
    'account will be created for you': 'खाते तयार केले जाईल.',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'एकदा सबमिट केल्यावर, तुम्हाला तुमच्या प्रदान केलेल्या ईमेल पत्त्यावर एक सत्यापन OTP (वन-टाइम पासवर्ड) प्राप्त होईल.',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'कोणत्याही शंका किंवा सहाय्यासाठी, आम्हाला +91-9699900111 वर कॉल करा.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'खाजगी न्यायालय सोपवल्याबद्दल धन्यवाद. तुमची सुरक्षा ही आमची प्राथमिकता आहे.',
    'Verify': 'सत्यापित करा',
    'Invalid Email Address': 'अवैध ई - मेल पत्ता',
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'आपल्याला कळविण्यात आम्हाला खेद होत आहे की प्रविष्ट केलेला ईमेल पत्ता अवैध आहे. कृपया खात्री करा की तुम्ही योग्य आणि योग्यरित्या स्वरूपित केलेला ईमेल पत्ता प्रविष्ट केला आहे.',
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'समस्या कायम राहिल्यास, ईमेल फॉरमॅटमध्ये (उदा. username@example.com) कोणत्याही टायपोज किंवा त्रुटींसाठी पुन्हा तपासा.',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'पुढील शंका किंवा मदतीसाठी, आम्हाला +91-9699900111 वर कॉल करा.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'PrivateCourt सोपवल्याबद्दल धन्यवाद. तुमची सुरक्षा ही आमची प्राथमिकता आहे.',     
    'Secure UDYAM Number Validation':'सुरक्षित उद्यम क्रमांक प्रमाणीकरण',
    'Enter Your Udyam Number':'तुमचा उदयम क्रमांक टाका',
    'Please enter your correct UDYAM Number.':'कृपया तुमचा योग्य उद्यम क्रमांक टाका.',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.':'तुमचा उद्यम क्रमांक आम्हाला तुमच्या व्यवसायाशी संबंधित महत्त्वाचे तपशील गोळा करण्याची परवानगी देतो. यामध्ये तुमचा व्यवसाय पत्ता, वर्तमान स्थिती, सेवा तपशील आणि संस्थेचा प्रकार समाविष्ट आहे.',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.':'तुमच्या उद्यम क्रमांकाची अचूकता सुनिश्चित करणे अखंड पडताळणी प्रक्रियेसाठी अत्यावश्यक आहे.',
    'UDYAM':'उद्यम',
    'Error: Invalid UDYAM Number':'त्रुटी: अवैध उद्यम क्रमांक',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.':'आपल्याला कळविण्यास आम्हाला खेद होत आहे की प्रविष्ट केलेला UDYAM क्रमांक अवैध किंवा चुकीचा आहे. कृपया तुम्ही योग्य UDYAM तपशील एंटर केल्याची खात्री करा आणि कोणत्याही टायपिंगच्या चुका पुन्हा तपासा.',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.':'तुम्हाला समस्या येत राहिल्यास, कृपया तुमचे UDYAM प्रमाणपत्र सत्यापित करा आणि तपशील पुन्हा प्रविष्ट करा.',
    'Secure PAN Validation Process':'सुरक्षित पॅन प्रमाणीकरण प्रक्रिया',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.':'ही पडताळणी प्रक्रिया विश्वासार्ह आणि सुरक्षित अनुभव सुनिश्चित करून आमच्या सेवांची अखंडता राखण्यासाठी डिझाइन केलेली आहे.',
    'Enter Your PAN Number':'तुमचा पॅन क्रमांक टाका',
    'Please enter your correct PAN number in the format XXXXX-0000-X.':'कृपया तुमचा योग्य पॅन क्रमांक XXXXX-0000-X या फॉरमॅटमध्ये टाका.',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.':'तुमचे पूर्ण नाव, जन्मतारीख, निवासी पत्ता आणि संपर्क तपशील यासारखी तुमच्या पॅनशी संबंधित आवश्यक माहिती प्रायव्हेटकोर्ट काळजीपूर्वक गोळा करेल. व्यवसाय मालकांसाठी, आम्ही तुमच्या पॅनशी जोडलेले सर्व संबंधित व्यवसाय तपशील जसे की GST तपशील, MCA तपशील आणि GST रिटर्न फाइलिंगचे रेकॉर्ड समाविष्ट करण्यासाठी आमचा डेटा संग्रह वाढवतो.',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.':'आमची सिस्टीम आर्थिक अंतर्दृष्टीसाठी तुमच्या CIBIL स्कोअरचे मूल्यांकन करते आणि तुमच्या PAN शी संबंधित कोणत्याही चालू असलेल्या न्यायालयीन प्रकरणांसाठी क्रॉस-व्हेरिफिकेशन करते.',
    'Error: Invalid PAN Format':'त्रुटी: अवैध PAN स्वरूप',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.':'आपल्याला कळविण्यास आम्हाला खेद होत आहे की प्रविष्ट केलेला पॅन क्रमांक आवश्यक स्वरूपाशी (XXXXX-0000-X) जुळत नाही. कृपया खात्री करा की तुम्ही योग्य पॅन तपशील प्रविष्ट केला आहे आणि अचूक पडताळणीसाठी निर्दिष्ट स्वरूपाचे अनुसरण करा.',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.':'तुम्हाला समस्या येत राहिल्यास, कृपया तुमचे पॅन कार्ड पुन्हा तपासा आणि तपशील पुन्हा एंटर करा. पुढील शंका किंवा मदतीसाठी, आम्हाला +91-9699900111 वर कॉल करा.',
    'Welcome XXXX':'स्वागत आहे XXXX',
    'Account security starts with verification':'खाते सुरक्षा पडताळणीसह सुरू होते',
    'OTP Validation for Account Access':'खाते प्रवेशासाठी OTP प्रमाणीकरण',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.':'कृपया तुमचा इनबॉक्स तपासा, आणि पडताळणी प्रक्रिया पूर्ण करण्यासाठी नियुक्त फील्डमध्ये OTP प्रविष्ट करा.',
    "Didn't receive OTP?":'OTP प्राप्त झाला नाही? ',
    'Request a Resend':'पुन्हा पाठवण्याची विनंती करा',
    'Verify OTP':'OTP सत्यापित करा',
    'Error: Incorrect OTP':'एरर: चुकीचा OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.':'गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रविष्ट केलेला वन-टाइम पासवर्ड (OTP) चुकीचा आहे. कृपया खात्री करा की तुम्ही तुमच्या ईमेलमध्ये प्राप्त झालेला OTP अचूकपणे टाकला आहे.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'समस्या कायम राहिल्यास, तुम्ही पुन्हा पाठवा लिंकवर क्लिक करून किंवा +91-9699900111 वर आमच्या सपोर्ट टीमशी संपर्क साधून नवीन OTP ची विनंती करू शकता.',
    'Double-check the email and try entering the OTP again.':'ईमेल दोनदा तपासा आणि OTP पुन्हा एंटर करण्याचा प्रयत्न करा.',
    'Choose Profile':'प्रोफाइल निवडा',
    'Setting Your Default Profile':'तुमचे डीफॉल्ट प्रोफाइल सेट करत आहे',
    'Please choose the default profile for a seamless experience.':'अखंड अनुभवासाठी कृपया डीफॉल्ट प्रोफाइल निवडा.',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":'तुम्ही एकाधिक कंपन्या व्यवस्थापित करत असल्यास, तुम्ही डीफॉल्ट म्हणून सेट करू इच्छित प्रोफाइल निवडा. हे सुनिश्चित करते की प्रत्येक वेळी तुम्ही लॉग इन करता, तुम्हाला तुमच्या पसंतीच्या कंपनीशी संबंधित माहितीवर त्वरित प्रवेश मिळेल.',
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.':'आता योग्य निवड केल्याने तुमचे भविष्यातील लॉगिन सोपे होते आणि तुमच्या प्राथमिक व्यवसाय घटकावर आधारित एक अनुकूल अनुभव प्रदान करते.',
    'Go to Dashboard':'डॅशबोर्डवर जा',
    'Data Compilation in Progress':'डेटा संकलन प्रगतीपथावर आहे:',
    'Please Allow Processing Time':'कृपया प्रक्रियेसाठी वेळ द्या',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":'तुमची माहिती दिल्याबद्दल धन्यवाद. तुमची सर्वसमावेशक प्रोफाइल स्थापित करण्यासाठी आमची प्रणाली सध्या पॅन, आधार, GST, ईमेल आणि अधिक तपशीलांसह तुम्ही प्रविष्ट केलेल्या डेटाचे विश्लेषण आणि संकलन करण्याच्या प्रक्रियेत आहे.',
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":'या ऑपरेशनला काही क्षण लागू शकतात. या वेळी, तुमची स्क्रीन किमान क्रियाकलाप दर्शवू शकते. कृपया खात्री बाळगा की हा आमच्या डेटा संकलन प्रक्रियेचा एक नियमित भाग आहे.',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.':'अचूकता आणि सुरक्षितता टिकवून ठेवण्यासाठी तुमचे प्रोफाइल काळजीपूर्वक तयार केले जात आहे. आम्ही तुमच्या संयमाची आणि समजूतदारपणाची मनापासून प्रशंसा करतो कारण आम्ही तुमचा अनुभव वाढवण्यासाठी कार्य करतो.',
    'Start Your Bank Account Verification':'तुमचे बँक खाते पडताळणी सुरू करा',
    'Bank Verification':'बँक पडताळणी',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.':'अखंड पडताळणी प्रक्रियेसाठी तुमच्या बँक खात्याच्या माहितीची अचूकता सुनिश्चित करणे महत्त्वाचे आहे. कृपया तुमचा खाते क्रमांक प्रविष्ट करा आणि तुमच्या खात्याशी संबंधित योग्य IFSC कोड प्रविष्ट करा.',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.':'ही पडताळणी पायरी आमच्या सेवांची सुरक्षितता वाढवण्यासाठी आणि तुम्हाला विश्वासार्ह अनुभव देण्यासाठी डिझाइन केलेली आहे.',
    'Account Number':'അക്കൗണ്ട് നമ്പർ',
    'IFSC code':'IFSC കോഡ്',
    'Error: Invalid Bank Account or IFSC Code':'त्रुटी: अवैध बँक खाते किंवा IFSC कोड',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.':'तुम्हाला कळवण्यात आम्हाला खेद होत आहे की प्रविष्ट केलेला बँक खाते किंवा IFSC कोड अवैध किंवा चुकीचा आहे. कृपया खात्री करा की तुम्ही दोन्ही तपशील अचूकपणे एंटर केले आहेत आणि कोणत्याही टायपोजसाठी पुन्हा तपासा.',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.':'तुम्हाला समस्या येत राहिल्यास, कृपया तुमचे बँक स्टेटमेंट सत्यापित करा किंवा प्रदान केलेल्या माहितीच्या अचूकतेची पुष्टी करण्यासाठी तुमच्या बँकेशी संपर्क साधा.',
    'Aadhaar Details Verification':'आधार तपशील पडताळणी',
    'Enter Your Aadhaar Number':'तुमचा आधार क्रमांक टाका',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.':'कृपया तुमचा आधार क्रमांक टाका. ही प्रक्रिया तुमच्या सुरक्षिततेसाठी अचूक क्रॉस-चेकिंग सुनिश्चित करते. तुमच्या सहकार्यासाठी धन्यवाद.',
    'Enter Your Aadhaar OTP Here':'तुमचा आधार OTP येथे एंटर करा',
    'Error: Incorrect Aadhaar OTP':'त्रुटी: चुकीचा आधार OTP',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.':'आधार पडताळणीसाठी प्रविष्ट केलेला वन-टाइम पासवर्ड (ओटीपी) चुकीचा आहे हे कळविण्यास आम्हाला खेद होत आहे. कृपया खात्री करा की तुम्ही तुमच्या नोंदणीकृत मोबाईल नंबरवर प्राप्त झालेला OTP अचूकपणे टाकला आहे.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'समस्या कायम राहिल्यास, तुम्ही पुन्हा पाठवा लिंकवर क्लिक करून किंवा +91-9699900111 वर आमच्या सपोर्ट टीमशी संपर्क साधून नवीन OTP ची विनंती करू शकता.',
    'Double-check the email and try entering the OTP again.':'ईमेल दोनदा तपासा आणि OTP पुन्हा एंटर करण्याचा प्रयत्न करा.',
    'Error: Invalid Aadhaar Number':'त्रुटी: अवैध आधार क्रमांक',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.':'गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रविष्ट केलेला आधार क्रमांक अवैध आहे. कृपया दोनदा तपासा आणि तुम्ही योग्य 12-अंकी आधार क्रमांक प्रविष्ट केल्याची खात्री करा.',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.':'तुम्हाला समस्या येत राहिल्यास, तुमचे आधार कार्ड सत्यापित करा किंवा पुढील सहाय्यासाठी +91-9699900111 वर आमच्या समर्थन कार्यसंघाशी संपर्क साधा. आम्ही तुमच्या सहकार्याची प्रशंसा करतो.',   
    'Language Selection:':'भाषा निवड:',
    'Empowering Effective Communication':'प्रभावी संप्रेषण सक्षम करणे',
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":'शुभ दिवस! मी मूर्ती आहे, आजच्या कामकाजासाठी तुमचा समर्पित सहाय्यक आहे. तुम्हाला सर्वात सोयीस्कर असलेली भाषा निवडून सुरुवात करूया.',
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"आपल्या पसंतीच्या भाषेची निवड केल्याने स्पष्ट आणि परिणामकारक संप्रेषण सुनिश्चित होते. तुमची मातृभाषा असो किंवा तुमच्या आवडीची दुसरी भाषा, ती निवडल्याने आमचा संवाद सुव्यवस्थित होईल आणि तुमचा अनुभव वाढेल.",
    "Select Your Preferred Language":"तुमची पसंतीची भाषा निवडा",
    "Welcome to PrivateCourt, where Helping you is our priority.":"PrivateCourt मध्ये आपले स्वागत आहे, जिथे तुम्हाला मदत करणे हे आमचे प्राधान्य आहे.",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":'आता, मी तुमची ओळख PrivateCourt शी करून देतो - कर्ज विवाद प्रभावीपणे आणि कार्यक्षमतेने सोडवण्यासाठी तुमचा विश्वासार्ह भागीदार.',
    "PrivateCourt:":"खाजगी न्यायालय:",
    "Your trusted partner in resolving lending disputes with care and expertise.":"सावधगिरीने आणि कौशल्याने कर्ज विवादांचे निराकरण करण्यासाठी तुमचा विश्वासू भागीदार.",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"PrivateCourt मध्ये आपले स्वागत आहे, जेथे तुमचे केस तपशील चांगल्या हातात आहेत. कर्ज देणाऱ्या कंपनीने लवाद किंवा सलोखा सुरू केल्यामुळे, तुमची परिस्थिती पूर्णपणे समजून घेणे आमच्यासाठी आवश्यक आहे. कृपया सर्व तपशील शेअर करा.",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"आमच्याकडे जितकी अधिक माहिती असेल तितकी आम्ही मदत करू शकतो. तुमच्यासाठी योग्य उपाय शोधण्यासाठी तुमचे इनपुट महत्त्वपूर्ण आहे.",
    "Trust us to carefully examine your case and work together to find the best way forward.":"तुमच्या केसचे काळजीपूर्वक परीक्षण करण्यासाठी आमच्यावर विश्वास ठेवा आणि सर्वोत्तम मार्ग शोधण्यासाठी एकत्र काम करा.",
    "Reviewing Your Dispute Information":'तुमच्या विवाद माहितीचे पुनरावलोकन करत आहे',
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by LendingKart, regarding a personal loan.":"चला तुमच्या विवादाभोवती असलेल्या तपशीलांवर बारकाईने नजर टाकूया. आज आम्ही ज्या माहितीवर चर्चा करत आहोत ती आमच्याशी LendingKart द्वारे वैयक्तिक कर्जासंबंधी सामायिक केली गेली आहे.",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"ही वैशिष्ट्ये समजून घेऊन, आम्ही परिस्थितीचे अधिक चांगल्या प्रकारे मूल्यांकन करू शकतो आणि तुमच्या परिस्थिती आणि उद्दिष्टांशी जुळणारी ठराव योजना तयार करू शकतो",
    "Dispute Amount Information":"विवाद रकमेची माहिती",
    "According to their records, you initially borrowed an amount of":"त्यांच्या नोंदीनुसार, तुम्ही सुरुवातीला काही रक्कम उधार घेतली होती",
    "Presently, your outstanding due stands at Rs.":"सध्या तुमची थकबाकी रु. ",
    "These details provide us with valuable insight into the history and current status of your loan.":"हे तपशील आम्हाला तुमच्या कर्जाचा इतिहास आणि सद्य स्थितीबद्दल मौल्यवान अंतर्दृष्टी देतात.",
    "Spotting Financial Issues: Understanding Unpaid Obligations":"आर्थिक समस्या ओळखणे: न भरलेल्या जबाबदाऱ्या समजून घेणे",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"आता, आर्थिक अडचणींमुळे तुम्हाला ज्या आव्हानांचा सामना करावा लागला आहे ते हळूवारपणे पाहू या. यावर चर्चा करणे कदाचित संवेदनशील असेल, परंतु तुमचा मोकळेपणा आम्हाला पुढील सर्वोत्तम मार्ग शोधण्यात मदत करेल. तुमच्या अनुभवाशी सुसंगत खालीलपैकी कोणताही पर्याय निवडण्यासाठी कृपया थोडा वेळ घ्या:",
    "Financial Loss":"आर्थिक नुकसान",
    "Business Loss":"व्यवसायात तोटा",
    "Partner Dispute":"भागीदार वाद",
    "Funds held with other vendors or clients":"इतर विक्रेते किंवा क्लायंटकडे ठेवलेल्या निधी",
    "Monetary loss through other means":"इतर माध्यमातून आर्थिक नुकसान",
    "Job Loss":"नोकरीची हानी",
    "Less than 2 months":"2 महिन्यांपेक्षा कमी",
    "Less than 6 months":"6 महिन्यांपेक्षा कमी",
    "Less than 1 year":"1 वर्षापेक्षा कमी",
    "More than 1 year" :"1 वर्षापेक्षा जास्त",
    "Health Issue":"आरोग्य समस्या",
    "Death of the earning member":"कमावत्या सदस्याचा मृत्यू",
    "Missing payment details":"पेमेंट तपशील गहाळ",
    "Dispute due to bad behaviour":"वाईट वागणुकीमुळे वाद",
    "No clear loan information":"कर्जाची स्पष्ट माहिती नाही",
    "Death Case":"मृत्यू प्रकरण",
    "We kindly request you to upload the death certificate at":"वर मृत्यू प्रमाणपत्र अपलोड करण्याची विनंती करतो",
    "Other reason/s":"इतर कारण/से",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"तुमचा त्वरित प्रतिसाद आम्हाला परिस्थितीचे मूल्यांकन करण्यात आणि सर्वात योग्य निराकरण शोधण्यात मदत करेल. निश्चिंत राहा, कोणत्याही प्रलंबित समस्यांचे निराकरण करण्यासाठी आणि निष्पक्ष आणि समाधानकारक निकाल सुनिश्चित करण्यासाठी आम्ही तुमच्यासोबत काम करण्यास वचनबद्ध आहोत.",
    "Exploring Settlement Possibilities":"सेटलमेंटच्या शक्यतांचा शोध घेणे",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"आमची चर्चा जसजशी पुढे जाईल, तसतसे डिफॉल्टचे कारण स्पष्ट करण्याच्या तुमच्या मोकळेपणाचे मी कौतुक करतो. कोणतीही कायदेशीर गुंतागुंत टाळण्यासाठी तुमची थकबाकी त्वरीत सोडवणे महत्वाचे आहे. आम्ही विलंब न करता तुमच्या जबाबदाऱ्या पूर्ण करण्यासाठी कुटुंबाकडून मदत मिळवणे किंवा पर्यायी वित्तपुरवठा पर्यायांचा शोध घेण्यासारखे विविध मार्ग शोधण्याची शिफारस करतो.",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":"वेळेवर सेटलमेंट केवळ कायदेशीर जोखीम कमी करत नाही तर तुमच्या आर्थिक परस्परसंवादात विश्वास आणि विश्वासार्हता देखील मजबूत करते. या प्रकरणात आपले सहकार्य खरोखरच मोलाचे आहे.",
    "Settlement Options":"सेटलमेंट पर्याय",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.":"जलद निराकरण करण्याच्या आमच्या प्रयत्नांमध्ये, आम्ही तुमचे सहकार्य मागतो.",
    "Are you open to discussing the settlement of your outstanding dues?":"तुमच्या थकबाकीच्या निकालाबाबत चर्चा करण्यास तुम्ही तयार आहात का?",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,":"तुमची थकबाकी सोडवण्याच्या तुमच्या मोकळेपणाचे आम्ही कौतुक करतो. तुमची आर्थिक परिस्थिती अधिक चांगल्या प्रकारे समजून घेण्यासाठी आणि त्यानुसार आमचा दृष्टिकोन तयार करण्यासाठी,",
    "could you kindly provide details on your current family income?":"तुम्ही तुमच्या सध्याच्या कौटुंबिक उत्पन्नाचा तपशील देऊ शकता का?",
    "In our efforts to address your outstanding dues, we'd like to know if a":"तुमची थकबाकी सोडवण्याच्या आमच्या प्रयत्नांमध्ये, तुमच्यासाठी",
    "one-time payment settlement is an option for you.":"एक-वेळ पेमेंट सेटलमेंट हा पर्याय आहे का हे आम्हाला जाणून घ्यायचे आहे.",
    "We appreciate your feedback. If a one-time payment is not viable,":"आम्ही तुमच्या अभिप्रायाची प्रशंसा करतो. एकरकमी पेमेंट व्यवहार्य नसल्यास,",
    "could you consider arranging payment in three equal instalments?":"तुम्ही तीन समान हप्त्यांमध्ये पेमेंटची व्यवस्था करण्याचा विचार करू शकता का?",
    "This approach could help ease any immediate financial strain while still addressing the outstanding dues.":"हा दृष्टीकोन थकबाकी भरताना कोणताही तात्काळ आर्थिक ताण कमी करण्यास मदत करू शकतो.",
    "Yes":"होय",
    "Next":"",
    "I am unable to pay by any means":"मी कोणत्याही प्रकारे पैसे देऊ शकत नाही",
    "Amount with Yes":"होय सह रक्कम",
    "No":"नाही",
    "Please Select":"कृपया निवडा",
    "We Appreciate Your Honesty.":"आम्ही तुमच्या प्रामाणिकपणाचे कौतुक करतो.",
    "No Income":"उत्पन्न नाही",
    "less than Rs. 10000/- per month":"रु. पेक्षा कमी 10000/- दरमहा",
    "less than Rs. 20,000/- per month":"रु. पेक्षा कमी 20000/- दरमहा",
    "less than Rs. 40,000/- per month":"रु. पेक्षा कमी 40000/- दरमहा",
    "less than Rs. 60,000/- per month":"रु. पेक्षा कमी 60000/- दरमहा",
    "more than Rs. 1,00,000/- per month":"रु. पेक्षा जास्त 1,00,000/- दरमहा",
    
    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "कृपया तुम्ही योग्य जीएसटी तपशील प्रविष्ट केल्याची खात्री करा आणि अचूक पडताळणीसाठी निर्दिष्ट स्वरूपाचे अनुसरण करा.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "तुम्हाला समस्या येत राहिल्यास, कृपया तुमचे पीएएन कार्ड पुन्हा तपासा आणि तपशील पुन्हा एंटर करा.",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "प्रदान केलेला जीएसटी आमच्या रेकॉर्डमध्ये आधीपासूनच अस्तित्वात आहे. कृपया पडताळणीसाठी वेगळा GST वापरून पहा.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "दिलेला पीएएन तपशील कंपनीचा असल्याचे दिसून येते. पडताळणीच्या उद्देशाने आम्हाला तुमचा वैयक्तिक पीएएन तपशील आवश्यक आहे.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.": "तुम्हाला समस्या येत राहिल्यास, कृपया तुमचे पीएएन कार्ड पुन्हा तपासा आणि तपशील पुन्हा एंटर करा. पुढील शंका किंवा मदतीसाठी, आम्हाला +91-9699900111 वर कॉल करा.",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "प्रदान केलेला पीएएन आमच्या रेकॉर्डमध्ये आधीपासूनच अस्तित्वात आहे. कृपया पडताळणीसाठी वेगळा पीएएन वापरून पहा.",
    "New User Registration": "नवीन वापरकर्ता नोंदणी",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "आमच्यासोबत तुमचा प्रवास सुरू करण्यासाठी, आम्ही सर्व नवीन वापरकर्त्यांना खाली वर्णन केलेल्या सोप्या चरणांचे अनुसरण करून नोंदणी करण्याची विनंती करतो:",
    "Registration": "नोंदणी",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "नोंदणी करण्यासाठी आणि आमच्या सर्वसमावेशक सत्यापन सेवांमध्ये प्रवेश मिळवण्यासाठी, कृपया एक-वेळ पेमेंट करून पुढे जा",
    "Rs. 500": "रु. ५००",
    "GST": "जीएसटी",
    "done": "पूर्ण",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "कृपया लक्षात घ्या की नोंदणी शुल्क सरकारी नियमांनुसार 18% जीएसटी (वस्तू आणि सेवा कर) च्या अधीन आहे.",
    "Total Amount Due:": "एकूण देय रक्कम:",
    "Rs. 590": "रु. ५९०",
    "Total Registration Fee + 18% GST": "एकूण नोंदणी शुल्क + 18% जीएसटी",
    "Secure Payment Gateway": "सुरक्षित पेमेंट गेटवे",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "खात्री बाळगा, तुमचे पेमेंट आणि वैयक्तिक माहिती आमच्या एन्क्रिप्टेड पेमेंट गेटवेद्वारे अत्यंत सुरक्षिततेने हाताळली जाते.",
    "Registration Complete - Thank You!": "नोंदणी पूर्ण - धन्यवाद!",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "अभिनंदन! तुम्ही नोंदणी प्रक्रिया पूर्ण केली आहे. आवश्यक माहिती प्रदान करण्याच्या तुमच्या समर्पणाने आम्हाला तुमची प्रभावीपणे सेवा देण्याच्या एक पाऊल जवळ आणले आहे.",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "तुमची नोंदणी पूर्ण झाल्यानंतर, आमची टीम तुमच्या गरजा कार्यक्षमतेने हाताळण्यासाठी पूर्णपणे सज्ज आहे. आम्ही तुम्हाला आश्वासन देतो की तुमच्या केसची अत्यंत काळजीपूर्वक आणि तपशीलाकडे लक्ष देऊन हाताळली जाईल.",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "तुम्हाला कधीही मदतीची आवश्यकता असल्यास किंवा काही शंका असल्यास, कृपया आमच्याशी कधीही संपर्क साधा. आम्ही तुमच्या समस्यांचे निराकरण करण्यासाठी आणि संपूर्ण प्रक्रियेदरम्यान सुरळीत प्रवास सुनिश्चित करण्यासाठी येथे आहोत.",
    "We eagerly anticipate the opportunity to assist you further.": "आम्ही तुम्हाला आणखी मदत करण्याच्या संधीची आतुरतेने अपेक्षा करतो.",
    "Warm regards,": "हार्दिक शुभेच्छा,",
    "PrivateCourt Team": "प्रायव्हेट कोर्ट टीम",
    "Secure UDYAM Number Validation": "सुरक्षित यूडीवाईएएम क्रमांक प्रमाणीकरण",
    "Enter UDYAM for": "साठी यूडीवाईएएम प्रविष्ट करा",
    "having GST number": "जीएसटी क्रमांक असणे",
    "Enter Your Udyam Number": "तुमचा यूडीवाईएएम क्रमांक टाका",
    "Error: Invalid UAM Number": "त्रुटी: अवैध यूऐएम क्रमांक",
    "We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos.": "आपल्याला कळविण्यास आम्हाला खेद होत आहे की प्रविष्ट केलेला यूऐएम क्रमांक अवैध किंवा चुकीचा आहे. कृपया खात्री करा की तुम्ही तपशील अचूकपणे एंटर केला आहे आणि कोणत्याही टायपिंगसाठी दोनदा तपासा.",
    "Server Overload: Please Retry Later": "सर्व्हर ओव्हरलोड: कृपया नंतर पुन्हा प्रयत्न करा",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "गैरसोयीबद्दल आम्ही दिलगीर आहोत आणि तुमच्या संयमाची प्रशंसा करतो. सध्या, आमच्या सर्व्हरला विनंत्यांची वाढ होत आहे, ज्यामुळे तात्पुरता विलंब होत आहे. निश्चिंत राहा, अचूकता आणि सुरक्षितता सुनिश्चित करण्यासाठी आम्ही परिश्रमपूर्वक तुमचा डेटा संकलित करत आहोत.",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "कृपया या व्यस्त कालावधीत आमच्यासोबत राहा आणि आम्ही तुम्हाला विनंती करतो की पुन्हा प्रयत्न करण्यापूर्वी थोडा वेळ थांबा. आम्ही प्रदान करण्याचा प्रयत्न करत असलेल्या सेवेची गुणवत्ता राखण्यासाठी तुमची समज महत्त्वाची आहे.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "तातडीच्या बाबी किंवा मदतीसाठी, आम्हाला +91-9699900111 वर कॉल करा.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "तुमच्या संयमाबद्दल आणि प्रायव्हेटकोर्टला सोपवल्याबद्दल धन्यवाद. तुमची सुरक्षा ही आमची प्राथमिकता आहे.",
    "If you continue to face issues, verify the": "तुम्हाला समस्या येत राहिल्यास, सत्यापित करा",
    "Applicant’s": "अर्जदाराचे",
    "Error: Invalid OTP": "त्रुटी: अवैध ओटीपी",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रविष्ट केलेला OTP चुकीचा आहे. कृपया ओटीपी पुन्हा तपासा आणि पुन्हा प्रयत्न करा.",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "पुढील सहाय्यासाठी आधार कार्ड किंवा आमच्या समर्थन कार्यसंघाशी +91-9699900111 वर संपर्क साधा. आम्ही तुमच्या सहकार्याची प्रशंसा करतो.",
    "Error: User already exists": "त्रुटी: वापरकर्ता आधीपासून अस्तित्वात आहे",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records. Please provide a different Aadhaar number for verification.": "गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रदान केलेला आधार क्रमांक आमच्या रेकॉर्डमध्ये आधीच अस्तित्वात आहे. कृपया पडताळणीसाठी वेगळा आधार क्रमांक द्या.",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रदान केलेले नाव पीएएन रेकॉर्डशी जुळत नाही. कृपया प्रविष्ट केलेले नाव तुमच्या पीएएन कार्डवरील माहितीशी जुळत असल्याची खात्री करा आणि पुन्हा प्रयत्न करा.",
    "Enter Bank Details for": "साठी बँक तपशील प्रविष्ट करा",
    "Error: Already Exists": "त्रुटी: आधीच अस्तित्वात आहे",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "आम्ही तुम्हाला कळविण्यास खेद करतो की तुम्ही प्रदान केलेला आयडी आमच्या सिस्टममध्ये आधीपासूनच अस्तित्वात आहे. कृपया दुसरा आयडी वापरून पहा.",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "तुम्हाला समस्या येत राहिल्यास, कृपया तुमचे बँक स्टेटमेंट सत्यापित करा किंवा प्रदान केलेल्या माहितीच्या अचूकतेची पुष्टी करण्यासाठी तुमच्या बँकेशी संपर्क साधा.",
    "Error: Name Not Matched": "त्रुटी: नाव जुळले नाही",
    "Good Afternoon": "शुभ दुपार",
    "Account security starts with verification": "खाते सुरक्षा पडताळणीसह सुरू होते",
    "OTP Validation for Account Access": "खाते प्रवेशासाठी ओटीपी प्रमाणीकरण",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "कृपया तुमचा इनबॉक्स तपासा, आणि पडताळणी प्रक्रिया पूर्ण करण्यासाठी नियुक्त फील्डमध्ये ओटीपी प्रविष्ट करा.",
    "Request a Resend": "पुन्हा पाठवण्याची विनंती करा",
    "Error: Incorrect OTP": "एरर: चुकीचा ओटीपी",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रविष्ट केलेला वन-टाइम पासवर्ड (OTP) चुकीचा आहे. कृपया खात्री करा की तुम्ही तुमच्या ईमेलमध्ये प्राप्त झालेला OTP अचूकपणे टाकला आहे.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "समस्या कायम राहिल्यास, तुम्ही पुन्हा पाठवा लिंकवर क्लिक करून किंवा +91-9699900111 वर आमच्या सपोर्ट टीमशी संपर्क साधून नवीन ओटीपी ची विनंती करू शकता.",
    "Double-check the email and try entering the OTP again.": "ईमेल दोनदा तपासा आणि ओटीपी पुन्हा एंटर करण्याचा प्रयत्न करा.",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "प्रायव्हेटकोर्टमध्ये, आम्ही आमच्या सेवांच्या सुरक्षिततेला आणि अखंडतेला प्राधान्य देतो. आमच्या सर्व वापरकर्त्यांसाठी विश्वसनीय आणि सुरक्षित अनुभव सुनिश्चित करण्यासाठी आमची जीएसटी पडताळणी प्रक्रिया बारकाईने तयार करण्यात आली आहे.",
    "Enter Your GSTIN": "तुमचा जीएसटीआईएन प्रविष्ट करा",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "कृपया तुमचा योग्य जीएसटी ओळख क्रमांक (GSTIN) 00-XXXXX-0000-X-0-X-0 फॉरमॅटमध्ये एंटर करा.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.": "तुमचा जीएसटीआयएन एंटर केल्यावर, प्रायव्हेटकोर्ट त्याच्याशी संबंधित आवश्यक माहिती काळजीपूर्वक गोळा करेल. यामध्ये तुमचे पूर्ण नाव, जन्मतारीख, निवासी पत्ता आणि संपर्क तपशील यांचा समावेश आहे. व्यवसाय मालकांसाठी, आम्ही तुमच्या जीएसटीआईएन शी जोडलेले सर्व संबंधित व्यवसाय तपशील समाविष्ट करण्यासाठी आमचा डेटा संग्रह वाढवतो, जसे की एमसीए तपशील आणि जीएसटी रिटर्न फाइलिंगचे रेकॉर्ड.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "आमची सिस्टीम आर्थिक अंतर्दृष्टीसाठी तुमच्या सी आई बी आई एल स्कोअरचे मूल्यांकन करते आणि तुमच्या जीएसटीआईएन शी संबंधित कोणत्याही चालू न्यायालयीन खटल्यांसाठी क्रॉस-व्हेरिफिकेशन करते.",
    "Error: Invalid GST Format": "त्रुटी: अवैध जीएसटी स्वरूप",
    "Please enter your personal PAN": "कृपया तुमचा वैयक्तिक पीएएन प्रविष्ट करा",
    "Already Exists": "आधिपासूनच अस्तित्वात आहे",
    "Invalid": "अवैध",
    "Secure PAN Validation Process": "सुरक्षित पीएएन प्रमाणीकरण प्रक्रिया",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "ही पडताळणी प्रक्रिया विश्वासार्ह आणि सुरक्षित अनुभव सुनिश्चित करून आमच्या सेवांची अखंडता राखण्यासाठी डिझाइन केलेली आहे.",
    "Enter Your PAN Number": "तुमचा पीएएन क्रमांक टाका",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "कृपया तुमचा योग्य पीएएन क्रमांक XXXXX-0000-X या फॉरमॅटमध्ये टाका.",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "तुमचे पूर्ण नाव, जन्मतारीख, निवासी पत्ता आणि संपर्क तपशील यासारखी तुमच्या पीएएनशी संबंधित आवश्यक माहिती प्राइवेट कोर्ट काळजीपूर्वक गोळा करेल. व्यवसाय मालकांसाठी, आम्ही तुमच्या पीएएनशी जोडलेले सर्व संबंधित व्यवसाय तपशील जसे की GST तपशील, एमसीए तपशील आणि जीएसटी रिटर्न फाइलिंगचे रेकॉर्ड समाविष्ट करण्यासाठी आमचा डेटा संग्रह वाढवतो.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "आमची सिस्टीम आर्थिक अंतर्दृष्टीसाठी तुमच्या सी आई बी आई एल स्कोअरचे मूल्यांकन करते आणि तुमच्या पीएएन शी संबंधित कोणत्याही चालू असलेल्या न्यायालयीन प्रकरणांसाठी क्रॉस-व्हेरिफिकेशन करते.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "दिलेला पीएएन तपशील कंपनीचा असल्याचे दिसून येते. पडताळणीच्या उद्देशाने आम्हाला तुमचा वैयक्तिक पीएएन तपशील आवश्यक आहे.",



    "Data Compilation in Progress: Please Allow Processing Time": "डेटा संकलन प्रगतीपथावर आहे: कृपया प्रक्रियेसाठी वेळ द्या",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "तुमची माहिती दिल्याबद्दल धन्यवाद. तुमची सर्वसमावेशक प्रोफाइल स्थापित करण्यासाठी आमची प्रणाली सध्या पीएएन, आधार, जीएसटी, ईमेल आणि अधिक तपशीलांसह, तुम्ही प्रविष्ट केलेल्या डेटाचे विश्लेषण आणि संकलित करण्याच्या प्रक्रियेत आहे.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "या ऑपरेशनला काही क्षण लागू शकतात. या वेळी, तुमची स्क्रीन किमान क्रियाकलाप दर्शवू शकते. कृपया खात्री बाळगा की हा आमच्या डेटा संकलन प्रक्रियेचा एक नियमित भाग आहे.",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "अचूकता आणि सुरक्षितता टिकवून ठेवण्यासाठी तुमचे प्रोफाइल काळजीपूर्वक तयार केले जात आहे. आम्ही तुमच्या संयमाची आणि समजूतदारपणाची मनापासून प्रशंसा करतो कारण आम्ही तुमचा अनुभव वाढवण्यासाठी कार्य करतो.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "तातडीच्या बाबींसाठी किंवा मदतीसाठी, आम्हाला +91-9699900111 वर कॉल करा.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "तुमच्या संयमाबद्दल आणि प्रायव्हेटकोर्टला सोपवल्याबद्दल धन्यवाद. तुमची सुरक्षा ही आमची प्राथमिकता आहे.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "कृपया तुमचा आधार क्रमांक टाका. ही प्रक्रिया तुमच्या सुरक्षिततेसाठी अचूक क्रॉस-चेकिंग सुनिश्चित करते. तुमच्या सहकार्यासाठी धन्यवाद.",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रविष्ट केलेला आधार क्रमांक अवैध आहे. कृपया दोनदा तपासा आणि तुम्ही प्रविष्ट केले असल्याची खात्री करा",
    "Applicant’s correct 12-digit Aadhaar number.": "अर्जदाराचा १२ अंकी आधार क्रमांक योग्य आहे.",
    "Error: Invalid OTP": "त्रुटी: अवैध ओ टी पी",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रविष्ट केलेला ओ टी पी चुकीचा आहे. कृपया ओ टी पी पुन्हा तपासा आणि पुन्हा प्रयत्न करा.",
    "Welcome": "स्वागत आहे",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "अखंड पडताळणी प्रक्रियेसाठी तुमच्या बँक खात्याच्या माहितीची अचूकता सुनिश्चित करणे महत्त्वाचे आहे. कृपया तुमचा वैध खाते क्रमांक प्रविष्ट करा आणि तुमच्या खात्याशी संबंधित योग्य IFSC कोड प्रविष्ट करा.",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "ही पडताळणी पायरी आमच्या सेवांची सुरक्षितता वाढवण्यासाठी आणि तुम्हाला विश्वासार्ह अनुभव देण्यासाठी डिझाइन केलेली आहे.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रदान केलेले नाव जीएसटी रेकॉर्डशी जुळत नाही. कृपया प्रविष्ट केलेले नाव तुमच्या जीएसटी कार्डावरील माहितीशी जुळत असल्याची खात्री करा आणि पुन्हा प्रयत्न करा.",
    "Business Verification Process": "व्यवसाय पडताळणी प्रक्रिया",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "सुरक्षित व्यवसाय प्रमाणीकरण सुनिश्चित करणे- ही पडताळणी प्रक्रिया आमच्या सेवांची अखंडता टिकवून ठेवण्यासाठी, एक विश्वासार्ह आणि सुरक्षित अनुभव प्रदान करण्यासाठी काळजीपूर्वक डिझाइन केलेली आहे.",
    "Please provide details for each of your businesses for accurate validation.": "कृपया अचूक प्रमाणीकरणासाठी तुमच्या प्रत्येक व्यवसायाचे तपशील प्रदान करा.",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "प्रायव्हेटकोर्ट तुमच्या पीएएनशी संबंधित आवश्यक माहिती गोळा करेल जसे की तुमचे पूर्ण नाव, जन्मतारीख, निवासी पत्ता आणि संपर्क तपशील. व्यवसाय मालकांसाठी, आम्ही तुमच्या पीएएनशी जोडलेले सर्व संबंधित व्यवसाय तपशील जसे की जीएसटी तपशील, एमसीए तपशील आणि जीएसटी रिटर्न फाइलिंगचे रेकॉर्ड समाविष्ट करण्यासाठी आमचा डेटा संग्रह वाढवतो.",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "आमची सिस्टीम आर्थिक अंतर्दृष्टीसाठी तुमच्या सी आई बी आई एल स्कोअरचे मूल्यमापन करते आणि तुमच्या पीएएन शी संबंधित कोणत्याही चालू न्यायालयीन प्रकरणांसाठी क्रॉस-व्हेरिफिकेशन करते.",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "तुमच्या खात्याची सुरक्षितता सुनिश्चित करण्यासाठी, कृपया तुमचा वैध ईमेल आयडी निर्दिष्ट फॉरमॅटमध्ये प्रविष्ट करा- username@domain.com. तुमचा ईमेल पत्ता आमच्या सिस्टममध्ये अस्तित्वात नसल्यास,",
    "account will be created for you.": "तुमच्यासाठी खाते तयार केले जाईल.",
    "GST Verification Process": "जीएसटी पडताळणी प्रक्रिया",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "आम्हाला तुम्हाला कळवण्यास खेद होत आहे की एंटर केलेला जीएसटी नंबर आवश्यक फॉरमॅटशी जुळत नाही (00-XXXXX-0000-X-0-X-0). कृपया तुम्ही योग्य जीएसटी तपशील एंटर केल्याची खात्री करा आणि अचूक पडताळणीसाठी निर्दिष्ट फॉरमॅट फॉलो करा.",
    "Verify GST": "जीएसटी सत्यापित करा",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "अभिनंदन! तुम्ही नोंदणी प्रक्रिया पूर्ण केली आहे. आवश्यक माहिती प्रदान करण्याच्या तुमच्या समर्पणाने आम्हाला तुमची प्रभावीपणे सेवा देण्याच्या एक पाऊल जवळ आणले आहे.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "गैरसोयीबद्दल आम्ही दिलगीर आहोत. प्रदान केलेले नाव जीएसटी रेकॉर्डशी जुळत नाही. कृपया प्रविष्ट केलेले नाव तुमच्या जीएसटी कार्डावरील माहितीशी जुळत असल्याची खात्री करा आणि पुन्हा प्रयत्न करा.",
    "Skip": "वगळा",
    "Server Overload: Please Retry Later": "सर्व्हर ओव्हरलोड: कृपया नंतर पुन्हा प्रयत्न करा",
    "Back To Home": "परत घराच्या दिशेने",




    
    "Verify Aadhaar": "आधार सत्यापित करा",
    "having PAN number":  "पॅन नंबर असणे",
    "Verify Bank":  "बँक सत्यापित करा",
    "Select Businesses":  "व्यवसाय निवडा",
    "I am individual":  "मी वैयक्तिक आहे",
    "Securely Verify Your Credit Score": "तुमचा क्रेडिट स्कोअर सुरक्षितपणे सत्यापित करा",
    "Enter Your Mobile Number":"तुमचा मोबाईल नंबर एंटर करा" ,
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.":"तुमच्या क्रेडिट स्कोअरमध्ये सुरक्षितपणे प्रवेश करण्यासाठी, कृपया खाली तुमचा मोबाइल नंबर प्रविष्ट करा. तुमची ओळख सत्यापित करण्यासाठी आणि तुमच्या माहितीची गोपनीयता सुनिश्चित करण्यासाठी आम्ही तुम्हाला एक-वेळचा पासवर्ड (OTP) पाठवू." ,
    "Verify without OTP": "OTP शिवाय पडताळणी करा",
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "सबमिशन केल्यावर, OTP साठी तुमचे संदेश त्वरित तपासा. ही पायरी सुनिश्चित करते की तुमची आर्थिक माहिती संरक्षित राहते तोपर्यंत तुम्ही तुमच्या क्रेडिट प्रोफाइलमध्ये मौल्यवान अंतर्दृष्टी मिळवता.",
    "Enter Your OTP Here":  "येथे तुमचा OTP प्रविष्ट करा",
    "Error: Invalid Mobile Number": "त्रुटी: अवैध मोबाईल नंबर",
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the":  "आम्ही गैरसोयीबद्दल दिलगीर आहोत. प्रविष्ट केलेला मोबाइल नंबर अवैध आहे. कृपया पुन्हा तपासा आणि आपण प्रविष्ट केले असल्याची खात्री करा",
    "Applicant’s correct 10-digit Mobile number.":  "अर्जदाराचा 10 अंकी मोबाईल नंबर योग्य आहे.",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.":  "पुढील सहाय्यासाठी मोबाईल नंबर किंवा आमच्या समर्थन कार्यसंघाशी +91-9699900111 वर संपर्क साधा. आम्ही तुमच्या सहकार्याची प्रशंसा करतो.",
    "Good Morning":"शुभ सकाळ" ,
    "Good Evening": "शुभ संध्याकाळ",
    "Good Night":  "शुभ रात्री",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0). Please ensure you have entered the correct GST details and follow the specified format for accurate verification.":  "आम्हाला तुम्हाला कळवण्यास खेद होत आहे की प्रविष्ट केलेला जीएसटी क्रमांक आवश्यक स्वरूपाशी जुळत नाही (00-XXXXXX-0000-X-0-X-0). कृपया तुम्ही योग्य GST तपशील प्रविष्ट केल्याची खात्री करा आणि अचूक पडताळणीसाठी निर्दिष्ट स्वरूपाचे अनुसरण करा.",
    "Error: Invalid PAN category": "त्रुटी: अवैध पॅन श्रेणी",
    "Rs.":  "रु.",
    "Verification Complete – Thank You!":  "सत्यापन पूर्ण - धन्यवाद!",
    "Congratulations! You've completed the Verification process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.":  "अभिनंदन! तुम्ही पडताळणी प्रक्रिया पूर्ण केली आहे. आवश्यक माहिती प्रदान करण्याच्या तुमच्या समर्पणामुळे आम्हाला तुमची प्रभावीपणे सेवा देण्याच्या एक पाऊल जवळ आले आहे.",
    "With your Verification finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.":  "तुमची पडताळणी अंतिम झाल्यामुळे, आमची टीम तुमच्या गरजा कार्यक्षमतेने हाताळण्यासाठी पूर्णपणे सुसज्ज आहे. आम्ही तुम्हाला खात्री देतो की तुमची केस अत्यंत काळजीपूर्वक आणि तपशीलाकडे लक्ष देऊन हाताळली जाईल.",
    "having Gst number":  "जीएसटी क्रमांक असणे",
    "Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.": "तुमचा UDYAM नंबर आम्हाला तुमच्या व्यवसायाशी निगडित महत्त्वपूर्ण तपशील गोळा करण्याची परवानगी देतो. यामध्ये तुमचा व्यवसाय पत्ता, सद्य स्थिती, सेवा तपशील आणि संस्थेचा प्रकार समाविष्ट आहे.",



    "Securely Verify Your Credit Score": "तुमचा क्रेडिट स्कोअर सुरक्षितपणे सत्यापित करा",
    "Enter Your Mobile Number": "तुमचा मोबाईल नंबर एंटर करा",
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "तुमच्या क्रेडिट स्कोअरमध्ये सुरक्षितपणे प्रवेश करण्यासाठी, कृपया खाली तुमचा मोबाइल नंबर प्रविष्ट करा. तुमची ओळख सत्यापित करण्यासाठी आणि तुमच्या माहितीची गोपनीयता सुनिश्चित करण्यासाठी आम्ही तुम्हाला एक-वेळचा पासवर्ड (OTP) पाठवू.",
    "For any queries or assistance, feel free to call us at +91-9699900111.": "कोणत्याही शंका किंवा सहाय्यासाठी, आम्हाला +91-9699900111 वर कॉल करा.",
    "Verify without OTP": "OTP शिवाय पडताळणी करा",
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "सबमिशन केल्यावर, OTP साठी तुमचे मेसेज तत्काळ तपासा. ही पायरी तुमची आर्थिक माहिती सुरक्षित राहते हे सुनिश्चित करते जेव्हा तुम्ही तुमच्या क्रेडिट प्रोफाइलमध्ये मौल्यवान अंतर्दृष्टी मिळवता.",
    "Enter Your OTP Here": "येथे तुमचा OTP प्रविष्ट करा",
    "Error: Invalid Mobile Number": "त्रुटी: अवैध मोबाईल नंबर",
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "आम्ही गैरसोयीबद्दल दिलगीर आहोत. प्रविष्ट केलेला मोबाइल नंबर अवैध आहे. कृपया पुन्हा तपासा आणि आपण प्रविष्ट केले असल्याची खात्री करा",
    "Applicant’s correct 10-digit Mobile number.": "अर्जदाराचा 10 अंकी मोबाईल नंबर योग्य आहे.",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "पुढील सहाय्यासाठी मोबाईल नंबर किंवा आमच्या समर्थन कार्यसंघाशी +91-9699900111 वर संपर्क साधा. आम्ही तुमच्या सहकार्याची प्रशंसा करतो.",
    "Error: Invalid OTP": "त्रुटी: अवैध OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "आम्ही गैरसोयीबद्दल दिलगीर आहोत. प्रविष्ट केलेला OTP चुकीचा आहे. कृपया OTP पुन्हा तपासा आणि पुन्हा प्रयत्न करा.",
    "mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "पुढील सहाय्यासाठी मोबाईल नंबर किंवा आमच्या समर्थन कार्यसंघाशी +91-9699900111 वर संपर्क साधा. आम्ही तुमच्या सहकार्याची प्रशंसा करतो.",
    "Verify OTP": "ओटीपी सत्यापित करा",
    "Verify": "सत्यापित करा",

}

export default MarathiTranslation;