const OdiaTranslation = {
    'Email Security Check': 'ଇମେଲ ସୁରକ୍ଷା ଯାଞ୍ଚ |',
    'Verify Your Email for Account Access': 'ଖାତା ପ୍ରବେଶ ପାଇଁ ଆପଣଙ୍କର ଇମେଲ୍ ଯାଞ୍ଚ କରନ୍ତୁ |',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": 'ଆପଣଙ୍କ ଖାତାର ସୁରକ୍ଷା ନିଶ୍ଚିତ କରିବାକୁ, ଦୟାକରି ନିର୍ଦ୍ଦିଷ୍ଟ ଫର୍ମାଟରେ ଆପଣଙ୍କର ବ valid ଧ ଇମେଲ ID- username@domain.com ପ୍ରବେଶ କରନ୍ତୁ | ଯଦି ଆପଣଙ୍କର ଇମେଲ୍ ଠିକଣା ଆମ ସିଷ୍ଟମରେ ନାହିଁ,',
    'NEW USER': 'ନୂତନ ଉପଯୋଗକର୍ତ୍ତା |',
    'account will be created for you': 'ଖାତା ଆପଣଙ୍କ ପାଇଁ ସୃଷ୍ଟି ହେବ |',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'ଥରେ ଦାଖଲ ହେବା ପରେ, ତୁମର ପ୍ରଦାନ କରାଯାଇଥିବା ଇମେଲ ଠିକଣାକୁ ପଠାଯାଇଥିବା ଏକ ଯାଞ୍ଚ OTP (ଏକ-ସମୟ ପାସୱାର୍ଡ) ପାଇବ |',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'ଯେକ any ଣସି ପ୍ରଶ୍ନ କିମ୍ବା ସହାୟତା ପାଇଁ, ଆମକୁ + 91-9699900111 ରେ କଲ କରିବାକୁ ମନା କରନ୍ତୁ |',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'ପ୍ରାଇଭେଟକୋର୍ଟ୍ ଦାୟିତ୍ୱ ଦେଇଥିବାରୁ ଧନ୍ୟବାଦ | ତୁମର ସୁରକ୍ଷା ଆମର ପ୍ରାଥମିକତା |',
    'Verify': 'ଯାଞ୍ଚ କରନ୍ତୁ |',
    'Invalid Email Address': 'ଅବ val ଧ ଇମେଲ ଠିକଣା |',
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ପ୍ରବିଷ୍ଟ ହୋଇଥିବା ଇମେଲ ଠିକଣା ଅବ alid ଧ ଅଟେ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ଏକ ସଠିକ୍ ଏବଂ ସଠିକ୍ ଫର୍ମାଟ୍ ହୋଇଥିବା ଇମେଲ୍ ଠିକଣା ପ୍ରବେଶ କରିଛନ୍ତି |', 'Malayalam': 'പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, ഇമെയിൽ ഫോർമാറ്റിലെ അക്ഷരത്തെറ്റുകളോ പിശകുകളോ ഉണ്ടോ എന്ന് രണ്ടുതവണ പരിശോധിക്കുക (ഉദാ. username@example.com).',
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'ଯଦି ସମସ୍ୟା ଜାରି ରହେ, ଇମେଲ ଫର୍ମାଟରେ ଥିବା କ typ ଣସି ଟାଇପ୍ କିମ୍ବା ତ୍ରୁଟି ପାଇଁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ (ଯଥା, username@example.com) |',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'ଅଧିକ ପ୍ରଶ୍ନ କିମ୍ବା ସହାୟତା ପାଇଁ, ଆମକୁ + 91-9699900111 ରେ କଲ କରିବାକୁ ମୁକ୍ତ ମନ ଦିଅନ୍ତୁ |',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'ପ୍ରାଇଭେଟକୋର୍ଟ୍ ଦାୟିତ୍ୱ ଦେଇଥିବାରୁ ଧନ୍ୟବାଦ | ତୁମର ସୁରକ୍ଷା ଆମର ପ୍ରାଥମିକତା |',
    'Secure UDYAM Number Validation': 'ସୁରକ୍ଷିତ उद्यम ସଂଖ୍ୟା ବ id ଧତା |',
    'Enter Your Udyam Number': 'ତୁମର ଉଦୟମ୍ ନମ୍ବର ପ୍ରବେଶ କର |',
    'Please enter your correct UDYAM Number.': 'ଦୟାକରି ଆପଣଙ୍କର ସଠିକ୍ उद्यम ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.': 'ତୁମର उद्यम ନମ୍ବର ଆମ ବ୍ୟବସାୟ ସହିତ ଜଡିତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ବିବରଣୀ ସଂଗ୍ରହ କରିବାକୁ ଆମକୁ ଅନୁମତି ଦିଏ | ଏଥିରେ ଆପଣଙ୍କର ବ୍ୟବସାୟ ଠିକଣା, ସାମ୍ପ୍ରତିକ ସ୍ଥିତି, ସେବା ବିବରଣୀ ଏବଂ ସଂଗଠନ ପ୍ରକାର ଅନ୍ତର୍ଭୁକ୍ତ |',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.': 'ଆପଣଙ୍କର उद्यम ନମ୍ବରର ସଠିକତା ନିଶ୍ଚିତ କରିବା ଏକ ନିରବିହୀନ ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ପାଇଁ ଅତ୍ୟନ୍ତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ |',
    'UDYAM': 'उद्यम',
    'Error: Invalid UDYAM Number': 'ତ୍ରୁଟି: ଅବ U ଧ ଯାଞ୍ଚ କରନ୍ତୁ ସଂଖ୍ୟା |',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.': 'ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ପ୍ରବେଶ କରିଥିବା UDYAM ନମ୍ବର ଅବ alid ଧ କିମ୍ବା ଭୁଲ ଅଟେ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସଠିକ୍ UDYAM ବିବରଣୀ ପ୍ରବେଶ କରିଛନ୍ତି ଏବଂ ଯେକ any ଣସି ଟାଇପ୍ ପାଇଁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ |',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.': 'ଯଦି ଆପଣ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହେବାକୁ ଲାଗନ୍ତି, ଦୟାକରି ଆପଣଙ୍କର UDYAM ସାର୍ଟିଫିକେଟ୍ ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ସବିଶେଷ ବିବରଣୀ ପୁନ - ପ୍ରବେଶ କରନ୍ତୁ |',
    'Secure PAN Validation Process': 'ସୁରକ୍ଷିତ ପାନ୍ ବ id ଧତା ପ୍ରକ୍ରିୟା |',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.': 'ଏହି ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ଆମର ସେବାଗୁଡିକର ଅଖଣ୍ଡତାକୁ ବଜାୟ ରଖିବା ପାଇଁ ଡିଜାଇନ୍ କରାଯାଇଛି, ଏକ ନିର୍ଭରଯୋଗ୍ୟ ଏବଂ ସୁରକ୍ଷିତ ଅଭିଜ୍ଞତା ନିଶ୍ଚିତ କରେ |',
    'Enter Your PAN Number': 'ଆପଣଙ୍କର ପାନ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |',
    'Please enter your correct PAN number in the format XXXXX-0000-X.': 'ଦୟାକରି XXXXX-0000-X ଫର୍ମାଟରେ ଆପଣଙ୍କର ସଠିକ୍ PAN ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.': 'ପ୍ରାଇଭେଟକୋର୍ଟ୍ ତୁମର ପାନ୍ ସହିତ ଜଡିତ ଅତ୍ୟାବଶ୍ୟକୀୟ ସୂଚନା ଯଥା ତୁମର ସମ୍ପୂର୍ଣ୍ଣ ନାମ, ଜନ୍ମ ତାରିଖ, ଆବାସିକ ଠିକଣା, ଏବଂ ଯୋଗାଯୋଗ ବିବରଣୀ ସଂଗ୍ରହ କରିବ | ବ୍ୟବସାୟ ମାଲିକମାନଙ୍କ ପାଇଁ, ଆମେ ଆପଣଙ୍କର ତଥ୍ୟ ସଂଗ୍ରହକୁ ଆପଣଙ୍କର ପ୍ୟାନ୍ ସହିତ ଜଡିତ ସମସ୍ତ ପ୍ରଯୁଜ୍ୟ ବ୍ୟବସାୟ ବିବରଣୀ ଯେପରିକି ଜିଏସ୍ଟି ବିବରଣୀ, MCA ନିର୍ଦ୍ଦିଷ୍ଟତା, ଏବଂ ଜିଏସ୍ଟି ରିଟର୍ନ ଫାଇଲ୍ ରେକର୍ଡଗୁଡିକ ଅନ୍ତର୍ଭୁକ୍ତ କରିବା ପାଇଁ ବିସ୍ତାର କରୁ |',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.': 'ଆର୍ଥିକ ଅନ୍ତର୍ନିହିତତା ପାଇଁ ଆମର ସିଷ୍ଟମ୍ ଆପଣଙ୍କର CIBIL ସ୍କୋରକୁ ଆକଳନ କରେ ଏବଂ ଆପଣଙ୍କର ପାନ୍ ସହିତ ଜଡିତ କ ongoing ଣସି ଚାଲୁଥିବା କୋର୍ଟ ମାମଲା ପାଇଁ କ୍ରସ୍-ଯାଞ୍ଚ କରେ |',
    'Error: Invalid PAN Format': 'ତ୍ରୁଟି: ଅବ val ଧ PAN ଫର୍ମାଟ୍ |',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.': 'ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ପ୍ରବିଷ୍ଟ ହୋଇଥିବା PAN ନମ୍ବର ଆବଶ୍ୟକ ଫର୍ମାଟ୍ (XXXXX-0000-X) ସହିତ ମେଳ ଖାଉ ନାହିଁ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସଠିକ୍ PAN ବିବରଣୀ ପ୍ରବେଶ କରିଛନ୍ତି ଏବଂ ସଠିକ୍ ଯାଞ୍ଚ ପାଇଁ ନିର୍ଦ୍ଦିଷ୍ଟ ଫର୍ମାଟ୍ ଅନୁସରଣ କରନ୍ତୁ |',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.': 'ଯଦି ଆପଣ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହେବାକୁ ଲାଗନ୍ତି, ଦୟାକରି ଆପଣଙ୍କର ପାନ୍ କାର୍ଡକୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ବିବରଣୀଗୁଡିକ ପୁନ - ପ୍ରବେଶ କରନ୍ତୁ | ଅଧିକ ପ୍ରଶ୍ନ କିମ୍ବା ସହାୟତା ପାଇଁ, ଆମକୁ + 91-9699900111 ରେ କଲ କରିବାକୁ ମୁକ୍ତ ମନ ଦିଅନ୍ତୁ |',
    'Welcome XXXX': 'ସ୍ Welcome ାଗତ XXXX',
    'Account security starts with verification': 'ଖାତା ସୁରକ୍ଷା ଯାଞ୍ଚରୁ ଆରମ୍ଭ ହୁଏ |',
    'OTP Validation for Account Access': 'ଖାତା ପ୍ରବେଶ ପାଇଁ OTP ବ id ଧତା |',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.': 'ଦୟାକରି ଆପଣଙ୍କର ଇନବକ୍ସ ଯାଞ୍ଚ କରନ୍ତୁ, ଏବଂ ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ସମାପ୍ତ କରିବାକୁ ନିର୍ଦ୍ଦିଷ୍ଟ କ୍ଷେତ୍ରରେ OTP ପ୍ରବେଶ କରନ୍ତୁ |',
    "Didn't receive OTP?": 'OTP ଗ୍ରହଣ କରିନାହାଁନ୍ତି କି?',
    'Request a Resend': 'ଏକ ଫେରସ୍ତ ପାଇଁ ଅନୁରୋଧ |',
    'Verify OTP': 'OTP ଯାଞ୍ଚ କରନ୍ତୁ |',
    'Error: Incorrect OTP': 'ତ୍ରୁଟି: ଭୁଲ OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.': 'ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ | ପ୍ରବିଷ୍ଟ ହୋଇଥିବା ଏକ-ସମୟ ପାସୱାର୍ଡ (OTP) ଭୁଲ ଅଟେ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ଆପଣଙ୍କର ଇମେଲରେ ଗ୍ରହଣ କରାଯାଇଥିବା OTP କୁ ସଠିକ୍ ଭାବରେ ପ୍ରବେଶ କରିଛନ୍ତି |',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.': 'ଯଦି ଏହି ସମସ୍ୟା ଜାରି ରହେ, ଆପଣ ପୁନ end ପଠାଇବା ଲିଙ୍କରେ କ୍ଲିକ୍ କରି କିମ୍ବା + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରି ଏକ ନୂତନ OTP ଅନୁରୋଧ କରିପାରିବେ |',
    'Double-check the email and try entering the OTP again.': 'ଇମେଲକୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର OTP ପ୍ରବେଶ କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ |',
    'Choose Profile': 'ପ୍ରୋଫାଇଲ୍ ବାଛନ୍ତୁ |',
    'Setting Your Default Profile': 'ଆପଣଙ୍କର ଡିଫଲ୍ଟ ପ୍ରୋଫାଇଲ୍ ସେଟ୍ କରୁଛି |',
    'Please choose the default profile for a seamless experience.': 'ଏକ ବିହୀନ ଅଭିଜ୍ଞତା ପାଇଁ ଦୟାକରି ଡିଫଲ୍ଟ ପ୍ରୋଫାଇଲ୍ ବାଛନ୍ତୁ |',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.": 'ଯଦି ଆପଣ ଏକାଧିକ କମ୍ପାନୀ ପରିଚାଳନା କରନ୍ତି, ଆପଣ ଡିଫଲ୍ଟ ଭାବରେ ସେଟ୍ କରିବାକୁ ଚାହୁଁଥିବା ପ୍ରୋଫାଇଲ୍ ଚୟନ କରନ୍ତୁ | ଏହା ସୁନିଶ୍ଚିତ କରେ ଯେ ପ୍ରତ୍ୟେକ ଥର ଆପଣ ଲଗ୍ ଇନ୍ କରିବାବେଳେ, ଆପଣଙ୍କର ପସନ୍ଦିତ କମ୍ପାନୀ ସହିତ ଜଡିତ ସୂଚନାକୁ ଶୀଘ୍ର ଆକ୍ସେସ୍ କରିବେ |',
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.': 'ସଠିକ୍ ଚୟନ କରିବା ବର୍ତ୍ତମାନ ତୁମର ଭବିଷ୍ୟତର ଲଗଇନ୍ଗୁଡ଼ିକୁ ସରଳ କରିଥାଏ ଏବଂ ତୁମର ପ୍ରାଥମିକ ବ୍ୟବସାୟ ସଂସ୍ଥା ଉପରେ ଆଧାର କରି ଏକ ଅନୁକୂଳ ଅଭିଜ୍ଞତା ପ୍ରଦାନ କରେ |',
    'Go to Dashboard': 'ડેશબોર્ડ પર જાઓ',
    'Data Compilation in Progress': 'ପ୍ରଗତିରେ ତଥ୍ୟ ସଂକଳନ:',
    'Please Allow Processing Time': 'ଦୟାକରି ପ୍ରକ୍ରିୟାକରଣ ସମୟକୁ ଅନୁମତି ଦିଅନ୍ତୁ |',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": 'ଆପଣଙ୍କର ସୂଚନା ପ୍ରଦାନ କରିଥିବାରୁ ଧନ୍ୟବାଦ | ଆମର ବିସ୍ତୃତ ପ୍ରୋଫାଇଲ୍ ପ୍ରତିଷ୍ଠା କରିବା ପାଇଁ ଆମର ସିଷ୍ଟମ୍ ବର୍ତ୍ତମାନ ଆପଣ ପ୍ରବେଶ କରିଥିବା ତଥ୍ୟକୁ ବିଶ୍ଳେଷଣ ଏବଂ ସଂକଳନ ପ୍ରକ୍ରିୟାରେ ଅଛି, ଯେପରିକି ପାନ୍, ଆଧାର, ଜିଏସ୍ଟି, ଇମେଲ୍, ଏବଂ ଅନ୍ୟାନ୍ୟ ବିବରଣୀ ସହିତ |',
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": 'ଏହି ଅପରେସନ୍ କିଛି ମୁହୂର୍ତ୍ତ ନେଇପାରେ | ଏହି ସମୟ ମଧ୍ୟରେ, ଆପଣଙ୍କର ସ୍କ୍ରିନ୍ ସର୍ବନିମ୍ନ କାର୍ଯ୍ୟକଳାପ ଦେଖାଇପାରେ | ଦୟାକରି ନିଶ୍ଚିତ ହୁଅନ୍ତୁ ଯେ ଏହା ଆମର ତଥ୍ୟ ସଂକଳନ ପ୍ରକ୍ରିୟାର ଏକ ନିତିଦିନିଆ ଅଂଶ |',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.': 'ସଠିକତା ଏବଂ ନିରାପତ୍ତାକୁ ସମର୍ଥନ କରିବା ପାଇଁ ତୁମର ପ୍ରୋଫାଇଲ୍ ଯତ୍ନର ସହିତ ପ୍ରସ୍ତୁତ ହେଉଛି | ଆମେ ତୁମର ଅଭିଜ୍ଞତା ବ to ାଇବା ପାଇଁ କାର୍ଯ୍ୟ କରୁଥିବାରୁ ଆମେ ତୁମର ଧ patience ର୍ଯ୍ୟ ଏବଂ ବୁ understanding ାମଣାକୁ ଆନ୍ତରିକତାର ସହିତ ପ୍ରଶଂସା କରୁ |',
    'Start Your Bank Account Verification': 'ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ଯାଞ୍ଚ ଆରମ୍ଭ କରନ୍ତୁ |',
    'Bank Verification': 'ବ୍ୟାଙ୍କ ଯାଞ୍ଚ',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.': 'ଏକ ବିହୀନ ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ପାଇଁ ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ସୂଚନାର ସଠିକତା ନିଶ୍ଚିତ କରିବା ଅତ୍ୟନ୍ତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ | ଦୟାକରି ଆପଣଙ୍କର ଆକାଉଣ୍ଟ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ, ଏବଂ ଆପଣଙ୍କର ଖାତା ସହିତ ଜଡିତ ସଠିକ୍ IFSC କୋଡ୍ ଇନପୁଟ୍ କରନ୍ତୁ |',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.': 'ଏହି ଯାଞ୍ଚ ପଦକ୍ଷେପ ଆମର ସେବାଗୁଡିକର ସୁରକ୍ଷାକୁ ବ enhance ାଇବା ଏବଂ ଆପଣଙ୍କୁ ଏକ ନିର୍ଭରଯୋଗ୍ୟ ଅଭିଜ୍ଞତା ପ୍ରଦାନ କରିବା ପାଇଁ ଡିଜାଇନ୍ କରାଯାଇଛି |',
    'Account Number': 'ଖାତା ସଂଖ୍ୟା',
    'IFSC code': 'IFSC କୋଡ୍ |',
    'Error: Invalid Bank Account or IFSC Code': 'ତ୍ରୁଟି: ଅବ val ଧ ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ କିମ୍ବା IFSC କୋଡ୍ |',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.': 'ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ କିମ୍ବା IFSC କୋଡ୍ ପ୍ରବେଶ ଅବ inv ଧ କିମ୍ବା ଭୁଲ ଅଟେ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସଠିକ୍ ଭାବରେ ଉଭୟ ବିବରଣୀ ପ୍ରବେଶ କରିଛନ୍ତି ଏବଂ ଯେକ any ଣସି ଟାଇପ୍ ପାଇଁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ |',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.': 'ଯଦି ଆପଣ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହୁଅନ୍ତି, ଦୟାକରି ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଷ୍ଟେଟମେଣ୍ଟ ଯାଞ୍ଚ କରନ୍ତୁ କିମ୍ବା ପ୍ରଦାନ କରାଯାଇଥିବା ସୂଚନାର ସଠିକତା ନିଶ୍ଚିତ କରିବାକୁ ଆପଣଙ୍କ ବ୍ୟାଙ୍କ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ |',
    'Aadhaar Details Verification': 'ଆଧାର ବିବରଣୀ ଯାଞ୍ଚ |',
    'Enter Your Aadhaar Number': 'ଆପଣଙ୍କର ଆଧାର ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.': 'ଦୟାକରି ଆପଣଙ୍କର ଆଧାର ନମ୍ବର ଇନପୁଟ୍ କରନ୍ତୁ | ଏହି ପ୍ରକ୍ରିୟା ଆପଣଙ୍କ ସୁରକ୍ଷା ପାଇଁ ସଠିକ୍ କ୍ରସ୍-ଯାଞ୍ଚ ନିଶ୍ଚିତ କରେ | ଆପଣଙ୍କ ସହଯୋଗ ପାଇଁ ଧନ୍ୟବାଦ।',
    'Enter Your Aadhaar OTP Here': 'ଏଠାରେ ଆପଣଙ୍କର ଆଧାର OTP ପ୍ରବେଶ କରନ୍ତୁ |',
    'Error: Incorrect Aadhaar OTP': 'ତ୍ରୁଟି: ଭୁଲ ଆଧାର OTP |',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.': 'ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ଆଧାର ଯାଞ୍ଚ ପାଇଁ ପ୍ରବେଶ କରିଥିବା ଏକ-ସମୟ ପାସୱାର୍ଡ (OTP) ଭୁଲ ଅଟେ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ଆପଣଙ୍କର ପଞ୍ଜୀକୃତ ମୋବାଇଲ୍ ନମ୍ବରରେ ଗ୍ରହଣ କରାଯାଇଥିବା OTP କୁ ସଠିକ୍ ଭାବରେ ପ୍ରବେଶ କରିଛନ୍ତି |',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.': 'ଯଦି ସମସ୍ୟାଟି ଜାରି ରହେ, ଆପଣ ରିସେଣ୍ଡ ଲିଙ୍କରେ କ୍ଲିକ୍ କରି କିମ୍ବା + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରି ଏକ ନୂତନ OTP ଅନୁରୋଧ କରିପାରିବେ |',
    'Double-check the email and try entering the OTP again.': 'ଇମେଲକୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର OTP ପ୍ରବେଶ କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ |',
    'Error: Invalid Aadhaar Number': 'ତ୍ରୁଟି: ଅବ val ଧ ଆଧାର ସଂଖ୍ୟା |',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.': 'ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ | ପ୍ରବେଶ କରିଥିବା ଆଧାର ସଂଖ୍ୟା ଅବ alid ଧ ଅଟେ | ଦୟାକରି ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସଠିକ୍ 12 ଅଙ୍କ ବିଶିଷ୍ଟ ଆଧାର ନମ୍ବର ପ୍ରବେଶ କରିଛନ୍ତି |',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.': 'ଯଦି ଆପଣ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହୁଅନ୍ତି, ତେବେ ଆପଣଙ୍କର ଆଧାର କାର୍ଡ ଯାଞ୍ଚ କରନ୍ତୁ କିମ୍ବା ଅଧିକ ସହାୟତା ପାଇଁ + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ | ଆମେ ଆପଣଙ୍କର ସହଯୋଗକୁ ପ୍ରଶଂସା କରୁଛୁ |',
    'Language Selection:': 'ଭାଷା ଚୟନ:',
    'Empowering Effective Communication': 'ପ୍ରଭାବଶାଳୀ ଯୋଗାଯୋଗକୁ ସଶକ୍ତ କରିବା |',
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.": 'ଦିନଟି ଭଲରେ କଟୁ! ମୁଁ ମର୍ଟି, ଆଜିର ପ୍ରକ୍ରିୟା ପାଇଁ ତୁମର ଉତ୍ସର୍ଗୀକୃତ ସହାୟକ | ଆପଣ ଯେଉଁ ଭାଷା ସହିତ ଅଧିକ ଆରାମଦାୟକ, ତାହା ଚୟନ କରି ଆରମ୍ଭ କରିବା |',
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.": "ଆପଣଙ୍କର ପସନ୍ଦିତ ଭାଷା ପାଇଁ ଚୟନ କରିବା ସ୍ପଷ୍ଟ ଏବଂ ପ୍ରଭାବଶାଳୀ ଯୋଗାଯୋଗ ସୁନିଶ୍ଚିତ କରେ | ଏହା ତୁମର ମାତୃଭାଷା ହେଉ କିମ୍ବା ତୁମର ପସନ୍ଦର ଅନ୍ୟ ଭାଷା, ଏହାକୁ ଚୟନ କରିବା ଆମର ପାରସ୍ପରିକ କାର୍ଯ୍ୟକୁ ଶୃଙ୍ଖଳିତ କରିବ ଏବଂ ତୁମର ଅଭିଜ୍ଞତାକୁ ବ enhance ାଇବ |",
    "Select Your Preferred Language": "ଆପଣଙ୍କର ପସନ୍ଦିତ ଭାଷା ଚୟନ କରନ୍ତୁ |",
    "Welcome to PrivateCourt, where Helping you is our priority.": "ପ୍ରାଇଭେଟକୋର୍ଟକୁ ସ୍ାଗତ, ଯେଉଁଠାରେ ଆପଣଙ୍କୁ ସାହାଯ୍ୟ କରିବା ଆମର ପ୍ରାଥମିକତା |",
    "Now, let me introduce you to PrivateCourt - your reliable partner in resolving lending disputes effectively and efficiently.": 'ବର୍ତ୍ତମାନ, ମୁଁ ଆପଣଙ୍କୁ ପ୍ରାଇଭେଟକୋର୍ଟ୍ ସହିତ ପରିଚିତ କରାଇବି - effectively ଣ ବିବାଦକୁ ପ୍ରଭାବଶାଳୀ ଏବଂ ଦକ୍ଷତାର ସହିତ ସମାଧାନ କରିବାରେ ଆପଣଙ୍କର ନିର୍ଭରଯୋଗ୍ୟ ଅଂଶୀଦାର |',
    "PrivateCourt: Your trusted partner in resolving lending disputes with care and expertise.": "ପ୍ରାଇଭେଟକୋର୍ଟ: ଯତ୍ନ ଏବଂ ପାରଦର୍ଶୀତା ସହିତ ending ଣ ବିବାଦର ସମାଧାନ କରିବାରେ ଆପଣଙ୍କର ବିଶ୍ୱସ୍ତ ସାଥୀ |",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.": "ପ୍ରାଇଭେଟକୋର୍ଟକୁ ସ୍ାଗତ, ଯେଉଁଠାରେ ଆପଣଙ୍କର କେସ୍ ବିବରଣୀ ଭଲ ହାତରେ ଅଛି | Arbit ଣ ପ୍ରଦାନକାରୀ କମ୍ପାନୀ ଆର୍ବିଟ୍ରେସନ୍ କିମ୍ବା ଆପୋଷ ବୁ ation ାମଣା ଆରମ୍ଭ କରିବା ସହିତ, ତୁମର ପରିସ୍ଥିତିକୁ ସମ୍ପୂର୍ଣ୍ଣ ଭାବରେ ବୁ to ିବା ଆମ ପାଇଁ ଜରୁରୀ | ଦୟାକରି ଆପଣ କରିପାରିବେ ସମସ୍ତ ବିବରଣୀ ଅଂଶୀଦାର କରନ୍ତୁ |",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.": "ଆମ ପାଖରେ ଯେତେ ଅଧିକ ସୂଚନା ଅଛି, ସେତେ ଭଲ ଆମେ ସାହାଯ୍ୟ କରିପାରିବା | ଆପଣଙ୍କ ପାଇଁ ସଠିକ୍ ସମାଧାନ ଖୋଜିବାରେ ଆପଣଙ୍କର ଇନପୁଟ୍ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ |",
    "Trust us to carefully examine your case and work together to find the best way forward.": "ତୁମର ମାମଲାକୁ ଯତ୍ନର ସହ ପରୀକ୍ଷା କରିବାକୁ ଏବଂ ଆଗକୁ ସର୍ବୋତ୍ତମ ଉପାୟ ଖୋଜିବା ପାଇଁ ଏକତ୍ର କାର୍ଯ୍ୟ କରିବାକୁ ଆମକୁ ବିଶ୍ୱାସ କର |",
    "Reviewing Your Dispute Information": 'ତୁମର ବିବାଦ ସୂଚନା ସମୀକ୍ଷା',
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by LendingKart, regarding a personal loan.": "ଆସନ୍ତୁ ତୁମର ବିବାଦକୁ ନେଇ ସବିଶେଷ ତଥ୍ୟ ଉପରେ ଧ୍ୟାନ ଦେବା | ଆଜି ଆମେ ଆଲୋଚନା କରୁଥିବା ସୂଚନା ଏକ ବ୍ୟକ୍ତିଗତ loan ଣ ସମ୍ବନ୍ଧରେ LendingKart ଦ୍ୱାରା ଆମ ସହିତ ଅଂଶୀଦାର ହୋଇଛି |",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.": "ଏହି ନିର୍ଦ୍ଦିଷ୍ଟତାକୁ ବୁ By ିବା ଦ୍ୱାରା, ଆମେ ପରିସ୍ଥିତିର ଉତ୍ତମ ମୂଲ୍ୟାଙ୍କନ କରିପାରିବା ଏବଂ ଏକ ରିଜୋଲ୍ୟୁସନ୍ ଯୋଜନା ପ୍ରସ୍ତୁତ କରିପାରିବା ଯାହା ଆପଣଙ୍କ ପରିସ୍ଥିତି ଏବଂ ଲକ୍ଷ୍ୟ ସହିତ ସମାନ ଅଟେ |",
    "Dispute Amount Information": "ବିବାଦ ପରିମାଣ ସୂଚନା",
    "According to their records, you initially borrowed an amount of": "ସେମାନଙ୍କର ରେକର୍ଡ ଅନୁଯାୟୀ, ଆପଣ ପ୍ରାରମ୍ଭରେ ଏକ ପରିମାଣର ed ଣ ନେଇଥିଲେ |",
    "Presently, your outstanding due stands at Rs.": "ବର୍ତ୍ତମାନ, ଆପଣଙ୍କର ବକେୟା ଦେୟ Rs, ୦୦୦ ଟଙ୍କା ଅଟେ |",
    "These details provide us with valuable insight into the history and current status of your loan.": "",
    "Spotting Financial Issues: Understanding Unpaid Obligations": "ଆର୍ଥିକ ସମସ୍ୟାଗୁଡିକ ସ୍ପଟ କରିବା: ଅନାଦେୟ ବାଧ୍ୟତାମୂଳକ ବୁ standing ିବା |",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:": "ବର୍ତ୍ତମାନ, ଆସନ୍ତୁ ଆର୍ଥିକ ପ୍ରତିବନ୍ଧକ ହେତୁ ଆପଣ ସମ୍ମୁଖୀନ ହୋଇଥିବା ଚ୍ୟାଲେଞ୍ଜଗୁଡ଼ିକୁ ଧୀରେ ଧୀରେ ଅନୁସନ୍ଧାନ କରିବା | ଏହା ଉପରେ ଆଲୋଚନା କରିବା ସମ୍ବେଦନଶୀଳ ହୋଇପାରେ, କିନ୍ତୁ ତୁମର ଖୋଲାଖୋଲି ଆମକୁ ସର୍ବୋତ୍ତମ ମାର୍ଗ ଖୋଜିବାରେ ବହୁତ ସାହାଯ୍ୟ କରିବ | ନିମ୍ନଲିଖିତ ବିକଳ୍ପଗୁଡ଼ିକ ମଧ୍ୟରୁ କ select ଣସିଟି ବାଛିବା ପାଇଁ ଦୟାକରି କିଛି ସମୟ ନିଅ ଯାହାକି ତୁମର ଅଭିଜ୍ଞତା ସହିତ ପୁନ res ପ୍ରତିରୂପିତ ହୁଏ:",
    "Financial Loss": "ଆର୍ଥିକ କ୍ଷତି |",
    "Business Loss": "ବ୍ୟବସାୟ କ୍ଷତି |",
    "Partner Dispute": "ସହଭାଗୀ ବିବାଦ |",
    "Funds held with other vendors or clients": "",
    "Monetary loss through other means": "ଅନ୍ୟ ଉପାୟ ମାଧ୍ୟମରେ ମୁଦ୍ରା କ୍ଷତି |",
    "Job Loss": "ଚାକିରି ହରାଇବା |",
    "Less than 2 months": "2 ମାସରୁ କମ୍",
    "Less than 6 months": "6 ମାସରୁ କମ୍ |",
    "Less than 1 year": "1 ବର୍ଷରୁ କମ୍",
    "More than 1 year": "1 ବର୍ଷରୁ ଅଧିକ |",
    "Health Issue": "",
    "Death of the earning member": "ରୋଜଗାର କରୁଥିବା ସଦସ୍ୟଙ୍କ ମୃତ୍ୟୁ |",
    "Missing payment details": "ଦେୟ ବିବରଣୀ ହଜିଯାଇଛି |",
    "Dispute due to bad behaviour": "ଖରାପ ଆଚରଣ ଯୋଗୁଁ ବିବାଦ |",
    "No clear loan information": "କ ସଫା ଣସି ସ୍ପଷ୍ଟ loan ଣ ସୂଚନା ନାହିଁ |",
    "Death Case": "ମୃତ୍ୟୁ ମାମଲା",
    "We kindly request you to upload the death certificate at": "ଆମେ ଦୟାକରି ଆପଣଙ୍କୁ ମୃତ୍ୟୁ ପ୍ରମାଣପତ୍ର ଅପଲୋଡ୍ କରିବାକୁ ଅନୁରୋଧ କରୁଛୁ |",
    "Other reason/s": "ଅନ୍ୟାନ୍ୟ କାରଣ / ଗୁଡିକ |",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.": "ତୁମର ତୁରନ୍ତ ପ୍ରତିକ୍ରିୟା ପରିସ୍ଥିତିର ମୂଲ୍ୟାଙ୍କନ ଏବଂ ସବୁଠାରୁ ଉପଯୁକ୍ତ ରିଜୋଲ୍ୟୁସନ୍ ଖୋଜିବାରେ ଆମକୁ ବହୁତ ସାହାଯ୍ୟ କରିବ | ନିଶ୍ଚିତ ହୁଅନ୍ତୁ, କ outstanding ଣସି ଉଲ୍ଲେଖନୀୟ ସମସ୍ୟାର ସମାଧାନ ଏବଂ ଏକ ନ୍ୟାୟପୂର୍ଣ୍ଣ ଏବଂ ସନ୍ତୋଷଜନକ ଫଳାଫଳ ନିଶ୍ଚିତ କରିବାକୁ ଆମେ ଆପଣଙ୍କ ସହ କାର୍ଯ୍ୟ କରିବାକୁ ପ୍ରତିଶ୍ରୁତିବଦ୍ଧ |",
    "Exploring Settlement Possibilities": "ସମାଧାନ ସମ୍ଭାବନା ଅନୁସନ୍ଧାନ |",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.": "ଆମର ଆଲୋଚନା ଅଗ୍ରଗତି କଲାବେଳେ, ମୁଁ ଡିଫଲ୍ଟର କାରଣ ବ୍ୟାଖ୍ୟା କରିବାରେ ତୁମର ଖୋଲାଖୋଲି ପ୍ରଶଂସା କରେ | କ legal ଣସି ଆଇନଗତ ଜଟିଳତାକୁ ରୋକିବା ପାଇଁ ତୁରନ୍ତ ତୁମର ବକେୟା ଦେୟ ସମାଧାନ କରିବା ଅତ୍ୟନ୍ତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ | ବିଳମ୍ବ ନକରି ତୁମର ଦାୟିତ୍ fulfill ପୂରଣ କରିବା ପାଇଁ ଆମେ ବିଭିନ୍ନ ଉପାୟ ଅନୁସନ୍ଧାନ କରିବାକୁ ପରାମର୍ଶ ଦେଇଥାଉ, ଯେପରିକି ପରିବାରରୁ ସାହାଯ୍ୟ ମାଗିବା କିମ୍ବା ବିକଳ୍ପ ଆର୍ଥିକ ବିକଳ୍ପ ଅନୁସନ୍ଧାନ କରିବା |",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.": "ଠିକ୍ ସମୟରେ ସମାଧାନ କେବଳ ଆଇନଗତ ବିପଦକୁ ହ୍ରାସ କରେ ନାହିଁ ବରଂ ଆପଣଙ୍କର ଆର୍ଥିକ ପାରସ୍ପରିକ କାର୍ଯ୍ୟରେ ବିଶ୍ୱାସ ଏବଂ ବିଶ୍ୱସନୀୟତାକୁ ମଧ୍ୟ ଦୃ strengthen କରେ | ଏହି ପ୍ରସଙ୍ଗରେ ତୁମର ସହଯୋଗ ପ୍ରକୃତରେ ମୂଲ୍ୟବାନ ଅଟେ |",
    "Settlement Options": "ସମାଧାନ ବିକଳ୍ପଗୁଡ଼ିକ",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.": "ଏକ ଶୀଘ୍ର ସମାଧାନ ପାଇଁ ଆମର ପ୍ରୟାସରେ, ଆମେ ଆପଣଙ୍କର ସହଯୋଗ ଖୋଜୁ |",
    "Are you open to discussing the settlement of your outstanding dues?": "ତୁମର ବକେୟା ଦେୟ ସମାଧାନ ବିଷୟରେ ଆଲୋଚନା କରିବାକୁ ତୁମେ ଖୋଲା କି?",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?": "ତୁମର ଉଲ୍ଲେଖନୀୟ ଦେୟ ସମାଧାନ ପାଇଁ ତୁମର ଖୋଲାଖୋଲି ପ୍ରଶଂସା କରୁଛୁ | ତୁମର ଆର୍ଥିକ ସ୍ଥିତିକୁ ଭଲ ଭାବରେ ବୁ understand ିବା ଏବଂ ସେହି ଅନୁଯାୟୀ ଆମର ଆଭିମୁଖ୍ୟକୁ ସଜାଡ଼ିବା ପାଇଁ, ତୁମେ ଦୟାକରି ତୁମର ସାମ୍ପ୍ରତିକ ପାରିବାରିକ ଆୟ ବିଷୟରେ ବିବରଣୀ ପ୍ରଦାନ କରିପାରିବ କି?",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.": "ତୁମର ବକେୟା ଦେୟକୁ ସମାଧାନ କରିବାକୁ ଆମର ପ୍ରୟାସରେ, ଆମେ ଜାଣିବାକୁ ଚାହିଁବୁ କି ଏକ ଥର ଦେୟ ସମାଧାନ ଆପଣଙ୍କ ପାଇଁ ଏକ ବିକଳ୍ପ କି?",

    // newly added 

    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସଠିକ୍ GST ବିବରଣୀ ପ୍ରବେଶ କରିଛନ୍ତି ଏବଂ ସଠିକ୍ ଯାଞ୍ଚ ପାଇଁ ନିର୍ଦ୍ଦିଷ୍ଟ ଫର୍ମାଟ୍ ଅନୁସରଣ କରନ୍ତୁ |",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "ଯଦି ଆପଣ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହେବାକୁ ଲାଗନ୍ତି, ଦୟାକରି ଆପଣଙ୍କର ପାନ୍ କାର୍ଡକୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ବିବରଣୀଗୁଡିକ ପୁନ - ପ୍ରବେଶ କରନ୍ତୁ |",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "ପ୍ରଦାନ କରାଯାଇଥିବା ଜିଏସ୍ଟି ଆମର ରେକର୍ଡରେ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି | ଯାଞ୍ଚ ପାଇଁ ଦୟାକରି ଏକ ଭିନ୍ନ GST ବ୍ୟବହାର କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ |",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "ଦେଖାଯାଉଛି ଯେ ପ୍ରଦାନ କରାଯାଇଥିବା PAN ବିବରଣୀଗୁଡିକ ଏକ କମ୍ପାନୀର ଅଟେ | ଯାଞ୍ଚ ଉଦ୍ଦେଶ୍ୟ ପାଇଁ ଆମେ ଆପଣଙ୍କର ବ୍ୟକ୍ତିଗତ PAN ବିବରଣୀ ଆବଶ୍ୟକ କରୁ |",
    "If you continue to face issues, kindly double-check your PAN card and re- enter the details.For further queries or assistance, feel free to call us at +91-9699900111.": "ଯଦି ଆପଣ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହେବାକୁ ଲାଗନ୍ତି, ଦୟାକରି ଆପଣଙ୍କର ପାନ୍ କାର୍ଡକୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ବିବରଣୀଗୁଡିକ ପୁନ - ପ୍ରବେଶ କରନ୍ତୁ | ଅଧିକ ପ୍ରଶ୍ନ କିମ୍ବା ସହାୟତା ପାଇଁ, ଆମକୁ + 91-9699900111 ରେ କଲ କରିବାକୁ ମୁକ୍ତ ମନ ଦିଅନ୍ତୁ |",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "ପ୍ରଦାନ କରାଯାଇଥିବା ପାନ୍ ଆମର ରେକର୍ଡଗୁଡିକରେ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି | ଯାଞ୍ଚ ପାଇଁ ଦୟାକରି ଏକ ଭିନ୍ନ PAN ବ୍ୟବହାର କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ |",
    "New User Registration": "ନୂତନ ଉପଭୋକ୍ତା ପଞ୍ଜିକରଣ |",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "ଆମ ସହିତ ଆପଣଙ୍କର ଯାତ୍ରା ଆରମ୍ଭ କରିବାକୁ, ଆମେ ଦୟାକରି ସମସ୍ତ ନୂତନ ଉପଭୋକ୍ତାମାନଙ୍କୁ ନିମ୍ନରେ ବର୍ଣ୍ଣିତ ସରଳ ପଦକ୍ଷେପଗୁଡିକ ଅନୁସରଣ କରି ପଞ୍ଜିକରଣ କରିବାକୁ ଅନୁରୋଧ କରୁଛୁ:",
    "Registration": "ପଞ୍ଜୀକରଣ",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "ପଞ୍ଜିକରଣ ଏବଂ ଆମର ବିସ୍ତୃତ ଯାଞ୍ଚ ସେବାଗୁଡିକରେ ପ୍ରବେଶ କରିବାକୁ, ଦୟାକରି ଏକ ଥର ଦେୟ ଦେଇ ଅଗ୍ରଗତି କରନ୍ତୁ |",
    "Rs. 500": "ଟଙ୍କା 500",
    "GST": "GST",
    "done": "ସମାପ୍ତ",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "ଦୟାକରି ଧ୍ୟାନ ଦିଅନ୍ତୁ ଯେ ସରକାରୀ ନିୟମ ଅନୁଯାୟୀ ପଞ୍ଜୀକରଣ ଶୁଳ୍କ 18% ଜିଏସ୍ଟି (ଦ୍ରବ୍ୟ ଏବଂ ସେବା କର) ଅଧୀନରେ ଅଛି |",
    "Total Amount Due:": "ସମୁଦାୟ ରାଶି:",
    "Rs. 590": "ଟଙ୍କା 590",
    "Total Registration Fee + 18% GST": "ମୋଟ ପଞ୍ଜୀକରଣ ଦେୟ + 18% ଜିଏସ୍ଟି |",
    "Secure Payment Gateway": "ସୁରକ୍ଷିତ ଦେୟ ଗେଟୱେ |",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "ନିଶ୍ଚିତ ହୁଅ, ତୁମର ଦେୟ ଏବଂ ବ୍ୟକ୍ତିଗତ ସୂଚନା ଆମର ଏନକ୍ରିପ୍ଟ ହୋଇଥିବା ପେମେଣ୍ଟ ଗେଟୱେ ମାଧ୍ୟମରେ ସର୍ବାଧିକ ସୁରକ୍ଷା ସହିତ ପରିଚାଳିତ ହୁଏ |",
    "Registration Complete - Thank You!": "ପଞ୍ଜୀକରଣ ସମ୍ପୂର୍ଣ୍ଣ - ଧନ୍ୟବାଦ!",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "ଅଭିନନ୍ଦନ! ଆପଣ ପଞ୍ଜୀକରଣ ପ୍ରକ୍ରିୟା ସମାପ୍ତ କରିଛନ୍ତି | ଆବଶ୍ୟକ ସୂଚନା ପ୍ରଦାନ ପାଇଁ ତୁମର ଉତ୍ସର୍ଗୀକୃତତା ତୁମକୁ ପ୍ରଭାବଶାଳୀ ଭାବରେ ସେବା କରିବା ପାଇଁ ଆମକୁ ଗୋଟିଏ ପାଦ ନିକଟତର କରିଛି |",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "ତୁମର ପଞ୍ଜୀକରଣ ଚୂଡାନ୍ତ ହେବା ସହିତ, ଆମର ଦଳ ତୁମର ଆବଶ୍ୟକତାକୁ ଦକ୍ଷତାର ସହିତ ପରିଚାଳନା କରିବା ପାଇଁ ସମ୍ପୂର୍ଣ୍ଣ ସଜ୍ଜିତ | ଆମେ ଆପଣଙ୍କୁ ନିଶ୍ଚିତ କରୁଛୁ ଯେ ଆପଣଙ୍କ ମାମଲା ଅତି ଯତ୍ନ ଏବଂ ସବିଶେଷ ଧ୍ୟାନ ସହିତ ପରିଚାଳିତ ହେବ |",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "ଯଦି ଆପଣ କେବେ ସାହାଯ୍ୟ ଆବଶ୍ୟକ କରନ୍ତି କିମ୍ବା କ qu ଣସି ପ୍ରଶ୍ନ ଅଛି, ଦୟାକରି ଯେକ time ଣସି ସମୟରେ ଆମ ସହିତ ଯୋଗାଯୋଗ କରିବାକୁ ଦୟାକରି ମୁକ୍ତ ହୁଅନ୍ତୁ | ଆମେ ତୁମର ଚିନ୍ତାଧାରାକୁ ସମାଧାନ କରିବା ପାଇଁ ଏବଂ ସମଗ୍ର ପ୍ରକ୍ରିୟାରେ ଏକ ସୁଗମ ଯାତ୍ରା ନିଶ୍ଚିତ କରିବାକୁ ଆମେ ଏଠାରେ ଅଛୁ |",
    "We eagerly anticipate the opportunity to assist you further.": "ଆପଣଙ୍କୁ ଆଗକୁ ସାହାଯ୍ୟ କରିବାର ସୁଯୋଗକୁ ଆମେ ଉତ୍ସାହର ସହିତ ଆଶା କରୁ |",
    "Warm regards,": "ହାର୍ଦ୍ଦିକ ଶୁଭେଚ୍ଛା,",
    "PrivateCourt Team": "ପ୍ରାଇଭେଟ କୋର୍ଟ୍ ଟିମ୍",
    "Secure UDYAM Number Validation": "ସୁରକ୍ଷିତ UDYAM ସଂଖ୍ୟା ବ id ଧତା |",
    "Enter UDYAM for": "ପାଇଁ UDYAM ପ୍ରବେଶ କରନ୍ତୁ |",
    "having GST number": "ଜିଏସ୍ଟି ନମ୍ବର ଅଛି |",
    "Enter Your Udyam Number": "ତୁମର ଉଦୟମ୍ ନମ୍ବର ପ୍ରବେଶ କର",
    "Error: Invalid UAM Number": "ତ୍ରୁଟି: ଅବ U ଧ UAM ନମ୍ବର",
    "We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos.": "ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ପ୍ରବେଶ କରିଥିବା UAM ନମ୍ବର ଅବ alid ଧ କିମ୍ବା ଭୁଲ ଅଟେ। ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସଠିକ୍ ଭାବରେ ସବିଶେଷ ବିବରଣୀ ପ୍ରବେଶ କରିଛନ୍ତି ଏବଂ ଯେକ any ଣସି ଟାଇପ୍ ପାଇଁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ।",
    "Server Overload: Please Retry Later": "ସର୍ଭର ଓଭରଲୋଡ୍: ଦୟାକରି ପରେ ପୁନ ry ଚେଷ୍ଟା କରନ୍ତୁ",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ମାଗୁଛୁ ଏବଂ ଆପଣଙ୍କ ଧ patience ର୍ଯ୍ୟକୁ ପ୍ରଶଂସା କରୁଛୁ। ସମ୍ପ୍ରତି ଆମର ସର୍ଭର ଅନୁରୋଧରେ ବୃଦ୍ଧି ଘଟୁଛି, ଏକ ଅସ୍ଥାୟୀ ବିଳମ୍ବ ସୃଷ୍ଟି କରୁଛି। ନିଶ୍ଚିତ ହୁଅନ୍ତୁ, ସଠିକତା ଏବଂ ନିରାପତ୍ତା ନିଶ୍ଚିତ କରିବାକୁ ଆମେ ଆପଣଙ୍କ ତଥ୍ୟ ସଂଗ୍ରହ କରୁଛୁ।",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "ଦୟାକରି ଏହି ବ୍ୟସ୍ତବହୁଳ ଅବସ୍ଥାରେ ଆମ ସହ ସହ୍ୟ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରିବା ପୂର୍ବରୁ ଆପଣଙ୍କୁ କିଛି ସମୟ ଅପେକ୍ଷା କରିବାକୁ ଦୟାକରି ଅନୁରୋଧ କରୁଛୁ। ସେବା ପ୍ରଦାନର ଗୁଣବତ୍ତା ବଜାୟ ରଖିବାରେ ଆପଣଙ୍କର ବୁ ଠିଆ ହେବା  ାମଣା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ଅଟେ।",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "ଜରୁରୀ ବିଷୟ କିମ୍ବା ସହାୟତା ପାଇଁ, ଆମକୁ + 91-9699900111 ରେ ଫୋନ୍ କରନ୍ତୁ |",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "ତୁମର ଧ ଶାନ୍ତି ର୍ଯ୍ୟ ଏବଂ ପ୍ରାଇଭେଟକୋର୍ଟ୍ ଦାୟିତ୍ୱ ପାଇଁ ଧନ୍ୟବାଦ | ତୁମର ସୁରକ୍ଷା ଆମର ପ୍ରାଥମିକତା |",
    "If you continue to face issues, verify the": "ଯଦି ଆପଣ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହୁଅନ୍ତି, ଯାଞ୍ଚ କରନ୍ତୁ",
    "Applicant's": "ଆବେଦନକାରୀଙ୍କ",
    "Error: Invalid OTP": "ତ୍ରୁଟି: ଅବ val ଧ OTP |",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ | ପ୍ରବେଶ କରିଥିବା OTP ଭୁଲ ଅଟେ | ଦୟାକରି OTP କୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "ଆଧାର କାର୍ଡ କିମ୍ବା ଅଧିକ ସହାୟତା ପାଇଁ + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ | ଆମେ ଆପଣଙ୍କର ସହଯୋଗକୁ ପ୍ରଶଂସା କରୁଛୁ |",
    "Error: User already exists": "ତ୍ରୁଟି: ଉପଯୋଗକର୍ତ୍ତା ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛନ୍ତି |",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ | ପ୍ରଦାନ କରାଯାଇଥିବା ଆଧାର ନମ୍ବର ଆମ ରେକର୍ଡରେ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି | ଯାଞ୍ଚ ପାଇଁ ଦୟାକରି ଏକ ଭିନ୍ନ ଆଧାର ନମ୍ବର ପ୍ରଦାନ କରନ୍ତୁ |",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ | ପ୍ରଦାନ କରାଯାଇଥିବା ନାମ PAN ରେକର୍ଡ ସହିତ ମେଳ ଖାଉ ନାହିଁ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ପ୍ରବେଶ କରିଥିବା ନାମ ଆପଣଙ୍କ PAN କାର୍ଡରେ ଥିବା ତଥ୍ୟ ସହିତ ମେଳ ଖାଉଛି ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
    "Enter Bank Details for": "ପାଇଁ ବ୍ୟାଙ୍କ ବିବରଣୀ ପ୍ରବେଶ କରନ୍ତୁ |",
    "Error: Already Exists": "ତ୍ରୁଟି: ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି |",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ଆପଣ ପ୍ରଦାନ କରିଥିବା ID ଆମ ସିଷ୍ଟମରେ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି | ଦୟାକରି ଅନ୍ୟ ଏକ ID ଚେଷ୍ଟା କରନ୍ତୁ |",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "ଯଦି ଆପଣ ସମସ୍ୟାର ସମ୍ମୁଖୀନ ହୁଅନ୍ତି, ଦୟାକରି ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଷ୍ଟେଟମେଣ୍ଟ ଯାଞ୍ଚ କରନ୍ତୁ କିମ୍ବା ପ୍ରଦାନ କରାଯାଇଥିବା ସୂଚନାର ସଠିକତା ନିଶ୍ଚିତ କରିବାକୁ ଆପଣଙ୍କ ବ୍ୟାଙ୍କ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ |",
    "Error: Name Not Matched": "ତ୍ରୁଟି: ନାମ ମେଳ ଖାଉ ନାହିଁ |",
    "Good Afternoon": "ଶୁଭ ଅପରାହ୍ନ |",
    "Account security starts with verification": "ଖାତା ସୁରକ୍ଷା ଯାଞ୍ଚରୁ ଆରମ୍ଭ ହୁଏ |",
    "OTP Validation for Account Access": "ଖାତା ପ୍ରବେଶ ପାଇଁ OTP ନିଶ୍ଚିତ କରନ୍ତୁ |",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "ଦୟାକରି ଆପଣଙ୍କର ଇନବକ୍ସ ଯାଞ୍ଚ କରନ୍ତୁ, ଏବଂ ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ସମାପ୍ତ କରିବାକୁ ନିର୍ଦ୍ଦିଷ୍ଟ କ୍ଷେତ୍ରରେ OTP ପ୍ରବେଶ କରନ୍ତୁ |",
    "Request a Resend": "ଏକ ଫେରସ୍ତ ପାଇଁ ଅନୁରୋଧ |",
    "Error: Incorrect OTP": "ତ୍ରୁଟି: ଭୁଲ OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ। ଏକ ଥର ପ୍ରବେଶ କରିଥିବା ପାସୱାର୍ଡ (OTP) ଭୁଲ ଅଟେ। ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ଆପଣଙ୍କର ଇମେଲରେ ଗ୍ରହଣ କରାଯାଇଥିବା OTP କୁ ସଠିକ୍ ଭାବରେ ପ୍ରବେଶ କରିଛନ୍ତି।",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.": "ଯଦି ଏହି ସମସ୍ୟା ଜାରି ରହେ, ଆପଣ ପୁନ end ପଠାଇବା ଲିଙ୍କରେ କ୍ଲିକ୍ କରି କିମ୍ବା + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରି ଏକ ନୂତନ OTP ଅନୁରୋଧ କରିପାରିବେ |",
    "Double-check the email and try entering the OTP again.": "ଇମେଲକୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର OTP ପ୍ରବେଶ କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ |",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "ପ୍ରାଇଭେଟ କୋର୍ଟ୍ | ରେ, ଆମେ ଆମର ସେବାଗୁଡିକର ସୁରକ୍ଷା ଏବଂ ଅଖଣ୍ଡତାକୁ ପ୍ରାଥମିକତା ଦେଇଥାଉ | ଆମର ସମସ୍ତ ଉପଭୋକ୍ତାମାନଙ୍କ ପାଇଁ ଏକ ନିର୍ଭରଯୋଗ୍ୟ ଏବଂ ସୁରକ୍ଷିତ ଅଭିଜ୍ଞତା ନିଶ୍ଚିତ କରିବାକୁ ଆମର ଜିଏସ୍ଟି ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ଯତ୍ନର ସହିତ ଡିଜାଇନ୍ ହୋଇଛି |",
    "Enter Your GSTIN": "ଆପଣଙ୍କର GSTIN ପ୍ରବେଶ କରନ୍ତୁ |",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "ଦୟାକରି 00-XXXXX-0000-X-0-X-0 ଫର୍ମାଟରେ ଆପଣଙ୍କର ସଠିକ୍ GST ପରିଚୟ ସଂଖ୍ୟା (GSTIN) ପ୍ରବେଶ କରନ୍ତୁ |",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such a specifics and records of GST return filings.": "ତୁମର GSTIN ପ୍ରବେଶ କରିବା ପରେ, ପ୍ରାଇଭେଟକୋର୍ଟ୍ ଏହା ସହିତ ଜଡିତ ଜରୁରୀ ସୂଚନା ସଂଗ୍ରହ କରିବ | ଏଥିରେ ଆପଣଙ୍କର ସମ୍ପୂର୍ଣ୍ଣ ନାମ, ଜନ୍ମ ତାରିଖ, ଆବାସିକ ଠିକଣା, ଏବଂ ଯୋଗାଯୋଗ ବିବରଣୀ ଅନ୍ତର୍ଭୁକ୍ତ | ବ୍ୟବସାୟ ମାଲିକମାନଙ୍କ ପାଇଁ, ଆମେ ଆପଣଙ୍କର ତଥ୍ୟ ସଂଗ୍ରହକୁ ଆପଣଙ୍କର GSTIN ସହିତ ବନ୍ଧା ହୋଇଥିବା ସମସ୍ତ ପ୍ରଯୁଜ୍ୟ ବ୍ୟବସାୟ ବିବରଣୀକୁ ଅନ୍ତର୍ଭୁକ୍ତ କରିବା ପାଇଁ ବିସ୍ତାର କରୁ, ଏହିପରି ଏକ ନିର୍ଦ୍ଦିଷ୍ଟତା ଏବଂ ଜିଏସ୍ଟି ରିଟର୍ନ ଫାଇଲ୍ ରେକର୍ଡଗୁଡିକ |",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "ଆମର ସିଷ୍ଟମ ଆର୍ଥିକ ଜ୍ଞାନ ପାଇଁ ଆପଣଙ୍କର CIBIL ସ୍କୋରକୁ ମୂଲ୍ୟାଙ୍କନ କରେ ଏବଂ ଆପଣଙ୍କର GSTIN ସହିତ ଜଡିତ ଯେକ ଜୀବନ୍ତ ଣସି ସାମ୍ପ୍ରତିକ କୋର୍ଟ ମାମଲା ପାଇଁ କ୍ରସ୍-ଯାଞ୍ଚ ହୋଇଛି |",
    "Error: Invalid GST Format": "ତ୍ରୁଟି: ଭୁଲ ଜିଏସ୍ଟି ଫର୍ମାଟ୍ |",
    "Please enter your personal PAN": "ଦୟାକରି ଆପଣଙ୍କର ବ୍ୟକ୍ତିଗତ PAN ପ୍ରବେଶ କରନ୍ତୁ |",
    "Already Exists": "ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି |",
    "Invalid": "ଭୁଲ",
    "Secure PAN Validation Process": "ସୁରକ୍ଷିତ ପାନ୍ ପ୍ରକ୍ରିୟା ନିଶ୍ଚିତ କରନ୍ତୁ |",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "ଏହି ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ଆମର ସେବାଗୁଡିକର ଅଖଣ୍ଡତାକୁ ବଜାୟ ରଖିବା ପାଇଁ ଡିଜାଇନ୍ କରାଯାଇଛି, ଏକ ନିର୍ଭରଯୋଗ୍ୟ ଏବଂ ସୁରକ୍ଷିତ ଅଭିଜ୍ଞତା ନିଶ୍ଚିତ କରେ |",
    "Enter Your PAN Number": "ଆପଣଙ୍କର ପାନ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "ଦୟାକରି XXXXX-0000-X ଫର୍ମାଟରେ ଆପଣଙ୍କର ସଠିକ୍ PAN ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details specifics, and records of GST return filings.": "ପ୍ରାଇଭେଟକୋର୍ଟ୍ ଆପଣଙ୍କ ପାନ୍ ସହିତ ଜଡିତ ଅତ୍ୟାବଶ୍ୟକ ସୂଚନା ଯଥା ତୁମର ସମ୍ପୂର୍ଣ୍ଣ ନାମ, ଜନ୍ମ ତାରିଖ, ଆବାସିକ ଠିକଣା, ଏବଂ ଯୋଗାଯୋଗ ବିବରଣୀ ସଂଗ୍ରହ କରିବ | ବ୍ୟବସାୟ ମାଲିକମାନଙ୍କ ପାଇଁ, ଆମେ ଆପଣଙ୍କର ତଥ୍ୟ ସଂଗ୍ରହକୁ ଆପଣଙ୍କର ପ୍ୟାନ୍ ସହିତ ଜଡିତ ସମସ୍ତ ପ୍ରଯୁଜ୍ୟ ବ୍ୟବସାୟ ବିବରଣୀ ଯେପରିକି ଜିଏସ୍ଟି ବିବରଣୀ ନିର୍ଦ୍ଦିଷ୍ଟତା ଏବଂ ଜିଏସ୍ଟି ରିଟର୍ନ ଫାଇଲଗୁଡିକର ରେକର୍ଡକୁ ଅନ୍ତର୍ଭୁକ୍ତ କରିବା ପାଇଁ ବିସ୍ତାର କରୁ |",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "ଆର୍ଥିକ ଅନ୍ତର୍ନିହିତତା ପାଇଁ ଆମର ସିଷ୍ଟମ୍ ଆପଣଙ୍କର CIBIL ସ୍କୋରକୁ ଆକଳନ କରେ ଏବଂ ଆପଣଙ୍କର ପାନ୍ ସହିତ ଜଡିତ କ ସାମ୍ପ୍ରତିକ ଣସି ଚାଲୁଥିବା କୋର୍ଟ ମାମଲା ପାଇଁ କ୍ରସ୍-ଯାଞ୍ଚ କରେ |",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "ଦେଖାଯାଉଛି ଯେ ପ୍ରଦାନ କରାଯାଇଥିବା PAN ବିବରଣୀଗୁଡିକ ଏକ କମ୍ପାନୀର ଅଟେ | ଯାଞ୍ଚ ଉଦ୍ଦେଶ୍ୟ ପାଇଁ ଆମେ ଆପଣଙ୍କର ବ୍ୟକ୍ତିଗତ PAN ବିବରଣୀ ଆବଶ୍ୟକ କରୁ |",

    "Data Compilation in Progress: Please Allow Processing Time": "ପ୍ରଗତିରେ ତଥ୍ୟ ସଂକଳନ: ଦୟାକରି ପ୍ରକ୍ରିୟାକରଣ ସମୟକୁ ଅନୁମତି ଦିଅନ୍ତୁ |",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "ଆପଣଙ୍କର ସୂଚନା ପ୍ରଦାନ କରିଥିବାରୁ ଧନ୍ୟବାଦ। ଆମର ବିସ୍ତୃତ ପ୍ରୋଫାଇଲ୍ ପ୍ରତିଷ୍ଠା କରିବା ପାଇଁ ପାନ୍, ଆଧାର, ଜିଏସ୍ଟି, ଇମେଲ୍, ଏବଂ ଅନ୍ୟାନ୍ୟ ବିବରଣୀ ଅନ୍ତର୍ଭୂକ୍ତ କରି ଆପଣ ପ୍ରବେଶ କରିଥିବା ତଥ୍ୟକୁ ବିଶ୍ଳେଷଣ ଏବଂ ସଂକଳନ ପ୍ରକ୍ରିୟାରେ ଅଛନ୍ତି।",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "ଏହି ଅପରେସନ୍ କିଛି ମୁହୂର୍ତ୍ତ ନେଇପାରେ | ଏହି ସମୟ ମଧ୍ୟରେ, ଆପଣଙ୍କର ସ୍କ୍ରିନ୍ ସର୍ବନିମ୍ନ କାର୍ଯ୍ୟକଳାପ ଦେଖାଇପାରେ | ଦୟାକରି ନିଶ୍ଚିତ ହୁଅନ୍ତୁ ଯେ ଏହା ଆମର ତଥ୍ୟ ସଂକଳନ ପ୍ରକ୍ରିୟାର ଏକ ନିତିଦିନିଆ ଅଂଶ |",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "ସଠିକତା ଏବଂ ନିରାପତ୍ତା ବଜାୟ ରଖିବା ପାଇଁ ଆପଣଙ୍କ ପ୍ରୋଫାଇଲ୍ ସତର୍କତାର ସହ ପ୍ରସ୍ତୁତ କରାଯାଉଛି। ଆମେ ଆପଣଙ୍କ ଅନୁଭୂତିକୁ ବଢାଇବା ପାଇଁ କାର୍ଯ୍ୟ କରୁଥିବା ବେଳେ ଆମେ ଆପଣଙ୍କ ଶାନ୍ତତା ଏବଂ ବୁଝାମଣାକୁ ଆନ୍ତରିକତାର ସହ ପ୍ରଶଂସା କରୁଛୁ |",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "ଜରୁରୀ ବିଷୟ କିମ୍ବା ସହାୟତା ପାଇଁ, ଆମକୁ + 91-9699900111 ରେ କଲ କରିବାକୁ ମୁକ୍ତ ମନେକର |",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "ତୁମର ଧ patience ର୍ଯ୍ୟ ଏବଂ ପ୍ରାଇଭେଟକୋର୍ଟ୍ ଦାୟିତ୍ୱ ପାଇଁ ଧନ୍ୟବାଦ | ତୁମର ସୁରକ୍ଷା ଆମର ପ୍ରାଥମିକତା |",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "ଦୟାକରି ଆପଣଙ୍କର ଆଧାର ନମ୍ବର ଇନପୁଟ୍ କରନ୍ତୁ | ଏହି ପ୍ରକ୍ରିୟା ଆପଣଙ୍କ ସୁରକ୍ଷା ପାଇଁ ସଠିକ୍ କ୍ରସ୍-ଯାଞ୍ଚ ନିଶ୍ଚିତ କରେ | ଆପଣଙ୍କ ସହଯୋଗ ପାଇଁ ଧନ୍ୟବାଦ।",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ । ପ୍ରବେଶ କରିଥିବା ଆଧାର ନମ୍ବର ଅବୈଧ ଅଟେ। ଦୟାକରି ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ପ୍ରବେଶ କରିଛନ୍ତି",
    "Applicant’s correct 12-digit Aadhaar number.": "ଆବେଦନକାରୀଙ୍କ ସଠିକ୍ 12 ଅଙ୍କ ବିଶିଷ୍ଟ ଆଧାର ସଂଖ୍ୟା |",
    "Error: Invalid OTP": "ତ୍ରୁଟି: ଅବୈଧ ଓଟିପି",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ | ପ୍ରବେଶ କରିଥିବା OTP ଭୁଲ ଅଟେ | ଦୟାକରି OTP କୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
    "Welcome": "ସ୍ୱାଗତମ୍‌",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "ଏକ ନିରବଚ୍ଛିନ୍ନ ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ପାଇଁ ଆପଣଙ୍କ ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ସୂଚନାର ସଠିକତା ସୁନିଶ୍ଚିତ କରିବା ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ | ଦୟାକରି ଆପଣଙ୍କ ବୈଧ ଆକାଉଣ୍ଟ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ, ଏବଂ ଆପଣଙ୍କ ଆକାଉଣ୍ଟ ସହ ଜଡିତ ସଠିକ୍ IFSC କୋଡ୍ ଇନପୁଟ୍ କରନ୍ତୁ।",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "ଏହି ଯାଞ୍ଚ ପଦକ୍ଷେପ ଆମର ସେବାଗୁଡିକର ନିରାପତ୍ତା ବଢ଼ାଇବା ଏବଂ ଆପଣଙ୍କୁ ଏକ ବିଶ୍ୱସନୀୟ ଅଭିଜ୍ଞତା ପ୍ରଦାନ କରିବାକୁ ଡିଜାଇନ୍ କରାଯାଇଛି।",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ । ପ୍ରଦାନ କରାଯାଇଥିବା ନାମ ଜିଏସଟି ରେକର୍ଡ ସହ ମେଳ ଖାଉନାହିଁ। ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ପ୍ରବେଶ କରିଥିବା ନାମ ଆପଣଙ୍କ ଜିଏସ୍ ଟି କାର୍ଡରେ ଥିବା ସୂଚନା ସହ ମେଳ ଖାଉଛି ଏବଂ ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ।",
    "Business Verification Process": "ବ୍ୟବସାୟ ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "ସୁରକ୍ଷିତ ବ୍ୟବସାୟ ବୈଧତା ସୁନିଶ୍ଚିତ କରିବା- ଏହି ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ଆମସେବାର ଅଖଣ୍ଡତାକୁ ବଜାୟ ରଖିବା, ଏକ ବିଶ୍ୱସନୀୟ ଏବଂ ସୁରକ୍ଷିତ ଅନୁଭୂତି ପ୍ରଦାନ କରିବା ପାଇଁ ସତର୍କତାର ସହ ଡିଜାଇନ୍ କରାଯାଇଛି।",
    "Please provide details for each of your businesses for accurate validation.": "ସଠିକ ବୈଧତା ପାଇଁ ଦୟାକରି ଆପଣଙ୍କର ପ୍ରତ୍ୟେକ ବ୍ୟବସାୟ ପାଇଁ ବିବରଣୀ ପ୍ରଦାନ କରନ୍ତୁ।",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "ପ୍ରାଇଭେଟ କୋର୍ଟ ଆପଣଙ୍କ ପାନ୍ ସହ ଜଡ଼ିତ ଜରୁରୀ ସୂଚନା ଯେପରିକି ଆପଣଙ୍କ ସମ୍ପୂର୍ଣ୍ଣ ନାମ, ଜନ୍ମ ତାରିଖ, ଆବାସିକ ଠିକଣା ଏବଂ ଯୋଗାଯୋଗ ବିବରଣୀ ସଂଗ୍ରହ କରିବ। ବ୍ୟବସାୟ ମାଲିକମାନଙ୍କ ପାଇଁ, ଆମେ ଆମର ଡାଟା ସଂଗ୍ରହକୁ ଆପଣଙ୍କ ପାନ୍ ସହିତ ଜଡିତ ସମସ୍ତ ପ୍ରାସଙ୍ଗିକ ବ୍ୟବସାୟିକ ବିବରଣୀ ଯେପରିକି ଜିଏସଟି ବିବରଣୀ, ଏମସିଏ ନିର୍ଦ୍ଦିଷ୍ଟତା ଏବଂ ଜିଏସଟି ରିଟର୍ଣ୍ଣ ଫାଇଲିଂର ରେକର୍ଡକୁ ଅନ୍ତର୍ଭୁକ୍ତ କରିବାକୁ ବିସ୍ତାର କରୁଛୁ।",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "ଆମର ସିଷ୍ଟମ ଆର୍ଥିକ ବୁଝାମଣା ପାଇଁ ଆପଣଙ୍କ ସିବିଆଇଲ୍ ସ୍କୋରର ମୂଲ୍ୟାଙ୍କନ କରେ ଏବଂ ଆପଣଙ୍କ ପାନ୍ ସହିତ ଜଡିତ କୌଣସି ଚାଲୁଥିବା ଅଦାଲତ ମାମଲା ପାଇଁ କ୍ରସ୍-ଯାଞ୍ଚ କରେ।",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "ଆପଣଙ୍କ ଖାତାର ସୁରକ୍ଷା ନିଶ୍ଚିତ କରିବାକୁ, ଦୟାକରି ନିର୍ଦ୍ଦିଷ୍ଟ ଫର୍ମାଟରେ ଆପଣଙ୍କର ବ valid ଧ ଇମେଲ ID- username@domain.com ପ୍ରବେଶ କରନ୍ତୁ | ଯଦି ଆପଣଙ୍କର ଇମେଲ୍ ଠିକଣା ଆମ ସିଷ୍ଟମରେ ନାହିଁ,",
    "account will be created for you.": "ଖାତା ଆପଣଙ୍କ ପାଇଁ ସୃଷ୍ଟି ହେବ |",
    "GST Verification Process": "ଜିଏସ୍ଟି ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା |",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ପ୍ରବିଷ୍ଟ ହୋଇଥିବା GST ନମ୍ବର ଆବଶ୍ୟକ ଫର୍ମାଟ୍ (00-XXXXX-0000-X-0-X-0) ସହିତ ମେଳ ଖାଉ ନାହିଁ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସଠିକ୍ GST ବିବରଣୀ ପ୍ରବେଶ କରିଛନ୍ତି ଏବଂ ସଠିକ୍ ଯାଞ୍ଚ ପାଇଁ ନିର୍ଦ୍ଦିଷ୍ଟ ଫର୍ମାଟ୍ ଅନୁସରଣ କରନ୍ତୁ |",
    "Verify GST": "GST ଯାଞ୍ଚ କରନ୍ତୁ |",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "ଅଭିନନ୍ଦନ! ଆପଣ ପଞ୍ଜୀକରଣ ପ୍ରକ୍ରିୟା ସମାପ୍ତ କରିଛନ୍ତି | ଆବଶ୍ୟକ ସୂଚନା ପ୍ରଦାନ କରିବାରେ ତୁମର ଉତ୍ସର୍ଗୀକୃତତା ତୁମକୁ ପ୍ରଭାବଶାଳୀ ଭାବରେ ସେବା କରିବା ପାଇଁ ଆମକୁ ଗୋଟିଏ ପାଦ ନିକଟତର କରିଛି |",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ | ପ୍ରଦାନ କରାଯାଇଥିବା ନାମ ଜିଏସ୍ଟି ରେକର୍ଡ ସହିତ ମେଳ ଖାଉ ନାହିଁ | ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ପ୍ରବେଶ କରାଯାଇଥିବା ନାମ ଆପଣଙ୍କ GST କାର୍ଡରେ ଥିବା ତଥ୍ୟ ସହିତ ମେଳ ଖାଉଛି ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
    "Skip": "ଛାଡିଦିଅ |",
    "Server Overload: Please Retry Later": "ସର୍ଭର ଓଭରଲୋଡ୍: ଦୟାକରି ପରେ କରନ୍ତୁ |",
    "Back To Home": "ଘରକୁ ଫେରନ୍ତୁ |",




    "Verify Aadhaar": "ଆଧାର ଯାଞ୍ଚ କରନ୍ତୁ",
    "having PAN number": "ପାନ୍ ନମ୍ବର ଅଛି",
    "Verify Bank": "ବ୍ୟାଙ୍କ ଯାଞ୍ଚ କରନ୍ତୁ",
    "Select Businesses": "ବ୍ୟବସାୟ ଚୟନ କରନ୍ତୁ",
    "I am individual": "ମୁଁ ବ୍ୟକ୍ତିଗତ ଅଟେ",
    "Securely Verify Your Credit Score": "ଆପଣଙ୍କର କ୍ରେଡିଟ୍ ସ୍କୋରକୁ ସୁରକ୍ଷିତ ଭାବରେ ଯାଞ୍ଚ କରନ୍ତୁ",
    "Enter Your Mobile Number": "ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "ଆପଣଙ୍କର କ୍ରେଡିଟ୍ ସ୍କୋରକୁ ସୁରକ୍ଷିତ ଭାବରେ ପ୍ରବେଶ କରିବାକୁ, ଦୟାକରି ନିମ୍ନରେ ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ। ଆପଣଙ୍କ ପରିଚୟ ଯାଞ୍ଚ କରିବା ଏବଂ ଆପଣଙ୍କ ସୂଚନାର ଗୋପନୀୟତା ନିଶ୍ଚିତ କରିବାକୁ ଆମେ ଆପଣଙ୍କୁ ଏକ ଥର ପାସୱାର୍ଡ (OTP) ପଠାଇବୁ।",
    "Verify without OTP": "OTP ବିନା ଯାଞ୍ଚ କରନ୍ତୁ",
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "ଦାଖଲ ପରେ, OTP ପାଇଁ ତୁରନ୍ତ ତୁମର ସନ୍ଦେଶ ଯାଞ୍ଚ କର। ଏହି ପଦକ୍ଷେପ ନିଶ୍ଚିତ କରେ ଯେ ତୁମର କ୍ରେଡିଟ୍ ପ୍ରୋଫାଇଲରେ ମୂଲ୍ୟବାନ ଜ୍ଞାନ ହାସଲ କରୁଥିବାବେଳେ ତୁମର ଆର୍ଥିକ ସୂଚନା ସୁରକ୍ଷିତ ରହିବ।",
    "Enter Your OTP Here": "ତୁମର OTP ଏଠାରେ ପ୍ରବେଶ କର",
    "Error: Invalid Mobile Number": "ତ୍ରୁଟି: ଭୁଲ ମୋବାଇଲ୍ ନମ୍ବର",
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ। ପ୍ରବେଶ କରିଥିବା ମୋବାଇଲ୍ ନମ୍ବର ଅବ alid ଧ ଅଟେ। ଦୟାକରି ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ପ୍ରବେଶ କରିଛନ୍ତି।",
    "Applicant’s correct 10-digit Mobile number.": " ଆବେଦନକାରୀଙ୍କ ସଠିକ୍ 10 ଅଙ୍କ ବିଶିଷ୍ଟ ମୋବାଇଲ୍ ନମ୍ବର।",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "ମୋବାଇଲ୍ ନମ୍ବର କିମ୍ବା ଅଧିକ ସହାୟତା ପାଇଁ + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ। ଆମେ ଆପଣଙ୍କ ସହଯୋଗକୁ ପ୍ରଶଂସା କରୁଛୁ।",
    "Good Morning": "ଶୁଭ ସକାଳ",
    "Good Evening": "ଶୁଭ ସନ୍ଧ୍ୟା",
    "Good Night": "ଶୁଭ ରାତ୍ରି",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0). Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "ଆମେ ଆପଣଙ୍କୁ ଜଣାଇବାକୁ ଦୁ regret ଖିତ ଯେ ପ୍ରବିଷ୍ଟ ହୋଇଥିବା GST ନମ୍ବର ଆବଶ୍ୟକ ଫର୍ମାଟ୍ (00-XXXXX-0000-X-0-X-0) ସହିତ ମେଳ ଖାଉ ନାହିଁ। ଦୟାକରି ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସଠିକ୍ ଜିଏସ୍ଟି ବିବରଣୀ ପ୍ରବେଶ କରିଛନ୍ତି ଏବଂ ସଠିକ୍ ଯାଞ୍ଚ ପାଇଁ ନିର୍ଦ୍ଦିଷ୍ଟ ଫର୍ମାଟ୍ ଅନୁସରଣ କରନ୍ତୁ | । ",
    "Error: Invalid PAN category": "ତ୍ରୁଟି: ଭୁଲ ପାନ୍ ବର୍ଗ",
    "Rs.": "Rs.",
    "Verification Complete – Thank You!": "ଯାଞ୍ଚ ସମ୍ପୂର୍ଣ୍ଣ - ଧନ୍ୟବାଦ!",
    "Congratulations! You've completed the Verification process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "ଅଭିନନ୍ଦନ! ଆପଣ ଯାଞ୍ଚ ପ୍ରକ୍ରିୟା ସମାପ୍ତ କରିଛନ୍ତି। ଆବଶ୍ୟକ ସୂଚନା ପ୍ରଦାନ କରିବାରେ ଆପଣଙ୍କର ଉତ୍ସର୍ଗୀକୃତତା ଆପଣଙ୍କୁ ପ୍ରଭାବଶାଳୀ ଭାବରେ ସେବା କରିବା ପାଇଁ ଆମକୁ ଗୋଟିଏ ପାଦ ନିକଟତର କରିଛି।",
    "With your Verification finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "ଆପଣଙ୍କର ଯାଞ୍ଚ ଚୂଡାନ୍ତ ହେବା ସହିତ, ଆପଣଙ୍କର ଦଳ ଦକ୍ଷତାର ସହିତ ଆପଣଙ୍କର ଆବଶ୍ୟକତାକୁ ପରିଚାଳନା କରିବା ପାଇଁ ଆମର ସଜ୍ଜିତ ହୋଇଛି। ଆମେ ଆପଣଙ୍କୁ ନିଶ୍ଚିତ କରୁଛୁ ଯେ ଆପଣଙ୍କ ମାମଲା ଅତି ଯତ୍ନ ସହକାରେ ବିସ୍ତୃତ ଭାବରେ ଧ୍ୟାନ ଦିଆଯିବ।",
    "having Gst number": "Gst ନମ୍ବର ଅଛି",
    "Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.": "ଆପଣଙ୍କର UDYAM ନମ୍ବର ଆମକୁ ଆପଣଙ୍କର ବ୍ୟବସାୟ ସହିତ ଜଡିତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ବିବରଣୀ ସଂଗ୍ରହ କରିବାକୁ ଅନୁମତି ଦିଏ | ଏଥିରେ ଆପଣଙ୍କର ବ୍ୟବସାୟ ଠିକଣା, ସାମ୍ପ୍ରତିକ ସ୍ଥିତି, ସେବା ବିବରଣୀ ଏବଂ ସଂଗଠନ ପ୍ରକାର ଅନ୍ତର୍ଭୁକ୍ତ |",


    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "ଯଦି ଏହି ସମସ୍ୟା ଜାରି ରହେ, ଆପଣ ପୁନ end ପଠାଇବା ଲିଙ୍କରେ କ୍ଲିକ୍ କରି କିମ୍ବା + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରି ଏକ ନୂତନ OTP ଅନୁରୋଧ କରିପାରିବେ |",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.": "ତୁମର GSTIN ପ୍ରବେଶ କରିବା ପରେ, ପ୍ରାଇଭେଟକୋର୍ଟ୍ ଏହା ସହିତ ଜଡିତ ଜରୁରୀ ସୂଚନା ସଂଗ୍ରହ କରିବ | ଏଥିରେ ଆପଣଙ୍କର ସମ୍ପୂର୍ଣ୍ଣ ନାମ, ଜନ୍ମ ତାରିଖ, ଆବାସିକ ଠିକଣା, ଏବଂ ଯୋଗାଯୋଗ ବିବରଣୀ ଅନ୍ତର୍ଭୁକ୍ତ | ବ୍ୟବସାୟ ମାଲିକମାନଙ୍କ ପାଇଁ, ଆମେ ଆପଣଙ୍କର GSTIN ସହିତ ଜଡିତ ସମସ୍ତ ପ୍ରଯୁଜ୍ୟ ବ୍ୟବସାୟ ବିବରଣୀକୁ ଅନ୍ତର୍ଭୁକ୍ତ କରିବା ପାଇଁ ଆମର ତଥ୍ୟ ସଂଗ୍ରହକୁ ବିସ୍ତାର କରୁ, ଯେପରିକି MCA ନିର୍ଦ୍ଦିଷ୍ଟତା ଏବଂ ଜିଏସ୍ଟି ରିଟର୍ନ ଫାଇଲଗୁଡିକର ରେକର୍ଡ |",

    "Securely Verify Your Credit Score": "ଆପଣଙ୍କର କ୍ରେଡିଟ୍ ସ୍କୋରକୁ ସୁରକ୍ଷିତ ଭାବରେ ଯାଞ୍ଚ କରନ୍ତୁ",
    "Enter Your Mobile Number": "ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ"
    ,
    "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "ଆପଣଙ୍କର କ୍ରେଡିଟ୍ ସ୍କୋରକୁ ସୁରକ୍ଷିତ ଭାବରେ ପ୍ରବେଶ କରିବାକୁ, ଦୟାକରି ନିମ୍ନରେ ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ। ଆପଣଙ୍କ ପରିଚୟ ଯାଞ୍ଚ କରିବା ଏବଂ ଆପଣଙ୍କ ସୂଚନାର ଗୋପନୀୟତା ନିଶ୍ଚିତ କରିବାକୁ ଆମେ ଆପଣଙ୍କୁ ଏକ ଥର ପାସୱାର୍ଡ (OTP) ପଠାଇବୁ।"
    ,
    "For any queries or assistance, feel free to call us at +91-9699900111.": "କ any ଣସି ପ୍ରଶ୍ନ କିମ୍ବା ସହାୟତା ପାଇଁ, ଆମକୁ + 91-9699900111 ରେ କଲ କରିବାକୁ ମନା କରନ୍ତୁ।"
    ,
    "Verify without OTP": "OTP ବିନା ଯାଞ୍ଚ କରନ୍ତୁ"
    ,
    "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "ଦାଖଲ ପରେ, OTP ପାଇଁ ତୁରନ୍ତ ତୁମର ସନ୍ଦେଶ ଯାଞ୍ଚ କର। ଏହି ପଦକ୍ଷେପ ନିଶ୍ଚିତ କରେ ଯେ ତୁମର ଆର୍ଥିକ ସୂଚନା ସୁରକ୍ଷିତ ରହିବ ଯେତେବେଳେ ତୁମେ ତୁମର କ୍ରେଡିଟ୍ ପ୍ରୋଫାଇଲରେ ମୂଲ୍ୟବାନ ଜ୍ଞାନ ହାସଲ କରିବ।"
    ,
    "Enter Your OTP Here": "ତୁମର OTP ପ୍ରବେଶ କର"
    ,
    "Error: Invalid Mobile Number": "ତ୍ରୁଟି: ଅବ Mobile ଧ ମୋବାଇଲ୍ ନମ୍ବର"
    ,
    "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ। ପ୍ରବେଶ କରିଥିବା ମୋବାଇଲ୍ ନମ୍ବର ଅବ alid ଧ ଅଟେ। ଦୟାକରି ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ପ୍ରବେଶ କରିଛନ୍ତି।"
    ,
    "Applicant’s correct 10-digit Mobile number.": "ଆବେଦନକାରୀଙ୍କ ସଠିକ୍ 10 ଅଙ୍କ ବିଶିଷ୍ଟ ମୋବାଇଲ୍ ନମ୍ବର।",
    "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "ମୋବାଇଲ୍ ନମ୍ବର କିମ୍ବା ଅଧିକ ସହାୟତା ପାଇଁ + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ। ଆମେ ଆପଣଙ୍କ ସହଯୋଗକୁ ପ୍ରଶଂସା କରୁଛୁ।"
    ,
    "Error: Invalid OTP": "ତ୍ରୁଟି: ଭୁଲ OTP"
    ,
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "ଅସୁବିଧା ପାଇଁ ଆମେ କ୍ଷମା ପ୍ରାର୍ଥନା କରୁଛୁ। ପ୍ରବେଶ କରିଥିବା OTP ଭୁଲ ଅଟେ। ଦୟାକରି OTP କୁ ଦୁଇଥର ଯାଞ୍ଚ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ।"
    ,
    "mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "ମୋବାଇଲ୍ ନମ୍ବର କିମ୍ବା ଅଧିକ ସହାୟତା ପାଇଁ + 91-9699900111 ରେ ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ। ଆମେ ଆପଣଙ୍କ ସହଯୋଗକୁ ପ୍ରଶଂସା କରୁଛୁ।"
    ,
    "Verify OTP": "OTP ଯାଞ୍ଚ କରନ୍ତୁ"
    ,
    "Verify": "ଯାଞ୍ଚ କରନ୍ତୁ",

}


export default OdiaTranslation;