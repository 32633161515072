const HindiTranslation = {
  "Email Security Check": "ईमेल सुरक्षा जांच",
  "Verify Your Email for Account Access":
    "खाता पहुंच के लिए अपना ईमेल सत्यापित करें",
  "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system":
    "अपने खाते की सुरक्षा सुनिश्चित करने के लिए, कृपया निर्दिष्ट प्रारूप में अपना वैध ईमेल आईडी दर्ज करें- username@domain.com। यदि आपका ईमेल पता हमारे सिस्टम में मौजूद नहीं है, तो",
  "NEW USER": "नया उपयोगकर्ता",
  "account will be created for you": "खाता बनाया जाएगा।",
  "Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.":
    "एक बार सबमिट करने के बाद, आपको आपके दिए गए ईमेल पते पर एक सत्यापन ओटीपी (वन-टाइम पासवर्ड) भेजा जाएगा।",
  "For any queries or assistance, feel free to call us at +91-9699900111.":
    "किसी भी प्रश्न या सहायता के लिए, बेझिझक हमें यहां कॉल करें +91-9699900111",
  "Thank you for entrusting PrivateCourt. Your security is our priority.":
    "प्राइवेटकोर्ट को सौंपने के लिए धन्यवाद। आपकी सुरक्षा हमारी प्राथमिकता है.",
  "Verify": "सत्यापित करें",
  "Invalid Email Address": "अमान्य ईमेल पता",
  "We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.":
    "हमें आपको यह बताते हुए खेद है कि दर्ज किया गया ईमेल पता अमान्य है। कृपया सुनिश्चित करें कि आपने सही और उचित रूप से प्रारूपित ईमेल पता दर्ज किया है।",
  "If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).":
    "यदि समस्या बनी रहती है, तो ईमेल प्रारूप (उदाहरण के लिए, username@example.com) में किसी भी टाइपो या त्रुटि के लिए दोबारा जांच करें।",
  "For further queries or assistance, feel free to call us at +91-9699900111.":
    "अधिक प्रश्नों या सहायता के लिए, बेझिझक हमें यहां कॉल करें +91-9699900111.",
  "Thank you for entrusting PrivateCourt. Your security is our priority.":
    "प्राइवेटकोर्ट को सौंपने के लिए धन्यवाद। आपकी सुरक्षा हमारी प्राथमिकता है.",
  "Secure UDYAM Number Validation": "सुरक्षित उद्यम नंबर सत्यापन",
  "Enter Your Udyam Number": "अपना उद्यम नंबर दर्ज करें",
  "Please enter your correct UDYAM Number.":
    "कृपया अपना सही उद्यम नंबर दर्ज करें।",
  "Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.":
    "आपका उद्यम नंबर हमें आपके व्यवसाय से जुड़े महत्वपूर्ण विवरण एकत्र करने की अनुमति देता है। इसमें आपका व्यावसायिक पता, वर्तमान स्थिति, सेवा विवरण और संगठन का प्रकार शामिल है।",
  "Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.":
    "निर्बाध सत्यापन प्रक्रिया के लिए आपके उद्यम नंबर की सटीकता सुनिश्चित करना महत्वपूर्ण है।",
  "UDYAM": "उद्यम",
  "Error: Invalid UDYAM Number": "त्रुटि: अमान्य उद्यम नंबर",
  "We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.":
    "हमें आपको यह बताते हुए खेद है कि दर्ज किया गया UDYAM नंबर अमान्य या गलत है। कृपया सुनिश्चित करें कि आपने सही UDYAM विवरण दर्ज किया है और किसी भी टाइपिंग त्रुटि के लिए दोबारा जांच करें।",
  "If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.":
    "यदि आपको लगातार समस्याओं का सामना करना पड़ रहा है, तो कृपया अपने उद्यम प्रमाणपत्र को सत्यापित करें और विवरण दोबारा दर्ज करें।",
  "Secure PAN Validation Process": "सुरक्षित पैन सत्यापन प्रक्रिया",
  "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.":
    "यह सत्यापन प्रक्रिया हमारी सेवाओं की अखंडता को बनाए रखने, एक विश्वसनीय और सुरक्षित अनुभव सुनिश्चित करने के लिए डिज़ाइन की गई है।",
  "Enter Your PAN Number": "अपना पैन नंबर दर्ज करें",
  "Please enter your correct PAN number in the format XXXXX-0000-X.":
    "कृपया XXXXX-0000-X प्रारूप में अपना सही पैन नंबर दर्ज करें।",
  "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.":
    "प्राइवेटकोर्ट आपके पैन से जुड़ी आवश्यक जानकारी जैसे आपका पूरा नाम, जन्म तिथि, आवासीय पता और संपर्क विवरण सावधानीपूर्वक एकत्र करेगा। व्यापार मालिकों के लिए, हम आपके पैन से जुड़े सभी प्रासंगिक व्यापार विवरणों जैसे जीएसटी विवरण, एमसीए विशिष्टताओं और जीएसटी रिटर्न फाइलिंग के रिकॉर्ड को शामिल करने के लिए अपने डेटा संग्रह का विस्तार करते हैं।",
  "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.":
    "हमारा सिस्टम वित्तीय जानकारी के लिए आपके सिबिल स्कोर का आकलन करता है और आपके पैन से जुड़े किसी भी चल रहे अदालती मामले के लिए क्रॉस-सत्यापन करता है।",
  "Error: Invalid PAN Format": "त्रुटि: अमान्य पैन प्रारूप",
  "We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.":
    "हमें आपको यह बताते हुए खेद है कि दर्ज किया गया पैन नंबर आवश्यक प्रारूप (XXXXX-0000-X) से मेल नहीं खाता है। कृपया सुनिश्चित करें कि आपने सही पैन विवरण दर्ज किया है और सटीक सत्यापन के लिए निर्दिष्ट प्रारूप का पालन करें।",
  "If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.":
    "यदि आपको लगातार समस्याओं का सामना करना पड़ रहा है, तो कृपया अपने पैन कार्ड की दोबारा जांच करें और विवरण दोबारा दर्ज करें। अधिक प्रश्नों या सहायता के लिए, बेझिझक हमें +91-9699900111 पर कॉल करें।",
  "Welcome XXXX": "स्वागत XXXX",
  "Account security starts with verification":
    "खाते की सुरक्षा सत्यापन से शुरू होती है",
  "OTP Validation for Account Access": "अकाउंट एक्सेस के लिए ओटीपी सत्यापन",
  "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.":
    "कृपया अपना इनबॉक्स जांचें, और सत्यापन प्रक्रिया को पूरा करने के लिए निर्दिष्ट फ़ील्ड में ओटीपी दर्ज करें।",
  "Didn't receive OTP?": "ओटीपी नहीं मिला?",
  "Request a Resend": "पुनः भेजने का अनुरोध करें",
  "Verify OTP": "ओटीपी",
  "Error: Incorrect OTP": "त्रुटि: ग़लत ओटीपी",
  "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। दर्ज किया गया वन-टाइम पासवर्ड (ओटीपी) गलत है। कृपया सुनिश्चित करें कि आपने अपने ईमेल पर प्राप्त ओटीपी को सही ढंग से दर्ज किया है।",
  "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.":
    "यदि समस्या बनी रहती है, तो आप पुनः भेजें लिंक पर क्लिक करके या हमारी सहायता टीम से +91-9699900111 पर संपर्क करके नए ओटीपी का अनुरोध कर सकते हैं।",
  "Double-check the email and try entering the OTP again.":
    "ईमेल को दोबारा जांचें और ओटीपी दोबारा दर्ज करने का प्रयास करें।",
  "Choose Profile": "प्रोफ़ाइल चुनें",
  "Setting Your Default Profile": "अपनी डिफ़ॉल्ट प्रोफ़ाइल सेट करना",
  "Please choose the default profile for a seamless experience.":
    "सहज अनुभव के लिए कृपया डिफ़ॉल्ट प्रोफ़ाइल चुनें।",
  "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":
    "यदि आप एकाधिक कंपनियों का प्रबंधन करते हैं, तो वह प्रोफ़ाइल चुनें जिसे आप डिफ़ॉल्ट के रूप में सेट करना चाहते हैं। यह सुनिश्चित करता है कि हर बार जब आप लॉग इन करते हैं, तो आपको अपनी पसंदीदा कंपनी से संबंधित जानकारी तक त्वरित पहुंच प्राप्त होगी।",
  "Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.":
    "अब सही चयन करना आपके भविष्य के लॉगिन को सरल बनाता है और आपकी प्राथमिक व्यावसायिक इकाई के आधार पर एक अनुरूप अनुभव प्रदान करता है।",
  "Go to Dashboard": "डैशबोर्ड पर जाएँ",
  "Data Compilation in Progress": "डेटा संकलन प्रगति पर है:",
  "Please Allow Processing Time": "कृपया प्रसंस्करण समय दें",
  "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":
    "अपनी जानकारी प्रदान करने के लिए धन्यवाद. हमारा सिस्टम वर्तमान में आपकी व्यापक प्रोफ़ाइल स्थापित करने के लिए आपके द्वारा दर्ज किए गए डेटा का विश्लेषण और संकलन करने की प्रक्रिया में है, जिसमें पैन, आधार, जीएसटी, ईमेल और अधिक जैसे विवरण शामिल हैं।",
  "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":
    "इस ऑपरेशन में कुछ क्षण लग सकते हैं. इस दौरान, आपकी स्क्रीन न्यूनतम गतिविधि दिखा सकती है। कृपया आश्वस्त रहें कि यह हमारी डेटा संकलन प्रक्रिया का एक नियमित हिस्सा है।",
  "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.":
    "सटीकता और सुरक्षा बनाए रखने के लिए आपकी प्रोफ़ाइल सावधानीपूर्वक तैयार की जा रही है। हम आपके धैर्य और समझ की ईमानदारी से सराहना करते हैं क्योंकि हम आपके अनुभव को बढ़ाने के लिए काम करते हैं।",
  "Start Your Bank Account Verification": "अपना बैंक खाता सत्यापन प्रारंभ करें",
  "Bank Verification": "बैंक सत्यापन",
  "Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.":
    "निर्बाध सत्यापन प्रक्रिया के लिए आपके बैंक खाते की जानकारी की सटीकता सुनिश्चित करना महत्वपूर्ण है। कृपया अपना खाता नंबर दर्ज करें, और अपने खाते से जुड़ा सही आईएफएससी कोड दर्ज करें।",
  "This verification step is designed to enhance the security of our services and provide you with a reliable experience.":
    "यह सत्यापन चरण हमारी सेवाओं की सुरक्षा बढ़ाने और आपको एक विश्वसनीय अनुभव प्रदान करने के लिए डिज़ाइन किया गया है।",
  "Account Number": "खाता संख्या",
  "IFSC code": "आईएफएससी कोड",
  "Error: Invalid Bank Account or IFSC Code":
    "त्रुटि: अमान्य बैंक खाता या आईएफएससी कोड",
  "We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.":
    "हमें आपको यह बताते हुए खेद है कि दर्ज किया गया बैंक खाता या आईएफएससी कोड अमान्य या गलत है। कृपया सुनिश्चित करें कि आपने दोनों विवरण सटीक रूप से दर्ज किए हैं और किसी भी टाइपिंग त्रुटि के लिए दोबारा जांच करें।",
  "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.":
    "यदि आपको लगातार समस्याओं का सामना करना पड़ रहा है, तो कृपया अपने बैंक विवरण को सत्यापित करें या प्रदान की गई जानकारी की सटीकता की पुष्टि करने के लिए अपने बैंक से संपर्क करें।",
  "Aadhaar Details Verification": "आधार विवरण सत्यापन",
  "Enter Your Aadhaar Number": "अपना आधार नंबर दर्ज करें",
  "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.":
    "कृपया अपना आधार नंबर दर्ज करें। यह प्रक्रिया आपकी सुरक्षा के लिए सटीक क्रॉस-चेकिंग सुनिश्चित करती है। आपके सहयोग के लिए धन्यवाद।",
  "Enter Your Aadhaar OTP Here": "यहां अपना आधार ओटीपी दर्ज करें",
  "Error: Incorrect Aadhaar OTP": "त्रुटि: गलत आधार ओटीपी",
  "We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.":
    "हमें आपको यह बताते हुए खेद है कि आधार सत्यापन के लिए दर्ज किया गया वन-टाइम पासवर्ड (ओटीपी) गलत है। कृपया सुनिश्चित करें कि आपने अपने पंजीकृत मोबाइल नंबर पर प्राप्त ओटीपी को सही ढंग से दर्ज किया है।",
  "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.":
    "यदि समस्या बनी रहती है, तो आप पुनः भेजें लिंक पर क्लिक करके या हमारी सहायता टीम से +91-9699900111 पर संपर्क करके नए ओटीपी का अनुरोध कर सकते हैं।",
  "Double-check the email and try entering the OTP again.":
    "ईमेल को दोबारा जांचें और ओटीपी दोबारा दर्ज करने का प्रयास करें।",
  "Error: Invalid Aadhaar Number": "त्रुटि: अमान्य आधार संख्या",
  "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। दर्ज किया गया आधार नंबर अमान्य है. कृपया दोबारा जांचें और सुनिश्चित करें कि आपने सही 12 अंकों का आधार नंबर दर्ज किया है।",
  "If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.":
    "यदि आपको लगातार समस्याओं का सामना करना पड़ रहा है, तो अपना आधार कार्ड सत्यापित करें या आगे की सहायता के लिए हमारी सहायता टीम से +91-9699900111 पर संपर्क करें। हम आपके सहयोग की सराहना करते हैं।",
  "Language Selection:": "भाषा चयन:",
  "Empowering Effective Communication": "प्रभावी संचार को सशक्त बनाना",
  "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":
    "शुभ दिन! मैं मूर्ति हूं, आज की कार्यवाही के लिए आपका समर्पित सहायक। आइए उस भाषा का चयन करके शुरुआत करें जिसमें आप सबसे अधिक सहज हैं।",
  "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":
    "अपनी पसंदीदा भाषा चुनने से स्पष्ट और प्रभावी संचार सुनिश्चित होता है। चाहे वह आपकी मातृभाषा हो या आपकी पसंद की कोई अन्य भाषा, इसका चयन करने से हमारी बातचीत सुव्यवस्थित होगी और आपका अनुभव बढ़ेगा।",
  "Select Your Preferred Language": "अपनी पसंदीदा भाषा चुनें",
  "Welcome to PrivateCourt, where Helping you is our priority.":
    "प्राइवेटकोर्ट में आपका स्वागत है, जहां आपकी मदद करना हमारी प्राथमिकता है।",
  "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":
    "अब, मैं आपको प्राइवेटकोर्ट से परिचित कराता हूं - जो ऋण संबंधी विवादों को प्रभावी ढंग से और कुशलता से हल करने में आपका विश्वसनीय भागीदार है।",
  "PrivateCourt": "प्राइवेटकोर्ट",
  "Your trusted partner in resolving lending disputes with care and expertise.":
    "देखभाल और विशेषज्ञता के साथ ऋण संबंधी विवादों को सुलझाने में आपका विश्वसनीय भागीदार।",
  "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":
    "प्राइवेटकोर्ट में आपका स्वागत है, जहां आपके मामले का विवरण अच्छे हाथों में है। ऋण देने वाली कंपनी द्वारा मध्यस्थता या सुलह शुरू करने के साथ, हमारे लिए आपकी स्थिति को पूरी तरह से समझना आवश्यक है। कृपया सभी विवरण साझा करें जो आप कर सकते हैं।",
  "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":
    "हमारे पास जितनी अधिक जानकारी होगी, हम उतनी ही बेहतर मदद कर सकेंगे। आपके लिए सही समाधान ढूंढने में आपका इनपुट महत्वपूर्ण है।",
  "Trust us to carefully examine your case and work together to find the best way forward.":
    "हम पर भरोसा करें कि हम आपके मामले की सावधानीपूर्वक जांच करेंगे और आगे बढ़ने का सर्वोत्तम रास्ता खोजने के लिए मिलकर काम करेंगे।",
  "Reviewing Your Dispute Information": "आपकी विवाद जानकारी की समीक्षा करना",
  "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by LendingKart, regarding a personal loan.":
    "आइए आपके विवाद से संबंधित विवरणों पर करीब से नज़र डालें। आज हम जिस जानकारी पर चर्चा कर रहे हैं वह व्यक्तिगत ऋण के संबंध में लेंडिंगकार्ट द्वारा हमारे साथ साझा की गई है।",
  "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":
    "इन विशिष्टताओं को समझकर, हम स्थिति का बेहतर आकलन कर सकते हैं और एक समाधान योजना तैयार कर सकते हैं जो आपकी परिस्थितियों और लक्ष्यों के अनुरूप हो।",
  "Dispute Amount Information": "विवाद राशि की जानकारी",
  "According to their records, you initially borrowed an amount of":
    "उनके रिकॉर्ड के अनुसार, आपने शुरू में फ़ोर की राशि उधार ली थी",
  "Presently, your outstanding due stands at Rs.":
    "वर्तमान में, आपका बकाया रु.",
  "These details provide us with valuable insight into the history and current status of your loan.":
    "ये विवरण हमें आपके ऋण के इतिहास और वर्तमान स्थिति के बारे में बहुमूल्य जानकारी प्रदान करते हैं।",
  "Spotting Financial Issues: Understanding Unpaid Obligations":
    "वित्तीय मुद्दों का पता लगाना: अवैतनिक दायित्वों को समझना",
  "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":
    "अब, आइए वित्तीय बाधाओं के कारण आपके सामने आने वाली चुनौतियों का धीरे से पता लगाएं। इस पर चर्चा करना संवेदनशील हो सकता है, लेकिन आपका खुलापन हमें आगे बढ़ने का सर्वोत्तम रास्ता खोजने में बहुत मदद करेगा। कृपया निम्नलिखित में से कोई भी विकल्प चुनने के लिए कुछ समय दें जो आपके अनुभव से मेल खाता हो:",
  "Financial Loss": "वित्तीय क्षति",
  "Business Loss": "व्यापार हानि",
  "Partner Dispute": "साथी विवाद",
  "Funds held with other vendors or clients":
    "अन्य विक्रेताओं या ग्राहकों के पास रखी गई धनराशि",
  "Monetary loss through other means": "अन्य माध्यमों से धन हानि",
  "Job Loss": "रोजगार हानि",
  "Less than 2 months": "2 महीने से भी कम",
  "Less than 6 months": "6 महीने से कम",
  "Less than 1 year": "एक वर्ष से कम",
  "More than 1 year": "1 वर्ष से अधिक",
  "Health Issue": "स्वास्थ्य का मसला",
  "Death of the earning member": "कमाने वाले सदस्य की मृत्यु",
  "Missing payment details": "भुगतान विवरण गुम है",
  "Dispute due to bad behaviour": "बुरे आचरण के कारण विवाद",
  "No clear loan information": "ऋण संबंधी कोई स्पष्ट जानकारी नहीं",
  "Death Case": "मौत का मामला",
  "We kindly request you to upload the death certificate at":
    "हम आपसे अनुरोध करते हैं कि मृत्यु प्रमाणपत्र कानूनी",
  "Other reason/s": "अन्य कारण",
  "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":
    "आपकी त्वरित प्रतिक्रिया से हमें स्थिति का आकलन करने और सबसे उपयुक्त समाधान खोजने में बहुत मदद मिलेगी। निश्चिंत रहें, हम किसी भी लंबित मुद्दे को हल करने और निष्पक्ष और संतोषजनक परिणाम सुनिश्चित करने के लिए आपके साथ काम करने के लिए प्रतिबद्ध हैं।",
  "Exploring Settlement Possibilities": "निपटान की संभावनाएँ तलाशना",
  "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":
    "जैसे-जैसे हमारी चर्चा आगे बढ़ती है, मैं डिफॉल्ट का कारण समझाने में आपके खुलेपन की सराहना करता हूं। किसी भी कानूनी जटिलता से बचने के लिए अपने बकाया बकाया का तुरंत समाधान करना महत्वपूर्ण है। हम बिना किसी देरी के अपने दायित्वों को पूरा करने के लिए विभिन्न रास्ते तलाशने की सलाह देते हैं, जैसे परिवार से सहायता लेना या वैकल्पिक वित्तपोषण विकल्पों की जांच करना।",
  "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":
    "समय पर निपटान न केवल कानूनी जोखिमों को कम करता है बल्कि आपके वित्तीय संबंधों में विश्वास और विश्वसनीयता को भी मजबूत करता है। इस मामले में आपका सहयोग वास्तव में मूल्यवान है।",
  "Settlement Options": "निपटान विकल्प",
  "In our efforts to facilitate a swift resolution, we seek your cooperation.":
    "त्वरित समाधान को सुविधाजनक बनाने के हमारे प्रयासों में, हम आपका सहयोग चाहते हैं।",
  "Are you open to discussing the settlement of your outstanding dues?":
    "क्या आप अपनी बकाया राशि के निपटान पर चर्चा करने के लिए तैयार हैं?",
  "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,":
    "हम आपकी बकाया राशि के समाधान के लिए आपके खुलेपन की सराहना करते हैं। अपनी वित्तीय स्थिति को बेहतर ढंग से समझने और उसके अनुसार हमारे दृष्टिकोण को तैयार करने के लिए,",
  "could you kindly provide details on your current family income?":
    "क्या आप कृपया अपनी वर्तमान पारिवारिक आय का विवरण प्रदान कर सकते हैं?",
  "In our efforts to address your outstanding dues, we'd like to know if a":
    "आपके बकाया भुगतान के समाधान के हमारे प्रयासों में, हम जानना चाहेंगे कि क्या",
  "one-time payment settlement is an option for you.":
    "एकमुश्त भुगतान निपटान आपके लिए एक विकल्प है।",
  "We appreciate your feedback. If a one-time payment is not viable,":
    "हम आपके फ़ीडबैक की सराहना करते हैं। यदि एकमुश्त भुगतान व्यवहार्य नहीं है,",
  "could you consider arranging payment in three equal instalments?":
    "तो क्या आप तीन समान किश्तों में भुगतान की व्यवस्था करने पर विचार कर सकते हैं?",
  "We Appreciate Your Honesty.": "हम आपकी ईमानदारी की सराहना करते हैं।",
  "Next": "अगला",
  "I am unable to pay by any means":
    "मैं किसी भी तरह से भुगतान करने में असमर्थ हूं",
  "Amount with Yes": "हाँ के साथ राशि",
  "No": "नहीं",
  "Please Select": "कृपया चयन कीजिए",
  "This approach could help ease any immediate financial strain while still addressing the outstanding dues.":
    "यह दृष्टिकोण बकाया राशि को संबोधित करते हुए किसी भी तत्काल वित्तीय तनाव को कम करने में मदद कर सकता है।",
  "No Income": "कोई कमाई नहीं",
  "less than Rs. 10000/- per month": "रुपये से कम 10000/- प्रति माह",
  "less than Rs. 20,000/- per month": "रुपये से कम 20000/- प्रति माह",
  "less than Rs. 40,000/- per month": "रुपये से कम 40000/- प्रति माह",
  "less than Rs. 60,000/- per month": "रुपये से कम 60000/- प्रति माह",
  "more than Rs. 1,00,000/- per month": "रुपये से अधिक. 1,00,000/- प्रति माह",
  "Yes": "हाँ",

  // new added
  "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.":
    "कृपया सुनिश्चित करें कि आपने सही जीएसटी विवरण दर्ज किया है और सटीक सत्यापन के लिए निर्दिष्ट प्रारूप का पालन करें।",
  "If you continue to face issues, kindly double-check your PAN card and re-enter the details.":
    "आप यदि क्रमागतः समस्याओं का सामना कर रहे हैं, तो कृपया अपने PAN कार्ड को दोबारा जांचें और विवरण पुनः दर्ज करें।",
  "The GST provided already exists in our records. Please try using a different GST for verification.":
    "प्रदान किया गया जीएसटी हमारे रिकॉर्ड में पहले से ही मौजूद है। कृपया सत्यापन के लिए एक अलग जीएसटी का उपयोग करने का प्रयास करें।",
  "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.":
    "ऐसा प्रतीत होता है कि प्रदान किए गए पैन विवरण एक कंपनी के हैं। सत्यापन उद्देश्यों के लिए हमें आपके व्यक्तिगत पैन विवरण की आवश्यकता है।",
  "If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.":
    "आप यदि क्रमागतः समस्याओं का सामना कर रहे हैं, तो कृपया अपने पैन कार्ड को दोबारा जांचें और विवरण पुनः दर्ज करें। अधिक प्रश्नों या सहायता के लिए, बेझिझक हमें +91-9699900111 पर कॉल करें।",
  "The PAN provided already exists in our records. Please try using a different PAN for verification.":
    "प्रदान किया गया पैन हमारे रिकॉर्ड में पहले से ही मौजूद है। कृपया सत्यापन के लिए एक अलग पैन का उपयोग करने का प्रयास करें।",
  "New User Registration": "नए उपयोगकर्ता के लिए पंजीकरण",
  "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:":
    "हमारे साथ अपनी यात्रा शुरू करने के लिए, हम सभी नए उपयोगकर्ताओं से अनुरोध करते हैं कि वे नीचे दिए गए सरल चरणों का पालन करके पंजीकरण करें:",
  "Registration": "पंजीकरण",
  "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of":
    "पंजीकरण करने और हमारी व्यापक सत्यापन सेवाओं तक पहुंच प्राप्त करने के लिए, कृपया एकमुश्त भुगतान करके आगे बढ़ें",
  "Rs. 500": "रु. 500",
  "GST": "GST",
  "done": "हो गया",
  "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.":
    "कृपया ध्यान दें कि पंजीकरण शुल्क सरकारी नियमों के अनुसार 18% जीएसटी (वस्तु एवं सेवा कर) के अधीन है।",
  "Total Amount Due:": "कुल शेष राशि:",
  "Rs. 590": "रु. 590",
  "Total Registration Fee + 18% GST": "कुल पंजीकरण शुल्क + 18% जीएसटी",
  "Secure Payment Gateway": "सुरक्षित भुगतान गेटवे",
  "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.":
    "आपकी भुगतान और व्यक्तिगत जानकारी को हमारे एन्क्रिप्टेड भुगतान गेटवे के माध्यम से सबसे अधिक सुरक्षितता के साथ संभाला जाता है।",
  "Registration Complete - Thank You!": "पंजीकरण पूरा - धन्यवाद!",
  "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.":
    "बधाई हो! आपने पंजीकरण प्रक्रिया पूरी की है। आपका आवश्यक जानकारी प्रदान करने का समर्पण हमें आपकी सेवा को प्रभावी ढंग से प्रदान करने के लिए एक कदम आगे ले आया है।",
  "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.":
    "आपके पंजीकरण को समाप्त करने के साथ, हमारी टीम पूरी तरह से आपकी आवश्यकताओं को कुशलता से संभालने के लिए योग्य है। हम आपको आश्वासन देते हैं कि आपका मामला सर्वोत्तम ध्यान और विवरण को ध्यान में रखते हुए संभाला जाएगा।",
  "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.":
    "यदि आपको कभी भी सहायता की आवश्यकता हो या कोई प्रश्न हो, तो कृपया हमसे संपर्क करने का स्वतंत्र महसूस करें। हम यहां आपकी चिंताओं का समाधान करने और प्रक्रिया के दौरान एक सहज यात्रा सुनिश्चित करने के लिए हैं।",
  "We eagerly anticipate the opportunity to assist you further.":
    "हम आपको और अधिक सहायता करने का अवसर उत्सुकता से आकांक्षा करते हैं।",
  "Warm regards,": "नमस्कार,",
  "PrivateCourt Team": "प्राइवेटकोर्ट टीम",
  "Secure UDYAM Number Validation": "सुरक्षित UDYAM नंबर सत्यापन",
  "Enter UDYAM for": "के लिए उद्यम दर्ज करें",
  "having GST number": "GST नंबर होना",
  "Enter Your Udyam Number": "अपना उद्यम नंबर दर्ज करें",
  "Error: Invalid UAM Number": "त्रुटि: अमान्य यूएएम नंबर",
  "We regret to inform you that the UAM Number entered isinvalid or incorrect. Please ensure you have accuratelyentered detail and double-check for any typos.":
    "हमें आपको यह बताते हुए खेद है कि दर्ज किया गया यूएएम नंबर अमान्य या गलत है। कृपया सुनिश्चित करें कि आपने विवरण सही ढंग से दर्ज किया है और किसी भी टाइपिंग त्रुटि के लिए दोबारा जांच करें।",
  "Server Overload: Please Retry Later":
    "सर्वर ओवरलोड: कृपया बाद में पुनः प्रयास करें",
  "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.":
    "असुविधा के लिए हम क्षमा चाहते हैं और आपके धैर्य की सराहना करते हैं। वर्तमान में, हमारा सर्वर अनुरोधों में वृद्धि का अनुभव कर रहा है, जिससे अस्थायी देरी हो रही है। निश्चिंत रहें, सटीकता और सुरक्षा सुनिश्चित करने के लिए हम परिश्रमपूर्वक आपका डेटा एकत्र कर रहे हैं।",
  "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.":
    "कृपया इस व्यस्त अवधि के दौरान हमारे साथ बने रहें, और हम आपसे अनुरोध करते हैं कि दोबारा प्रयास करने से पहले कुछ क्षण प्रतीक्षा करें। हम जो सेवा प्रदान करने का प्रयास कर रहे हैं उसकी गुणवत्ता बनाए रखने में आपकी समझ महत्वपूर्ण है।",
  "For urgent matters or assistance, feel free to call us at +91-9699900111.":
    "अत्यावश्यक मामलों या सहायता के लिए, बेझिझक हमें +91-9699900111 पर कॉल करें।",
  "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.":
    "आपके धैर्य और PrivateCourt को सौंपने के लिए धन्यवाद। आपकी सुरक्षा हमारी प्राथमिकता है.",
  "If you continue to face issues, verify the":
    "यदि आपको लगातार समस्याओं का सामना करना पड़ रहा है, तो सत्यापित करें",
  "Applicant’s": "आवेदक का",
  "Error: Invalid OTP": "त्रुटि: अमान्य ओटीपी",
  "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। दर्ज किया गया ओटीपी गलत है. कृपया ओटीपी की दोबारा जांच करें और पुनः प्रयास करें।",
  "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.":
    "आधार कार्ड या अधिक सहायता के लिए हमारी सहायता टीम से +91-9699900111 पर संपर्क करें। हम आपके सहयोग की सराहना करते हैं।",
  "Error: User already exists": "त्रुटि: उपयोगकर्ता पहले से मौजूद है",
  "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। प्रदान किया गया आधार नंबर पहले से ही हमारे रिकॉर्ड में मौजूद है। कृपया सत्यापन के लिए एक अलग आधार नंबर प्रदान करें।",
  "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। प्रदान किया गया नाम पैन रिकॉर्ड से मेल नहीं खाता। कृपया सुनिश्चित करें कि दर्ज किया गया नाम आपके पैन कार्ड की जानकारी से मेल खाता है और पुनः प्रयास करें।",
  "Enter Bank Details for": "के लिए बैंक विवरण दर्ज करें",
  "Error: Already Exists": "त्रुटि: पहले से ही मौजूद है",
  "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.":
    "हमें आपको यह सूचित करते हुए खेद हो रहा है कि आपके द्वारा प्रदान की गई आईडी पहले से ही हमारे सिस्टम में मौजूद है। कृपया कोई अन्य आईडी आज़माएँ.",
  "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.":
    "यदि आपको लगातार समस्याओं का सामना करना पड़ रहा है, तो कृपया अपने बैंक विवरण को सत्यापित करें या प्रदान की गई जानकारी की सटीकता की पुष्टि करने के लिए अपने बैंक से संपर्क करें।",
  "Error: Name Not Matched": "त्रुटि: नाम मेल नहीं खाता",
  "Good Afternoon": "शुभ दोपहर",
  "Account security starts with verification":
    "खाते की सुरक्षा सत्यापन से शुरू होती है",
  "OTP Validation for Account Access": "अकाउंट एक्सेस के लिए ओटीपी सत्यापन",
  "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.":
    "कृपया अपना इनबॉक्स जांचें, और सत्यापन प्रक्रिया को पूरा करने के लिए निर्दिष्ट फ़ील्ड में ओटीपी दर्ज करें।",
  "Request a Resend": "पुनः भेजने का अनुरोध करें",
  "Error: Incorrect OTP": "त्रुटि: ग़लत ओटीपी",
  "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। दर्ज किया गया वन-टाइम पासवर्ड (ओटीपी) गलत है। कृपया सुनिश्चित करें कि आपने अपने ईमेल पर प्राप्त ओटीपी को सही ढंग से दर्ज किया है।",
  "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.":
    "यदि समस्या बनी रहती है, तो आप पुनः भेजें लिंक पर क्लिक करके या हमारी सहायता टीम से +91-9699900111 पर संपर्क करके नए ओटीपी का अनुरोध कर सकते हैं।",
  "Double-check the email and try entering the OTP again.":
    "ईमेल को दोबारा जांचें और ओटीपी दोबारा दर्ज करने का प्रयास करें।",
  "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.":
    "प्राइवेटकोर्ट में, हम अपनी सेवाओं की सुरक्षा और अखंडता को प्राथमिकता देते हैं। हमारी जीएसटी सत्यापन प्रक्रिया हमारे सभी उपयोगकर्ताओं के लिए एक विश्वसनीय और सुरक्षित अनुभव सुनिश्चित करने के लिए सावधानीपूर्वक डिज़ाइन की गई है।",
  "Enter Your GSTIN": "अपना जीएसटीआईएन दर्ज करें",
  "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.":
    "कृपया अपना सही GST पहचान नंबर (GSTIN) 00-XXXXX-0000-X-0-X-0 प्रारूप में दर्ज करें।",
  "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.":
    "आपके जीएसटीआईएन दर्ज करने पर, प्राइवेटकोर्ट परिश्रमपूर्वक इससे जुड़ी आवश्यक जानकारी एकत्र करेगा। इसमें आपका पूरा नाम, जन्मतिथि, आवासीय पता और संपर्क विवरण शामिल हैं। व्यापार मालिकों के लिए, हम आपके जीएसटीआईएन से जुड़े सभी प्रासंगिक व्यापार विवरणों, जैसे एमसीए विशिष्टताओं और जीएसटी रिटर्न फाइलिंग के रिकॉर्ड को शामिल करने के लिए अपने डेटा संग्रह का विस्तार करते हैं।",
  "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.":
    "हमारा सिस्टम वित्तीय जानकारी के लिए आपके CIBIL स्कोर का आकलन करता है और आपके जीएसटीआईएन से जुड़े किसी भी चल रहे अदालती मामले के लिए क्रॉस-सत्यापन करता है।",
  "Error: Invalid GST Format": "त्रुटि: अमान्य जीएसटी प्रारूप",
  "Please enter your personal PAN": "कृपया अपना व्यक्तिगत पैन दर्ज करें",
  "Already Exists": "पहले से ही मौजूद है",
  "Invalid": "अमान्य",
  "Secure PAN Validation Process": "सुरक्षित पैन सत्यापन प्रक्रिया",
  "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.":
    "यह सत्यापन प्रक्रिया हमारी सेवाओं की अखंडता को बनाए रखने, एक विश्वसनीय और सुरक्षित अनुभव सुनिश्चित करने के लिए डिज़ाइन की गई है।",
  "Enter Your PAN Number": "अपना पैन नंबर दर्ज करें",
  "Please enter your correct PAN number in the format XXXXX-0000-X.":
    "कृपया XXXXX-0000-X प्रारूप में अपना सही पैन नंबर दर्ज करें।",
  "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.":
    "प्राइवेटकोर्ट आपके पैन से जुड़ी आवश्यक जानकारी जैसे आपका पूरा नाम, जन्म तिथि, आवासीय पता और संपर्क विवरण सावधानीपूर्वक एकत्र करेगा। व्यापार मालिकों के लिए, हम आपके पैन से जुड़े सभी प्रासंगिक व्यापार विवरणों जैसे जीएसटी विवरण, एमसीए विशिष्टताओं और जीएसटी रिटर्न फाइलिंग के रिकॉर्ड को शामिल करने के लिए अपने डेटा संग्रह का विस्तार करते हैं।",
  "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.":
    "हमारा सिस्टम वित्तीय जानकारी के लिए आपके CIBIL स्कोर का आकलन करता है और आपके पैन से जुड़े किसी भी चल रहे अदालती मामले के लिए क्रॉस-सत्यापन करता है।",
  "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.":
    "ऐसा प्रतीत होता है कि प्रदान किया गया पैन विवरण किसी कंपनी का है। सत्यापन उद्देश्यों के लिए हमें आपके व्यक्तिगत पैन विवरण की आवश्यकता है।",

  "Data Compilation in Progress: Please Allow Processing Time":
    "डेटा संकलन प्रगति पर है: कृपया प्रसंस्करण समय दें",
  "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":
    "अपनी जानकारी प्रदान करने के लिए धन्यवाद. हमारा सिस्टम वर्तमान में आपके व्यापक प्रोफ़ाइल को स्थापित करने के लिए आपके द्वारा दर्ज किए गए डेटा का विश्लेषण और संकलन करने की प्रक्रिया में है, जिसमें पैन, आधार, जीएसटी, ईमेल और अधिक जैसे विवरण शामिल हैं।",
  "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":
    "इस ऑपरेशन में कुछ क्षण लग सकते हैं. इस दौरान, आपकी स्क्रीन न्यूनतम गतिविधि दिखा सकती है। कृपया आश्वस्त रहें कि यह हमारी डेटा संकलन प्रक्रिया का एक नियमित हिस्सा है।",
  "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.":
    "सटीकता और सुरक्षा बनाए रखने के लिए आपकी प्रोफ़ाइल सावधानीपूर्वक तैयार की जा रही है। हम आपके धैर्य और समझ की ईमानदारी से सराहना करते हैं क्योंकि हम आपके अनुभव को बढ़ाने के लिए काम करते हैं।",
  "For urgent matters or assistance, feel free to call us at +91-9699900111.":
    "अत्यावश्यक मामलों या सहायता के लिए, बेझिझक हमें +91-9699900111 पर कॉल करें।",
  "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.":
    "आपके धैर्य और PrivateCourt को सौंपने के लिए धन्यवाद। आपकी सुरक्षा हमारी प्राथमिकता है.",
  "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.":
    "कृपया अपना आधार नंबर दर्ज करें। यह प्रक्रिया आपकी सुरक्षा के लिए सटीक क्रॉस-चेकिंग सुनिश्चित करती है। आपके सहयोग के लिए धन्यवाद।",
  "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। दर्ज किया गया आधार नंबर अमान्य है. कृपया दोबारा जांचें और सुनिश्चित करें कि आपने प्रवेश कर लिया है",
  "Applicant’s correct 12-digit Aadhaar number.":
    "आवेदक का सही 12 अंकों का आधार नंबर।",
  "Error: Invalid OTP": "त्रुटि: अमान्य ओटीपी",
  "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। दर्ज किया गया ओटीपी गलत है. कृपया ओटीपी की दोबारा जांच करें और पुनः प्रयास करें।",
  "Welcome": "स्वागत",
  "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.":
    "निर्बाध सत्यापन प्रक्रिया के लिए आपके बैंक खाते की जानकारी की सटीकता सुनिश्चित करना महत्वपूर्ण है। कृपया अपना वैध खाता नंबर दर्ज करें, और अपने खाते से जुड़े सही आईएफएससी कोड को इनपुट करें।",
  "This verification step is designed to enhance the security of our services and provide you with a reliable experience.":
    "यह सत्यापन चरण हमारी सेवाओं की सुरक्षा बढ़ाने और आपको एक विश्वसनीय अनुभव प्रदान करने के लिए डिज़ाइन किया गया है।",
  "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। प्रदान किया गया नाम जीएसटी रिकॉर्ड से मेल नहीं खाता। कृपया सुनिश्चित करें कि दर्ज किया गया नाम आपके जीएसटी कार्ड की जानकारी से मेल खाता है और पुनः प्रयास करें।",
  "Business Verification Process": "व्यवसाय सत्यापन प्रक्रिया",
  "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.":
    "सुरक्षित व्यवसाय सत्यापन सुनिश्चित करना- यह सत्यापन प्रक्रिया हमारी सेवाओं की अखंडता को बनाए रखने, एक विश्वसनीय और सुरक्षित अनुभव प्रदान करने के लिए सावधानीपूर्वक डिज़ाइन की गई है।",
  "Please provide details for each of your businesses for accurate validation.":
    "कृपया सटीक सत्यापन के लिए अपने प्रत्येक व्यवसाय का विवरण प्रदान करें।",
  "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.":
    "प्राइवेटकोर्ट आपके पैन से जुड़ी आवश्यक जानकारी जैसे आपका पूरा नाम, जन्म तिथि, आवासीय पता और संपर्क विवरण एकत्र करेगा। व्यापार मालिकों के लिए, हम आपके पैन से जुड़े सभी प्रासंगिक व्यापार विवरणों जैसे जीएसटी विवरण, एमसीए विशिष्टताओं और जीएसटी रिटर्न फाइलिंग के रिकॉर्ड को शामिल करने के लिए अपने डेटा संग्रह का विस्तार करते हैं।",
  "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.":
    "हमारा सिस्टम वित्तीय जानकारी के लिए आपके सिबिल स्कोर का मूल्यांकन करता है और आपके पैन से जुड़े किसी भी चल रहे अदालती मामले के लिए क्रॉस-सत्यापन करता है।",
  "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a":
    "अपने खाते की सुरक्षा सुनिश्चित करने के लिए, कृपया निर्दिष्ट प्रारूप में अपना वैध ईमेल आईडी दर्ज करें- username@domain.com। यदि आपका ईमेल पता हमारे सिस्टम में मौजूद नहीं है, तो ",
  "account will be created for you.": "आपके लिए खाता बनाया जाएगा",
  "GST Verification Process": "जीएसटी सत्यापन प्रक्रिया",
  "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.":
    "हमें आपको यह बताते हुए खेद है कि दर्ज किया गया जीएसटी नंबर आवश्यक प्रारूप (00-XXXXX-0000-X-0-X-0) से मेल नहीं खाता है। कृपया सुनिश्चित करें कि आपने सही जीएसटी विवरण दर्ज किया है और सटीक सत्यापन के लिए निर्दिष्ट प्रारूप का पालन करें।",
  "Verify GST": "जीएसटी सत्यापित करें",
  "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.":
    "बधाई हो! आपने पंजीकरण प्रक्रिया पूरी कर ली है. आवश्यक जानकारी प्रदान करने के प्रति आपके समर्पण ने हमें प्रभावी ढंग से आपकी सेवा करने के एक कदम और करीब ला दिया है।",
  "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.":
    "असुविधा के लिए हम खेद व्यक्त करते हैं। प्रदान किया गया नाम जीएसटी रिकॉर्ड से मेल नहीं खाता। कृपया सुनिश्चित करें कि दर्ज किया गया नाम आपके जीएसटी कार्ड की जानकारी से मेल खाता है और पुनः प्रयास करें।",
  "Skip": "छोड़े",
  "Server Overload: Please Retry Later":
    "सर्वर ओवरलोड: कृपया बाद में पुनः प्रयास करें",
  "Back To Home": "होम वापिस जाये ",



  "Verify Aadhaar": "आधार सत्यापित करें",
  "having PAN number": "पैन नंबर होना",
  "Verify Bank": "बैंक सत्यापित करें",
  "Select Businesses": "व्यवसाय चुनें",
  "I am individual": "मैं व्यक्ति हूँ",
  "Securely Verify Your Credit Score": "अपने क्रेडिट स्कोर को सुरक्षित रूप से सत्यापित करें",
  "Enter Your Mobile Number": "अपना मोबाइल नंबर दर्ज करें",
  "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "अपने क्रेडिट स्कोर को सुरक्षित रूप से एक्सेस करने के लिए, कृपया नीचे अपना मोबाइल नंबर दर्ज करें। हम आपकी पहचान सत्यापित करने और आपकी जानकारी की गोपनीयता सुनिश्चित करने के लिए आपको एक बार का पासवर्ड (OTP) भेजेंगे।",
  "Verify without OTP": "बिना OTP के सत्यापित करें",
  "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "सबमिट करने के बाद, OTP के लिए अपने संदेशों की तुरंत जाँच करें। यह कदम सुनिश्चित करता है कि आपकी वित्तीय जानकारी सुरक्षित रहे जबकि आप अपने क्रेडिट प्रोफ़ाइल में मूल्यवान जानकारी प्राप्त करते हैं।",
  "Enter Your OTP Here": "अपना OTP यहाँ दर्ज करें",
  "Error: Invalid Mobile Number": "त्रुटि: अमान्य मोबाइल नंबर",
  "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "असुविधा के लिए हमें खेद है। दर्ज किया गया मोबाइल नंबर अमान्य है। कृपया दोबारा जाँच करें और सुनिश्चित करें कि आपने",
  "Applicant’s correct 10-digit Mobile number.": "आवेदक का सही 10-अंकीय मोबाइल नंबर दर्ज किया है।",
  "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "मोबाइल नंबर या आगे की सहायता के लिए +91-9699900111 पर हमारी सहायता टीम से संपर्क करें। हम आपके सहयोग की सराहना करते हैं।",
  "Good Morning": "सुप्रभात",
  "Good Evening": "शुभ संध्या",
  "Good Night":
    "शुभ रात्रि",
  "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0). Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "हमें आपको यह बताते हुए खेद है कि दर्ज किया गया GST नंबर आवश्यक प्रारूप (00-XXXXX-0000-X-0-X-0) से मेल नहीं खाता है। कृपया सुनिश्चित करें कि आपने सही GST विवरण दर्ज किया है और सटीक सत्यापन के लिए निर्दिष्ट प्रारूप का पालन करें।",
  "Error: Invalid PAN category": "त्रुटि: अमान्य PAN श्रेणी",
  "Rs.": "रु.",
  "Verification Complete – Thank You!": "सत्यापन पूरा हुआ - धन्यवाद!",
  "Congratulations! You've completed the Verification process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "बधाई हो! आपने सत्यापन प्रक्रिया पूरी कर ली है। आवश्यक जानकारी प्रदान करने के प्रति आपके समर्पण ने हमें प्रभावी रूप से आपकी सेवा करने के एक कदम और करीब ला दिया है।",
  "With your Verification finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "आपके सत्यापन को अंतिम रूप दिए जाने के साथ, हमारी टीम आपकी आवश्यकताओं को कुशलतापूर्वक संभालने के लिए पूरी तरह से सुसज्जित है। हम आपको आश्वस्त करते हैं कि आपके मामले को अत्यंत सावधानी और विस्तार से संभाला जाएगा।",
  "having Gst number": "GST नंबर होना",
  "Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.": "आपका UDYAM नंबर हमें आपके व्यवसाय से जुड़े महत्वपूर्ण विवरण एकत्र करने की अनुमति देता है। इसमें आपका व्यवसाय पता, वर्तमान स्थिति, सेवा विवरण और संगठन का प्रकार शामिल है।",

  "Securely Verify Your Credit Score": "अपने क्रेडिट स्कोर को सुरक्षित रूप से सत्यापित करें",
  "Enter Your Mobile Number": "अपना मोबाइल नंबर दर्ज करें",
  "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information.": "अपने क्रेडिट स्कोर को सुरक्षित रूप से एक्सेस करने के लिए, कृपया नीचे अपना मोबाइल नंबर दर्ज करें। हम आपकी पहचान सत्यापित करने और आपकी जानकारी की गोपनीयता सुनिश्चित करने के लिए आपको एक बार का पासवर्ड (OTP) भेजेंगे।",
  "For any queries or assistance, feel free to call us at +91-9699900111.": "किसी भी प्रश्न या सहायता के लिए, हमें +91-9699900111 पर कॉल करने के लिए स्वतंत्र महसूस करें।",
  "Verify without OTP": "बिना OTP के सत्यापित करें",
  "Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile.": "बमिट करने के बाद, OTP के लिए अपने संदेशों की तुरंत जाँच करें। यह कदम सुनिश्चित करता है कि आपकी वित्तीय जानकारी सुरक्षित रहे जबकि आप अपने क्रेडिट प्रोफ़ाइल में मूल्यवान जानकारी प्राप्त करते हैं।",
  "Enter Your OTP Here": "यहाँ अपना OTP दर्ज करें",
  "Error: Invalid Mobile Number": "त्रुटि: अमान्य मोबाइल नंबर",
  "We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the": "असुविधा के लिए हमें खेद है। दर्ज किया गया मोबाइल नंबर अमान्य है। कृपया दोबारा जाँच करें और सुनिश्चित करें कि आपने",
  "Applicant’s correct 10-digit Mobile number.": "आवेदक का सही 10-अंकीय मोबाइल नंबर दर्ज किया है।",
  "Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "मोबाइल नंबर या हमारी सहायता टीम से संपर्क करें +91-9699900111 पर संपर्क करें। हम आपके सहयोग की सराहना करते हैं।",
  "Error: Invalid OTP": "त्रुटि: अमान्य OTP",
  "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "सुविधा के लिए हमें खेद है। दर्ज किया गया OTP गलत है। कृपया OTP की दोबारा जाँच करें और पुनः प्रयास करें।",
  "mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "मोबाइल नंबर या आगे की सहायता के लिए +91-9699900111 पर हमारी सहायता टीम से संपर्क करें। हम आपके सहयोग की सराहना करते हैं।",
  "Verify OTP": "OTP सत्यापित करें",
  "Verify": "सत्यापित करें",
};

export default HindiTranslation;
