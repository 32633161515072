import {createSlice} from "@reduxjs/toolkit";

const initialState={
    userName:"",
}

export const userNameSlice=createSlice({
    name:"userName",
    initialState,
    reducers:{
        changeUserName:(state, action)=>{
            state.userName=action.payload;
        }
    }
});

export const {changeUserName}=userNameSlice.actions;

export default userNameSlice.reducer;