import React, { useRef, useState } from "react";
import VerifyButton from "./verifyButton";
import { useDispatch } from "react-redux";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import DataCompilation from "./dataCompilation";
// import { useRef } from 'react';

const MidGST = () => {
  const translate = useLanguage.getLang();
  const dispatch = useDispatch();
  const [gstErr, setGstEr] = useState(false);
  //   const [companyPanEr, setCompanyPanEr] = useState(false);
  const [aEErr, setAEErr] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [gstin, setGstIn] = useState("");
  const [dataCompilation, setDataCompilation] = useState(false);
  const [user, setUser] = useState(auth.getUser().UserLogin);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),

    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),

    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  //   console.log(user, "user");

  const handleAlreadyExists = async (pan1) => {
    pan1 = pan1.toUpperCase();
    try {
      const response = await http.post2("/alreadyExists", { gstin: gstin });
      if (response.status == 200) {
        if (response.data == "Already Exists") {
          // inputRefs.map((ref) => ref.current.value="");
          setAEErr(true);
          setDisabled(true);
        }
      }
    } catch (error) {
      dispatch(changeStage("overload"));
      setDisabled(false);
      console.error("Error:", error);
    }
  };

  const handleKeyDown = (index, e) => {
    setGstEr(false);
    setAEErr(false);
    const key = e.key.toUpperCase();

    let isNumber = false;
    let isCapital = false;
    if([0,1,7,8,9,10,12,14].includes(index) && /^[0-9]$/i.test(key)){
      isNumber=/^[0-9]$/i.test(key);
    }
    else if([2,3,4,5,6,11,13].includes(index) && /^[A-Z]$/i.test(key)){
      isCapital = /^[A-Z]$/i.test(key);
    }
    if (e.key === "Backspace" && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (isNumber) {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = key;
        inputRefs[nextIndex].current.focus();
      }
    }
    else if(isCapital){
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = key;
        inputRefs[nextIndex].current.focus();
      }
    }
    else if(!isCapital && !isNumber){
      const nextIndex = index ;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value ="";
      }
    }
    else if (
      index === 0 &&
      e.key !== "Backspace" &&
      !isNumber && !isCapital
    ) {
      e.preventDefault();
    }

    let gstin = inputRefs.map((ref) => ref.current.value).join("");
    if (gstin.length == 15) {
      setDisabled(false);
    } else setDisabled(true);
    setGstIn(gstin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setDataCompilation(true);
    console.log("gstSubmit", gstin);
    try {
      const response = await http.post2("/verifyNewGst", {
        gstin,
        mid: user._id,
      });
      if (response.status == 200) {
        setDataCompilation(false);
        if (response.data == "Invalid") {
          // inputRefs.map((ref) => (ref.current.value = ""));
          setGstEr(true);
        } else {
          auth.login(response.data.token);
          dispatch(changeStage(response.data.verificationStage));
        }
      }
      setDisabled(false);
    } catch (error) {
      dispatch(changeStage("overload"));
      setDisabled(false);
      setDataCompilation(false);
      console.error("Error:", error);
    }
  };

  return (
    <>
      {dataCompilation ? (
        <>
          <DataCompilation />
        </>
      ) : (
        <div className="login-mid">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 login-mid-left">
                <h2 data-translate="tran-1">
                  {translate?.["GST Verification Process"] ??
                    "GST Verification Process"}
                </h2>
                <p data-translate="tran-2">
                  {translate?.[
                    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users."
                  ] ??
                    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users."}
                </p>
              </div>
              <div className="col-lg-6">
                <div className="card pb-normal">
                  <div className="vf-content">
                    <h1 data-translate="tran-3">
                      {translate?.["Enter Your GSTIN"] ?? "Enter Your GSTIN"}
                    </h1>
                    <p data-translate="tran-4">
                      {translate?.[
                        "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0."
                      ] ??
                        "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0."}
                    </p>
                    <p data-translate="tran-5">
                      {translate?.[
                        "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings."
                      ] ??
                        "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings."}
                    </p>
                    <p data-translate="tran-6">
                      {translate?.[
                        "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN."
                      ] ??
                        "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN."}
                    </p>
                    <p data-translate="tran-7">
                      {translate?.[
                        "For any queries or assistance, feel free to call us at +91-9699900111."
                      ] ??
                        "For any queries or assistance, feel free to call us at +91-9699900111."}
                    </p>
                    <p>
                      <strong data-translate="tran-8">
                        {translate?.[
                          "Thank you for entrusting PrivateCourt. Your security is our priority."
                        ] ??
                          "Thank you for entrusting PrivateCourt. Your security is our priority."}
                      </strong>
                    </p>
                  </div>
                  <div className="otp-boxs d-flex flex-wrap justify-content-center pan-number-box mb-2">
                    {[...Array(15)].map((_, index) => (
                      <input
                        key={index}
                        className="text-center text-uppercase form-control rounded"
                        type="text"
                        // onKeyPress={index < 5 ? onlyAtoZ : restrictInput}
                        onKeyUp={(e) => handleKeyDown(index, e)}
                        maxLength="1"
                        ref={inputRefs[index]}
                      />
                    ))}
                  </div>

                  {gstErr && (
                    <div
                      className="alert alert-danger text-danger vfe-content"
                      role="alert"
                    >
                      <div className="text-center">
                        <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                      </div>
                      <h4 className="alert-heading" data-translate="tran-10">
                        {translate?.["Error: Invalid GST Format"] ??
                          "Error: Invalid GST Format"}
                      </h4>
                      <p data-translate="tran-11">
                        {translate?.["We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0). Please ensure you have entered the correct GST details and follow the specified format for accurate verification."]??"We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0). Please ensure you have entered the correct GST details and follow the specified format for accurate verification."}
                      </p>
                      <p data-translate="tran-12">
                        {["If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."]??"If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."}
                      </p>
                      <p>
                        <strong data-translate="tran-15">
                          {translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}
                        </strong>
                      </p>
                    </div>
                  )}

                  {aEErr && (
                    <div
                      className="alert alert-danger text-danger vfe-content"
                      role="alert"
                    >
                      <div className="text-center">
                        <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                      </div>
                      <h4 className="alert-heading" data-translate="tran-10">
                        {translate?.["Error: Already Exists"] ??
                          "Error: Already Exists"}
                      </h4>
                      <p data-translate="tran-11">
                        {["The GST provided already exists in our records. Please try using a different GST for verification."]??"The GST provided already exists in our records. Please try using a different GST for verification."}
                      </p>
                      <p data-translate="tran-12">
                        {["If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."]??"If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111."}
                      </p>
                      <p>
                        <strong data-translate="tran-15">
                          {translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}
                        </strong>
                      </p>
                    </div>
                  )}
                  <div className="ms-auto">
                    <VerifyButton
                      btnName="Verify GST"
                      onSubmit={handleSubmit}
                      disabledBtn={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MidGST;
