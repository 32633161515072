import React, { useEffect, useRef, useState } from "react";
import VerifyButton from "./verifyButton";
import { useDispatch } from "react-redux";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import DataCompilation from "./dataCompilation";

const MidThankYou=()=>{
    const translate = useLanguage.getLang();
    const dispatch = useDispatch();
    // const user = auth.getUser().UserLogin;
    const [user, setUser] = useState(auth.getUser().UserLogin);
    console.log(user);


    const handleBtn=async()=>{
      // try {
      //   const response = await http.post2("/verifyCourtCase", {mid:user._id});
      //   if(response.status==200){
      //      if(response.data=="done"){
      //       console.log("user Verified");
      //     }
      //     else{
      //       console.log("not Verified");
      //     }
      //   }
      // } catch (error) {
      //   // dispatch(changeStage('overload'))
      //   console.error("Error:", error);
      // }
        window.location.href = '/dashboard';
    }

    const handleVerifyCutomer=async()=>{
      try {
        const response = await http.post2("/verifyCourtCase", {mid:user._id});
        if(response.status==200){
           if(response.data=="done"){
            console.log("user Verified");
          }
          else{
            console.log("not Verified");
          }
        }
      } catch (error) {
        // dispatch(changeStage('overload'))
        console.error("Error:", error);
      }
    }

    // useEffect(()=>{
    //   handleVerifyCutomer();
    // },[]);
  

    return (
        <div className="login-mid">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 login-mid-left">
              <h2 data-translate="lml-heading">{translate?.["Registration Complete – Thank You!"]??"Registration Complete – Thank You!"}</h2>
              <p data-translate="lml-para">{translate?.["Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively."]??"Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively."}</p>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="card pb-normal ad-box">
                <div className="alert alert-success text-success vf-content" role="alert">
                  <div className="text-center">
                      <i className="bi bi-check-circle-fill fs-1"></i>
                  </div>
                  <h4 className="alert-heading blue" data-translate="ty-heading">{translate?.["Registration Complete – Thank You!"]??"Registration Complete – Thank You!"}</h4>
                  <p data-translate="ty-title-1">{translate?.["Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively."]??"Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively."}</p>
                  <p data-translate="ty-title-2">{translate?.["With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail."]??"With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail."}</p>
                  <p data-translate="ty-title-3">{translate?.["Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process."]??"Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process."}</p>
                  <p>{translate?.["We eagerly anticipate the opportunity to assist you further."]??"We eagerly anticipate the opportunity to assist you further."}</p>
                  <p className="fw-bold">
                    <span data-translate="ty-title-4">{translate?.["Warm regards,"]??"Warm regards,"}</span> 
                    <br></br><span data-translate="ty-title-5">{translate?.["PrivateCourt Team"]??"PrivateCourt Team"}</span>
                  </p>
                </div>
                <div className="ms-auto">
                    <VerifyButton btnName="Dashboard" onSubmit={handleBtn} disabledBtn={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
           )
}
export default MidThankYou;