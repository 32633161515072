import React,{useRef, useState} from 'react';
import VerifyButton from './verifyButton';
import { useDispatch } from 'react-redux';
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import processLoader from "./../../../images/process-loader.gif"

const DataCompilation = () => {

  const translate = useLanguage.getLang();

    return (
        <div className="login-mid">
        <div className="container">
            <div className="row h-auto">
                <div className="col-lg-6">
                    <h3 data-translate="tran-1">{translate?.["Data Compilation in Progress: Please Allow Processing Time"]??"Data Compilation in Progress: Please Allow Processing Time"}</h3>
                    <p data-translate="tran-2">{translate?.["Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile."]??"Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile."}</p>
                    <p data-translate="tran-3">{translate?.["This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process."]??"Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile."}</p>
                    <p data-translate="tran-4">{translate?.["Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience."]??"Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience."}</p>
                    <p data-translate="tran-5">{translate?.["For urgent matters or assistance, feel free to call us at +91-9699900111."]??"For urgent matters or assistance, feel free to call us at +91-9699900111."}</p>
                    <p><strong data-translate="tran-6">{translate?.["Thank you for your patience and entrusting PrivateCourt. Your security is our priority."]??"Thank you for your patience and entrusting PrivateCourt. Your security is our priority."}</strong></p>
                </div>
                <div className="col-lg-6 align-self-center">
                    <img className="img-fluid" src={processLoader} alt=""/>
                </div>
            </div>
            {/* <div className="language-list">
              <div className="container">
                <div className="row h-auto">
                  <div className="col-lg-12">
                    <ul className="list-inline">       
                      <li className="list-inline-item" onclick="reloadPage()">English (UK)</li>
                      <li className="list-inline-item" id="button1" onclick="changeLanguage('Hindi'),document.getElementById('audioPlayer').src='audio/Hindi-Voice.wav'">हिन्दी</li>
                      <li className="list-inline-item" id="button2" onclick="changeLanguage('Bengali'),document.getElementById('audioPlayer').src='audio/Bengali-Voice.wav'">বাংলা</li>
                      <li className="list-inline-item" id="button3" onclick="changeLanguage('Telugu'),document.getElementById('audioPlayer').src='audio/Telugu-Voice.wav'">తెలుగు</li>
                      <li className="list-inline-item" id="button4" onclick="changeLanguage('Marathi'),document.getElementById('audioPlayer').src='audio/Marathi-Voice.wav'">मराठी</li>
                      <li className="list-inline-item" id="button5" onclick="changeLanguage('Tamil'),document.getElementById('audioPlayer').src='audio/Tamil-Voice.wav'">தமிழ்</li>
                      <li className="list-inline-item" id="button6" onclick="changeLanguage('Gujarati'),document.getElementById('audioPlayer').src='audio/Gujarati-Voice.wav'">ગુજરાતી</li>
                      <li className="list-inline-item" id="button7" onclick="changeLanguage('Kannada'),document.getElementById('audioPlayer').src='audio/Kannada-Voice.wav'">ಕನ್ನಡ</li>
                      <li className="list-inline-item" id="button8" onclick="changeLanguage('Malayalam'),document.getElementById('audioPlayer').src='audio/Malayalam-Voice.wav'">മലയാളം</li>
                      <li className="list-inline-item" id="button9" onclick="changeLanguage('Odia'),document.getElementById('audioPlayer').src='audio/Odia-Voice.wav'">ওড়িয়া</li>
                    </ul>           
                  </div>
                  <div className="col-lg-1">
                    <audio id="audioPlayer" src="audio/email-verify.wav" preload="auto"></audio>
                    <button id="toggleButton" onclick="toggleAudio()">
                      <i id="playPauseIcon" className="bi bi-play-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
       
    </div>  
    );
};

export default DataCompilation;