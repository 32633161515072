import React, { useEffect, useRef, useState } from "react";
import VerifyButton from "./verifyButton";
import { useDispatch } from "react-redux";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import DataCompilation from "./dataCompilation";

const MidUdyam = () => {
  const translate = useLanguage.getLang();
  const [udyamEr, setUdyamEr] = useState(false);
  const [user, setUser] = useState(auth.getUser().UserLogin);
  const dispatch = useDispatch();
  const [udyamno, setUdyamNo] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [indexName, setIndexName] = useState({});
  const [NameErr, setNameErr] = useState(false);
  const [aEErr, setAEErr] = useState(false);
  const [dataCompilation, setDataCompilation] = useState(false);

  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),

    useRef(null),
    useRef(null),

    useRef(null),
    useRef(null),
    useRef(null),

    useRef(null),
    useRef(null),
    useRef(null),

    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleAlreadyExists = async (udyamno1) => {
    udyamno1 = udyamno1.toUpperCase();
    console.log(udyamno1);
    // try {
    //   const response = await http.post2("/alreadyExists", {udyamno:udyamno1});
    //   if(response.status==200){
    //      if(response.data=="Already Exists"){
    //       // inputRefs.map((ref) => ref.current.value="");
    //       setAEErr(true);
    //       setDisabled(true);
    //     }
    //   }
    // } catch (error) {
    //   dispatch(changeStage('overload'));
    //   setDisabled(false);
    //   console.error("Error:", error);
    // }
  };

  const formatString = (inputString) => {
    const prefix = inputString.slice(0, 5);
    const stateCode = inputString.slice(5, 7);
    const year = inputString.slice(7, 9);
    const sequence = inputString.slice(9);
    return `${prefix}-${stateCode}-${year}-${sequence}`;
  };

  const handleKeyDown = (index, e) => {
    setNameErr(false);
    setUdyamEr(false);
    const key = e.key.toUpperCase();
    let isNumber = false;
    let isCapital = false;
    if ([5, 6].includes(index) && /^[A-Z]$/i.test(key)) {
      isNumber = /^[A-Z]$/i.test(key);
    } else if (
      [7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index) &&
      /^[0-9]$/i.test(key)
    ) {
      isCapital = /^[0-9]$/i.test(key);
    }

    if (isNumber) {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = key;
        inputRefs[nextIndex].current.focus();
      }
    } else if (isCapital) {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = key;
        inputRefs[nextIndex].current.focus();
      }
    } else if (e.key === "Backspace" && index > 5) {
      inputRefs[index - 1].current.focus();
    } else if (index === 5 && e.key !== "Backspace") {
      e.preventDefault();
    }
    if (!isCapital && !isNumber) {
      const nextIndex = index;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = "";
      }
    }
    // else if (isNumberOrCapitalLetter) {
    //   const nextIndex = index + 1;
    //   if (nextIndex < inputRefs.length) {
    //     inputRefs[index].current.value = key;
    //     inputRefs[nextIndex].current.focus();
    //   }
    // }

    let udyam1 = inputRefs.map((ref) => ref.current.value).join("");
    if (udyam1.length == 16) {
      handleAlreadyExists(formatString(udyam1));
      setDisabled(false);
    } else setDisabled(true);
    setUdyamNo(formatString(udyam1));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setDataCompilation(true);
    try {
      console.log(udyamno);
      const response = await http.post2("/udyamVerify", {
        udyamno,
        mid: user._id,
      });
      console.log(response.data);
      if (response.status == 200) {
        setDataCompilation(false);
        if (response.data == "Invalid") {
          setUdyamEr(true);
        } else if (response.data == "Name not matched") {
          setNameErr(true);
        } else {
          auth.login(response.data.token);
          dispatch(changeStage(response.data.verificationStage));
          window.location.reload();
        }
      } else if (response.status == 400) {
        dispatch(changeStage("overload"));
      }
    } catch (error) {
      setUdyamEr(true);
      console.error("Error:", error);
    }
    setDisabled(false);
  };

  const handleSkip = async (e) => {
    e.preventDefault();
    console.log("skip clicked");
    try {
      let response = await http.post2(`/skipUdyam`, { mid: user._id }); // Corrected URL format
      if (response.status === 200) {
        auth.login(response.data.token);
        dispatch(changeStage(response.data.verificationStage));
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckIndex = async () => {
    setDisabled(true);
    try {
      const response = await http.post2("/udyamIndex", { mid: user._id });
      if (response.status == 200) {
        if (response.data != "done") {
          console.log(response.data);
          setIndexName(response.data);
        } else {
          // dispatch(changeStage(""));
        }
      }
    } catch (error) {
      setUdyamEr(true);
      console.error("Error:", error);
    }
    setDisabled(false);
  };

  useEffect(() => {
    inputRefs[0].current.value = "U";
    inputRefs[1].current.value = "D";
    inputRefs[2].current.value = "Y";
    inputRefs[3].current.value = "A";
    inputRefs[4].current.value = "M";
  }, [udyamno]);

  useEffect(() => {
    handleCheckIndex();
  }, []);

  return (
    <>
      {dataCompilation ? (
        <>
          <DataCompilation />
        </>
      ) : (
        <div className="login-mid">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 login-mid-left">
                <h2 data-translate="tran-1">{translate?.["Secure UDYAM Number Validation"]??"Secure UDYAM Number Validation"}</h2>
                <h4>
                  {translate?.["Enter UDYAM for"]??"Enter UDYAM for"} {indexName?.legal_name} {translate?.["having Gst number"]??"having Gst number"}{" "}
                  {indexName?.gstin}
                </h4>
              </div>
              <div className="col-lg-6">
                <div className="card pb-normal">
                  <div className="vf-content">
                    <h1 data-translate="tran-2">{translate?.["Enter Your Udyam Number"]??"Enter Your Udyam Number"}</h1>
                    <p data-translate="tran-3">
                      {translate?.["Please enter your correct UDYAM Number."]??"Please enter your correct UDYAM Number."}
                    </p>
                    <p data-translate="tran-4">
                      {translate?.["Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type."]??"Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type."}
                    </p>
                    <p data-translate="tran-5">
                      {translate?.["Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process."]??"Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process."}
                    </p>
                  </div>
                  <div className="otp-boxs otp-pan d-flex flex-wrap justify-content-center udyam-number-box aadhar-number-box">
                    {[...Array(16)].map((_, index) => (
                      <React.Fragment key={index}>
                        <input
                          className="text-center form-control rounded"
                          type="text"
                          onKeyUp={(e) => handleKeyDown(index, e)}
                          maxLength="1"
                          ref={inputRefs[index]}
                        />
                        {(index === 4 || index === 6 || index === 8) && (
                          <span className="aadhar-divider">-</span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>

                  {udyamEr && (
                    <div
                      className="alert alert-danger mt-3 vfe-content"
                      role="alert"
                    >
                      <div className="text-center">
                        <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                      </div>
                      <h4 className="alert-heading" data-translate="tran-11">
                        {translate?.["Error: Invalid UAM Number"]??"Error: Invalid UAM Number"}
                      </h4>
                      <p data-translate="tran-12">
                        {translate?.["We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos."]??"We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos."}
                      </p>
                      {/* <p data-translate="tran-13">If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.</p> */}
                      <p data-translate="tran-14">
                        {translate?.["For further queries or assistance, feel free to call us at +91-9699900111."]??"For further queries or assistance, feel free to call us at +91-9699900111."}
                      </p>
                      <p>
                        <strong data-translate="tran-15">
                          {translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}
                        </strong>
                      </p>
                    </div>
                  )}

                  {NameErr && (
                    <div
                      className="alert alert-danger mt-3 vfe-content"
                      role="alert"
                    >
                      <div className="text-center">
                        <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                      </div>
                      <h4 className="alert-heading" data-translate="tran-11">
                        {translate?.["Error: Name Not Matched"]??"Error: Name Not Matched"}
                      </h4>
                      <p data-translate="tran-12">
                        {translate?.["We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again."]??"We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again."}
                      </p>
                      {/* <p data-translate="tran-13">If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.</p> */}
                      <p data-translate="tran-14">
                        {translate?.["For further queries or assistance, feel free to call us at +91-9699900111."]??"For further queries or assistance, feel free to call us at +91-9699900111."}
                      </p>
                      <p>
                        <strong data-translate="tran-15">
                          {translate["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}
                        </strong>
                      </p>
                    </div>
                  )}
                  <div className="d-flex justify-content-end align-items-center skip-sec">
                    <h4
                      id="skipButton"
                      className="align-self-end me-2"
                      data-translate="skip-btn" 
                      onClick={handleSkip}
                      style={{ cursor: "pointer" }}
                    >
                      {translate?.["Skip"]??"Skip"}
                    </h4>
                    <VerifyButton
                      btnName="Verify Udyam"
                      onSubmit={handleSubmit}
                      disabledBtn={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MidUdyam;
