import React, { useState, useEffect} from 'react';
import VerifyButton from './verifyButton';
import { useDispatch } from 'react-redux';
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import DataCompilation from './dataCompilation';


const MidBank = () => {
  const [user, setUser] = useState(auth.getUser().UserLogin);
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [bankEr, setBankEr]=useState(false);
  const [aEer, setAEErr]=useState(false);
  const [indexName, setIndexName]=useState({});
  const [NameErr, setNameErr]=useState(false);
  const dispatch = useDispatch();    
  const [disabled, setDisabled] = useState(true);
  const translate = useLanguage.getLang();
  const [dataCompilation, setDataCompilation]=useState(false);

  console.log(user);
  const handleChangeAccno = (e) => {
    const input = e.target.value;
    input.slice(-1);
    const numericInput = input.replace(/[^0-9]/g, "").toUpperCase();
    setAccountNumber(numericInput);
    setBankEr(false);
    setNameErr(false);
    setAEErr(false);
  };

  const handleChangeIfsc = (e) => {
    const input = e.target.value;
    input.slice(-1);
    const alphanumericInput = input.replace(/[^A-Za-z0-9]/g, "").toUpperCase();
    setIfscCode(alphanumericInput);
    setBankEr(false);
    setNameErr(false);
    setAEErr(false)
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setDisabled(true);
    setDataCompilation(true);
    try {
      const response = await http.post2("/bankVerify", {
        accno:accountNumber,
        ifsc:ifscCode,
        mid: user._id,
      });
      // accno, ifsc, mid
      if(response.status==200){
        setDataCompilation(false);
        if(response.data=="Already Exists"){
          setAEErr(true);
          setDataCompilation(false);
        }
        else if(response.data=="Name not matched"){
          setNameErr(true);
          setDataCompilation(false);
        }
        else if(response.data=="Bank details are incorrect"){
          setBankEr(true);
          setDataCompilation(false);
        }
        else {
          auth.login(response.data.token);
          dispatch(changeStage(response.data.verificationStage));
        }
      }
    } catch (error) {
      dispatch(changeStage("overload"));
      console.error("Error:", error);
    }
    setDisabled(false);
  };

  const handleBtn=async()=>{
    try {
      const response = await http.post2("/verifyCourtCase", {mid:user._id});
      if(response.status==200){
         if(response.data=="done"){
          console.log("user Verified");
        }
        else{
          console.log("not Verified");
        }
      }
    } catch (error) {
      // dispatch(changeStage('overload'))
      console.error("Error:", error);
    }
    dispatch(changeStage("thank"));
  }

  const handleCheckIndex=async()=>{
    setDisabled(true);
    try {
      const response = await http.post2("/bankIndex", {mid: user._id});
      if(response.status==200){
        if(response.data!="done"){
          setIndexName(response.data);
          setAccountNumber("");
          setIfscCode("");
        }
        else {
          handleBtn();
          // dispatch(changeStage("thank"));
          // window.location.href = '/dashboard';
          // dispatch(changeStage("done"));
        }
      }
    } catch (error) {
      // setBankEr(true);
      console.error("Error:", error);
    }
    setDisabled(false);
  }

  useEffect(()=>{
    handleCheckIndex();
  },[dataCompilation])

  return (
    <>{
      dataCompilation ?<>
        <DataCompilation/>
      </>:
    <div className="login-mid">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 login-mid-left">
            <h2><span data-translate="tran-1">{translate?.["Welcome"] ?? "Welcome"}</span>{user.full_name}</h2>
            <p data-translate="tran-2">{translate?.["Start Your Bank Account Verification"] ?? "Start Your Bank Account Verification"}</p>
            {indexName?.pan_number?<h4>{translate?.["Enter Bank Details for"]??"Enter Bank Details for"} {indexName?.full_name} {translate?.["having PAN number"]??"having PAN number"} {indexName?.pan_number}</h4>:""}
            {indexName?.gstin?<h4>{translate?.["Enter Bank Details for"]??"Enter Bank Details for"} {indexName?.legal_name} {translate?.["having GST number"]??"having GST number"} {indexName?.gstin}</h4>:""}

          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="card pb-normal bank-verification">
              <div className="vf-content">
                <h1 className="mb-2" data-translate="tran-3">{translate?.["Bank Verification"] ?? "Bank Verification"}</h1>
                <p data-translate="tran-4">{translate?.["Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account."] ?? "Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account."}</p>
                <p data-translate="tran-5">{translate?.["This verification step is designed to enhance the security of our services and provide you with a reliable experience."] ?? "This verification step is designed to enhance the security of our services and provide you with a reliable experience."}</p>
                <p data-translate="tran-6">{translate?.["For any queries or assistance, feel free to call us at +91-9699900111."] ?? "For any queries or assistance, feel free to call us at +91-9699900111."}</p>
                <p><strong data-translate="tran-7">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."] ?? "Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
              </div>
              <div>
                <h5 className="mb-1 mt-3 blue" data-translate="tran-8">{translate?.["Account Number"] ?? "Account Number"}</h5>
                <input
                  className="mt-1 mb-1 form-control rounded"
                  type="text"
                  name="accountNumber"
                  value={accountNumber}
                  onChange={handleChangeAccno}
                //   onKeyPress={restrictInput}
                />
                <h5 className="mb-1 mt-3 blue" data-translate="tran-9">{translate?.["IFSC code"] ?? "IFSC code"}</h5>
                <input
                  className="mt-1 form-control rounded text-uppercase"
                  type="text"
                  name="ifscCode"
                  value={ifscCode}
                  onChange={handleChangeIfsc}
                />
                {bankEr && <div className="alert alert-danger mt-3 vfe-content" role="alert">
                    <div className="text-center">
                      <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                    </div>
                    <h4 className="alert-heading" data-translate="tran-11">{translate?.["Error: Invalid Bank Account or IFSC Code"]??"Error: Invalid Bank Account or IFSC Code"}</h4>
                    <p data-translate="tran-12">{translate?.["We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos."]??"We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos."}</p>
                    <p data-translate="tran-13">{translate?.["If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information."]??"If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information."}</p>
                    <p data-translate="tran-14">{translate?.["For further queries or assistance, feel free to call us at +91-9699900111."]??"For further queries or assistance, feel free to call us at +91-9699900111."}</p>
                    <p><strong data-translate="tran-15">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                  </div>}
                  {aEer && <div className="alert alert-danger mt-3 vfe-content" role="alert">
                    <div className="text-center">
                      <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                    </div>
                    <h4 className="alert-heading" data-translate="tran-11">{translate?.["Error: Already Exists"]??"Error: Already Exists"}</h4> 
                    <p data-translate="tran-12">{translate?.["We regret to inform you that the ID you've provided already exists in our system. Please try another ID."]??"We regret to inform you that the ID you've provided already exists in our system. Please try another ID."}</p>
                    <p data-translate="tran-13">{translate?.["If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information."]??"If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information."}</p>
                    <p data-translate="tran-14">{translate?.["For further queries or assistance, feel free to call us at +91-9699900111."]??"For further queries or assistance, feel free to call us at +91-9699900111."}</p>
                    <p><strong data-translate="tran-15">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                  </div>}
                  {NameErr && <div className="alert alert-danger mt-3 vfe-content" role="alert">
                    <div className="text-center">
                      <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                    </div>
                    <h4 className="alert-heading" data-translate="tran-11">{translate?.["Error: Name Not Matched"]??"Error: Name Not Matched"}</h4> 
                    <p data-translate="tran-12">{translate?.["We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again."]??"We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again."}</p>
                    <p data-translate="tran-13">{translate?.["If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information."]??"If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information."}</p>
                    <p data-translate="tran-14">{translate?.["For further queries or assistance, feel free to call us at +91-9699900111."]??"For further queries or assistance, feel free to call us at +91-9699900111."}</p>
                    <p><strong data-translate="tran-15">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                  </div>}
                <div className="ms-auto">
                  <VerifyButton btnName="Verify Bank" onSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
           }
           </>
  );
};

export default MidBank;

