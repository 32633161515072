import Hindi from "./TranslationLang2/Hindi";
import Bengali from './TranslationLang2/Bengali';
import Gujarati from './TranslationLang2/Gujarati';
import Kannada from './TranslationLang2/Kannada';
import Malayalam from './TranslationLang2/Malayalam';
import Marathi from './TranslationLang2/Marathi';
import Odia from './TranslationLang2/Odia';
import Tamil from './TranslationLang2/Tamil';
import Telugu from './TranslationLang2/Telugu';
import English from './TranslationLang2/English(UK)';

const getLanguage=(lang)=>{
    if(lang=="Hindi"){
        return Hindi;
    }
    else if(lang=="Bengali"){
        return Bengali;
    }
    else if(lang=="Gujarati"){
        return Gujarati;
    }
    else if(lang=="Kannada"){
        return Kannada;
    }
    else if(lang=="Malayalam"){
        return Malayalam;
    }
    else if(lang=="Marathi"){
        return Marathi;
    }
    else if(lang=="Telugu"){
        return Telugu;
    }
    else if(lang=="Tamil"){
        return Tamil;
    }
    else if(lang=="Odia"){
        return Odia;
    }
    else if(lang=='English(UK)'){
        return English;
    }
}


export default getLanguage;