import React, { useRef, useState } from "react";
import axios from 'axios'
import VerifyButton from "./verifyButton";
import { useDispatch } from "react-redux";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import DataCompilation from "./dataCompilation";

const MidPayment=()=>{
    const [paymentId, setPaymentId] = useState(null);
    const [user, setUser] = useState(auth.getUser().UserLogin);
    const [dataCompilation, setDataCompilation]=useState(false);
    const payment=user.totalPayment;
    const translate = useLanguage.getLang();

    const dispatch = useDispatch();

    const initializeRazorpay = async () => {
        setDataCompilation(true);
        try{
            const data=await http.post2('/orders',{amount:1, mid:user._id});
            await initPayment(data.data);
            setDataCompilation(false);
        }
        catch(err){
            dispatch(changeStage("overload"));
        }
    };

    const initPayment = async (data) =>{
        const options ={
            key:'rzp_live_MpsNui8coFW1Ft',
            amount:data.data.amount,
            currency: data.data.currency,
            name:'PRIVATECOURT',
            description:'Top Arbitration, Mediation & other ADR services provider',
            order_id:data.data.id,
            handler:async(response)=>{
                try{
                    response.mid=user._id;
                    let data=await http.post2('/verifyPayment', response);
                    auth.login(data.data.token);
                    dispatch(changeStage(data.data.verificationStage));
                    console.log(data);
                } catch(err){
                    console.log(err);
                }
            },
            theme:{
                color:"#ff7529",
            }
        };
        const rzp1=new window.Razorpay(options);
        rzp1.open();
    }
    return (
        <>{
            dataCompilation ?<>
              <DataCompilation/>
            </>:
        <div id="content" className="login-mid">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className=""> 
                <div className="login-mid-left">
                  <h2 data-translate="tran-1">{translate?.["New User Registration"]??"New User Registration"}</h2>
                  <p data-translate="tran-2">{translate?.["To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:"]??"To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:"}</p>
                </div>
              </div> 
            </div>
            <div className="col-lg-6 col-xl-5 offset-xl-1">
              <div className="card new-user-rgs">
                <h2 className="mt-0 text-primary" data-translate="tran-3">{translate?.["Registration"]??"Registration"}</h2>
                <p><span data-translate="tran-4">{translate?.["To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of"]??"To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of"}</span> <strong data-translate="tran-5">Rs. {user.totalPayment}</strong>.</p>
                <h2 className="text-primary" data-translate="tran-6">GST</h2>
                <p data-translate="tran-7">{translate?.["Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations."]??"Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations."}</p>
                <h2 className="text-primary"><span data-translate="tran-8">{translate?.["Total Amount Due:"]??"Total Amount Due:"}</span> <strong data-translate="tran-9">Rs. {payment}</strong></h2>
                <p>(<span data-translate="tran-13">{translate?.["Total Registration Fee + 18% GST"]??"Total Registration Fee + 18% GST"}</span>)</p>
                <div className="alert alert-info" role="alert">
                  <h4 className="alert-heading" data-translate="tran-10">{translate?.["Secure Payment Gateway"]??"Secure Payment Gateway"}</h4>
                  <p className="mb-0" data-translate="tran-11">{translate?.["Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway."]??"Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway."}</p>
                </div>
                <div className="ms-auto">
                <VerifyButton btnName="Pay Now" onSubmit={initializeRazorpay} disabledBtn={false}/>
              </div>
              </div>
            </div>
          </div>
        </div>
     </div>
            }
            </>
    )
}
export default MidPayment;